var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    { ref: "_baseModal" },
    [
      _c("template", { slot: "header" }, [
        _c(
          "div",
          { staticClass: "flex" },
          [
            _c("div", { staticClass: "light-gray f4 fw5" }, [
              _vm._v(_vm._s(_vm.modalTitle)),
            ]),
            _c("div", { staticClass: "ml2" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.selectedTagType,
                      expression: "selectedTagType",
                    },
                  ],
                  staticClass: "resize bg-adori-gray white f6 ba br2",
                  class: { "o-40 not-allowed": _vm.isOpenInEditMode },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.selectedTagType = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                      function ($event) {
                        return _vm.changeTagType($event.target.value)
                      },
                    ],
                  },
                },
                _vm._l(_vm.tagTypes, function (item, i) {
                  return _c(
                    "option",
                    {
                      key: i,
                      attrs: {
                        disabled: _vm.isOpenInEditMode
                          ? item.name !== _vm.selectedTagType
                          : false,
                      },
                      domProps: { value: item.name },
                    },
                    [
                      _vm._v(
                        "\n            " + _vm._s(item.text) + "\n          "
                      ),
                    ]
                  )
                }),
                0
              ),
            ]),
            !_vm.isSelectedTagAd
              ? _c("BaseNewTooltip", { attrs: { text: "Preview" } }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex items-center justify-center mr3 pointer ml2",
                      on: { click: _vm.previewTag },
                    },
                    [
                      _c(
                        "i",
                        {
                          staticClass: "material-icons v-mid white text-shadow",
                        },
                        [_vm._v(" visibility ")]
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "gray f5 v-mid ml3" },
              [
                _c("BaseEditInput", {
                  attrs: { value: _vm.tagTitle, placeHolder: "Untitled" },
                  on: { "change-title": _vm.changeTagTitle },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "template",
        { slot: "body" },
        [
          _c("div", { staticClass: "flex" }, [
            _c("div", { staticClass: "controls" }, [
              _c("div", { staticClass: "controls-sidebar" }, [
                _c("div", { staticClass: "controls-grid controls-tabs" }, [
                  _c("ul", { staticClass: "controls-menu noselect" }, [
                    _c(
                      "li",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.selectedTagType === "poll",
                            expression: "selectedTagType === 'poll'",
                          },
                        ],
                        class: { active: _vm.activeTab == "poll" },
                        on: {
                          click: function ($event) {
                            return _vm.setActiveTab("poll")
                          },
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/audiogram/side-menu/poll.svg"),
                          },
                        }),
                        _vm._v("\n                Poll\n              "),
                      ]
                    ),
                    _c(
                      "li",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.selectedTagType === "Web link",
                            expression: "selectedTagType === 'Web link'",
                          },
                        ],
                        class: { active: _vm.activeTab == "Web link" },
                        on: {
                          click: function ($event) {
                            return _vm.setActiveTab("Web link")
                          },
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/audiogram/side-menu/link.svg"),
                          },
                        }),
                        _vm._v("\n                Link\n              "),
                      ]
                    ),
                    _c(
                      "li",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.selectedTagType === "call",
                            expression: "selectedTagType === 'call'",
                          },
                        ],
                        class: { active: _vm.activeTab == "call" },
                        on: {
                          click: function ($event) {
                            return _vm.setActiveTab("call")
                          },
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/audiogram/side-menu/call.svg"),
                          },
                        }),
                        _vm._v("\n                Call\n              "),
                      ]
                    ),
                    _c(
                      "li",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.selectedTagType === "location",
                            expression: "selectedTagType === 'location'",
                          },
                        ],
                        class: { active: _vm.activeTab == "location" },
                        on: {
                          click: function ($event) {
                            return _vm.setActiveTab("location")
                          },
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/audiogram/side-menu/location.svg"),
                          },
                        }),
                        _vm._v("\n                Location\n              "),
                      ]
                    ),
                    _c(
                      "li",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.selectedTagType === "buy",
                            expression: "selectedTagType === 'buy'",
                          },
                        ],
                        class: { active: _vm.activeTab == "buy" },
                        on: {
                          click: function ($event) {
                            return _vm.setActiveTab("buy")
                          },
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/audiogram/side-menu/buy.svg"),
                          },
                        }),
                        _vm._v("\n                Buy\n              "),
                      ]
                    ),
                    _c(
                      "li",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.selectedTagType === "audio",
                            expression: "selectedTagType === 'audio'",
                          },
                        ],
                        class: { active: _vm.activeTab == "audio" },
                        on: {
                          click: function ($event) {
                            return _vm.setActiveTab("audio")
                          },
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/audiogram/side-menu/audio-ad.svg"),
                          },
                        }),
                        _vm._v("\n                Audio Ad\n              "),
                      ]
                    ),
                    _c(
                      "li",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.selectedTagType === "display_ad",
                            expression: "selectedTagType === 'display_ad'",
                          },
                        ],
                        class: { active: _vm.activeTab == "display_ad" },
                        on: {
                          click: function ($event) {
                            return _vm.setActiveTab("display_ad")
                          },
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/audiogram/side-menu/display-ad.svg"),
                          },
                        }),
                        _vm._v("\n                Display Ad\n              "),
                      ]
                    ),
                    _c(
                      "li",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.selectedTagType === "video_ad",
                            expression: "selectedTagType === 'video_ad'",
                          },
                        ],
                        class: { active: _vm.activeTab == "video_ad" },
                        on: {
                          click: function ($event) {
                            return _vm.setActiveTab("video_ad")
                          },
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/audiogram/side-menu/videoicon.svg"),
                          },
                        }),
                        _vm._v("\n                Video Ad\n              "),
                      ]
                    ),
                    _c(
                      "li",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.selectedTagType === "artag",
                            expression: "selectedTagType === 'artag'",
                          },
                        ],
                        class: { active: _vm.activeTab == "artag" },
                        on: {
                          click: function ($event) {
                            return _vm.setActiveTab("artag")
                          },
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/audiogram/side-menu/artag.svg"),
                          },
                        }),
                        _vm._v("\n                AR\n              "),
                      ]
                    ),
                    _c(
                      "li",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              !_vm.isSelectedTagAd &&
                              !_vm.isGif &&
                              !_vm.isVideo,
                            expression:
                              "!isSelectedTagAd && !isGif && !isVideo",
                          },
                        ],
                        class: { active: _vm.activeTab == "templates" },
                        on: {
                          click: function ($event) {
                            return _vm.setActiveTab("templates")
                          },
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/audiogram/side-menu/templates.svg"),
                          },
                        }),
                        _vm._v("\n                Templates\n              "),
                      ]
                    ),
                    _c(
                      "li",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              !_vm.isSelectedTagAd &&
                              !_vm.isGif &&
                              !_vm.isVideo,
                            expression:
                              "!isSelectedTagAd && !isGif && !isVideo",
                          },
                        ],
                        class: { active: _vm.activeTab == "background" },
                        on: {
                          click: function ($event) {
                            return _vm.setActiveTab("background")
                          },
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/audiogram/side-menu/background.svg"),
                          },
                        }),
                        _vm._v("\n                Background\n              "),
                      ]
                    ),
                    _c(
                      "li",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              !_vm.isSelectedTagAd &&
                              !_vm.isGif &&
                              !_vm.isVideo,
                            expression:
                              "!isSelectedTagAd && !isGif && !isVideo",
                          },
                        ],
                        class: { active: _vm.activeTab == "text" },
                        on: {
                          click: function ($event) {
                            return _vm.setActiveTab("text")
                          },
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/audiogram/side-menu/text.svg"),
                          },
                        }),
                        _vm._v("\n                Text\n              "),
                      ]
                    ),
                    _c(
                      "li",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isSelectedTagAd && !_vm.isVideo,
                            expression: "!isSelectedTagAd && !isVideo",
                          },
                        ],
                        class: { active: _vm.activeTab == "image" },
                        on: {
                          click: function ($event) {
                            return _vm.setActiveTab("image")
                          },
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/audiogram/side-menu/image.svg"),
                          },
                        }),
                        _vm._v("\n                Images\n              "),
                      ]
                    ),
                    _c(
                      "li",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isSelectedTagAd && !_vm.isGif,
                            expression: "!isSelectedTagAd && !isGif",
                          },
                        ],
                        class: { active: _vm.activeTab == "video" },
                        on: {
                          click: function ($event) {
                            return _vm.setActiveTab("video")
                          },
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/audiogram/side-menu/videos.svg"),
                          },
                        }),
                        _vm._v("\n                Videos\n              "),
                      ]
                    ),
                    _c(
                      "li",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              !_vm.isSelectedTagAd &&
                              !_vm.isGif &&
                              !_vm.isVideo,
                            expression:
                              "!isSelectedTagAd && !isGif && !isVideo",
                          },
                        ],
                        class: { active: _vm.activeTab == "elements" },
                        on: {
                          click: function ($event) {
                            return _vm.setActiveTab("elements")
                          },
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/audiogram/side-menu/elements.svg"),
                          },
                        }),
                        _vm._v("\n                Misc\n              "),
                      ]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "controls-content scrollbar" }, [
                  _c("div", [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.activeTab === "poll",
                            expression: "activeTab === 'poll'",
                          },
                        ],
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "element-grid" },
                          [
                            _c(
                              "div",
                              { staticClass: "flex items-end mb1" },
                              [
                                _c("BaseLabel", {
                                  attrs: {
                                    for: "input0",
                                    text: _vm.inputMeta.labelText,
                                    icon: _vm.inputMeta.labelIcon,
                                  },
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass: "silver f6 mb0 ml1",
                                    staticStyle: { "letter-spacing": "1px" },
                                  },
                                  [_vm._v("(required)")]
                                ),
                              ],
                              1
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.inputValues[0],
                                  expression: "inputValues[0]",
                                },
                              ],
                              staticClass:
                                "db bg-adori-very-light-gray bw0 br2 ph3 pv3 gray f6 white",
                              style: _vm.isValidationForInput
                                ? "border: 1px solid red;"
                                : "border: 1px solid transparent;",
                              attrs: {
                                id: "input0",
                                placeholder: _vm.inputMeta.placeholder,
                                maxlength: "80",
                              },
                              domProps: { value: _vm.inputValues[0] },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.inputValues,
                                    0,
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm.isValidationForInput
                              ? _c("div", { staticClass: "f6 adori-red" }, [
                                  _vm._v("Cannot be empty"),
                                ])
                              : _vm._e(),
                            _c(
                              "div",
                              { staticClass: "flex items-end mb1 mt4" },
                              [
                                _c("BaseLabel", {
                                  attrs: {
                                    for: "input0",
                                    text: "options",
                                    icon: "format_list_numbered",
                                  },
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass: "silver f6 ml1",
                                    staticStyle: { "letter-spacing": "1px" },
                                  },
                                  [_vm._v("(required)")]
                                ),
                              ],
                              1
                            ),
                            _vm._l([1, 2, 3, 4], function (n) {
                              return _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.inputValues[n],
                                    expression: "inputValues[n]",
                                  },
                                ],
                                key: n,
                                staticClass:
                                  "db bg-adori-very-light-gray bw0 br2 pa3 mb2 white f6 wh ite",
                                staticStyle: {
                                  border: "1px solid transparent",
                                  "margin-left": "",
                                },
                                attrs: {
                                  placeholder: "Enter choice " + n,
                                  maxlength: "40",
                                },
                                domProps: { value: _vm.inputValues[n] },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.inputValues,
                                      n,
                                      $event.target.value
                                    )
                                  },
                                },
                              })
                            }),
                            !_vm.hasClickedNext
                              ? _c("div", [
                                  _vm.pollOptionArray.length < 2 &&
                                  _vm.hasStartedTyping &&
                                  !_vm.hasSameOptions
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "debug f6 flex adori-red",
                                        },
                                        [
                                          _vm._v(
                                            "\n                      Cannot have less than two options\n                    "
                                          ),
                                        ]
                                      )
                                    : !_vm.hasSameOptions &&
                                      _vm.hasStartedTyping
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "debug f6 flex adori-red",
                                        },
                                        [
                                          _vm._v(
                                            "\n                      Have 2 or more options similar\n                    "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                            _vm.hasClickedNext
                              ? _c("div", [
                                  _vm.pollOptionArray.length < 2 &&
                                  !_vm.hasSameOptions
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "debug f6 flex adori-red",
                                        },
                                        [
                                          _vm._v(
                                            "\n                      Cannot have less than two options\n                    "
                                          ),
                                        ]
                                      )
                                    : !_vm.hasSameOptions
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "debug f6 flex adori-red",
                                        },
                                        [
                                          _vm._v(
                                            "\n                      Have 2 or more options similar\n                    "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.activeTab === "Web link",
                            expression: "activeTab === 'Web link'",
                          },
                        ],
                      },
                      [
                        _c("div", { staticClass: "element-grid" }, [
                          _c(
                            "div",
                            { staticClass: "flex items-end mb1" },
                            [
                              _c("BaseLabel", {
                                attrs: {
                                  for: "input0",
                                  text: _vm.inputMeta.labelText,
                                  icon: _vm.inputMeta.labelIcon,
                                },
                              }),
                              _c(
                                "div",
                                {
                                  staticClass: "silver f6 mb0 ml1",
                                  staticStyle: { "letter-spacing": "1px" },
                                },
                                [_vm._v("(required)")]
                              ),
                            ],
                            1
                          ),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.inputValues[0],
                                expression: "inputValues[0]",
                              },
                            ],
                            staticClass:
                              "db bg-adori-very-light-gray bw0 br2 ph3 pv3 gray f6 white w5",
                            style: _vm.isValidationForInput
                              ? "border: 1px solid red;"
                              : "border: 1px solid transparent;",
                            attrs: {
                              id: "input0",
                              placeholder: _vm.inputMeta.placeholder,
                            },
                            domProps: { value: _vm.inputValues[0] },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.inputValues,
                                  0,
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _c("div", { staticClass: "f6 adori-red" }, [
                            !_vm.inputValues[0] && _vm.isValidationForInput
                              ? _c("div", [_vm._v("Cannot be empty")])
                              : _vm.isValidationForInput
                              ? _c("div", [_vm._v("Must be a valid url")])
                              : _vm._e(),
                          ]),
                        ]),
                      ]
                    ),
                    _vm.activeTab === "call"
                      ? _c("div", [
                          _c(
                            "div",
                            { staticClass: "element-grid" },
                            [
                              _c(
                                "div",
                                { staticClass: "flex items-end mb1" },
                                [
                                  _c("BaseLabel", {
                                    attrs: {
                                      for: "input0",
                                      text: _vm.inputMeta.labelText,
                                      icon: _vm.inputMeta.labelIcon,
                                    },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "silver f6 mb0 ml1",
                                      staticStyle: { "letter-spacing": "1px" },
                                    },
                                    [_vm._v("(required)")]
                                  ),
                                ],
                                1
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.inputValues[0],
                                    expression: "inputValues[0]",
                                  },
                                ],
                                staticClass:
                                  "db bg-adori-very-light-gray bw0 br2 ph3 pv3 gray f6 white w5",
                                style: _vm.isValidationForInput
                                  ? "border: 1px solid red;"
                                  : "border: 1px solid transparent;",
                                attrs: {
                                  id: "input0",
                                  placeholder: _vm.inputMeta.placeholder,
                                },
                                domProps: { value: _vm.inputValues[0] },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.inputValues,
                                      0,
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _vm.isValidationForInput
                                ? _c("div", { staticClass: "f6 adori-red" }, [
                                    _vm._v("Cannot be empty"),
                                  ])
                                : _vm._e(),
                              _c("AdoriPhone", {
                                staticClass: "mt4",
                                style:
                                  (!_vm.isValidPhoneNumber &&
                                    _vm.hasClickedNext) ||
                                  (!_vm.isValidPhoneNumber &&
                                    _vm.currentPhoneNumber !== null)
                                    ? "border: 1px solid red"
                                    : "border: 1px solid transparent",
                                on: {
                                  phoneNumberCurrentInput:
                                    _vm.phoneNumberCurrentInput,
                                  validPhoneNumber: _vm.validPhoneNumber,
                                },
                              }),
                              (!_vm.isValidPhoneNumber && _vm.hasClickedNext) ||
                              (!_vm.isValidPhoneNumber &&
                                _vm.currentPhoneNumber !== null)
                                ? _c("div", { staticClass: "f6 adori-red" }, [
                                    !_vm.currentPhoneNumber
                                      ? _c("div", [_vm._v("Cannot be empty")])
                                      : !_vm.isValidPhoneNumber
                                      ? _c("div", [
                                          _vm._v("Not a valid phone number"),
                                        ])
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.activeTab === "location",
                            expression: "activeTab === 'location'",
                          },
                        ],
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "element-grid" },
                          [
                            _c(
                              "div",
                              { staticClass: "flex items-end mb1" },
                              [
                                _c("BaseLabel", {
                                  attrs: {
                                    for: "input0",
                                    text: _vm.inputMeta.labelText,
                                    icon: _vm.inputMeta.labelIcon,
                                  },
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass: "silver f6 mb0 ml1",
                                    staticStyle: { "letter-spacing": "1px" },
                                  },
                                  [_vm._v("(required)")]
                                ),
                              ],
                              1
                            ),
                            _c("MapLocationPicker", {
                              staticClass: "w5",
                              staticStyle: { "z-index": "1" },
                              style:
                                (_vm.checkValidation && _vm.hasClickedNext) ||
                                (_vm.checkValidation && _vm.hasStartedTyping)
                                  ? "border: 1px solid red;"
                                  : "border: 1px solid transparent;",
                              attrs: { id: "mapLocationPicker" },
                              on: { locationInput: _vm.locationInput },
                            }),
                            (_vm.checkValidation && _vm.hasClickedNext) ||
                            (_vm.checkValidation && _vm.hasStartedTyping)
                              ? _c("div", { staticClass: "f6 adori-red" }, [
                                  _vm._v(
                                    "\n                    Start typing and choose from dropdown\n                  "
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.activeTab === "buy",
                            expression: "activeTab === 'buy'",
                          },
                        ],
                      },
                      [
                        _c("div", { staticClass: "element-grid" }, [
                          _c(
                            "div",
                            { staticClass: "flex items-end mb1" },
                            [
                              _c("BaseLabel", {
                                attrs: {
                                  for: "input0",
                                  text: _vm.inputMeta.labelText,
                                  icon: _vm.inputMeta.labelIcon,
                                },
                              }),
                              _c(
                                "div",
                                {
                                  staticClass: "silver f6 mb0 ml1",
                                  staticStyle: { "letter-spacing": "1px" },
                                },
                                [_vm._v("(required)")]
                              ),
                            ],
                            1
                          ),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.buyProductName,
                                expression: "buyProductName",
                              },
                            ],
                            staticClass:
                              "db bg-adori-very-light-gray bw0 br2 ph3 pv2 gray f6 white w-90",
                            attrs: {
                              id: "tagEditTextArea3",
                              placeholder: "Name of the product",
                              maxlength: "100",
                            },
                            domProps: { value: _vm.buyProductName },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.buyProductName = $event.target.value
                              },
                            },
                          }),
                          _c(
                            "div",
                            { staticClass: "flex items-end" },
                            [
                              _c("BaseLabel", {
                                staticClass: "mb1",
                                attrs: {
                                  for: "tagEditTextArea",
                                  text: "Target Url*",
                                  icon: "short_text",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.buyActionUrl,
                                expression: "buyActionUrl",
                              },
                            ],
                            staticClass:
                              "db bg-adori-very-light-gray bw0 br2 ph3 pv2 gray f6 white w-100",
                            style:
                              !_vm.buyActionUrl &&
                              _vm.checkValidation &&
                              _vm.hasClickedNext
                                ? "border: 1px solid red;"
                                : "border: 1px solid transparent;",
                            attrs: {
                              id: "tagEditTextArea3",
                              placeholder: "URL of the product",
                              maxlength: "1000",
                            },
                            domProps: { value: _vm.buyActionUrl },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.buyActionUrl = $event.target.value
                              },
                            },
                          }),
                          _c("div", { staticClass: "f6 adori-red" }, [
                            !_vm.buyActionUrl && _vm.hasClickedNext
                              ? _c("div", [_vm._v("Cannot be empty")])
                              : _vm.checkValidation && _vm.hasClickedNext
                              ? _c("div", [_vm._v("Must be a valid url")])
                              : _vm._e(),
                          ]),
                          _c(
                            "div",
                            { staticClass: "flex items-end" },
                            [
                              _c("BaseLabel", {
                                staticClass: "mb1",
                                attrs: {
                                  for: "tagEditTextArea",
                                  text: "Label on action button*",
                                  icon: "short_text",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.buyActionText,
                                expression: "buyActionText",
                              },
                            ],
                            staticClass:
                              "db bg-adori-very-light-gray bw0 br2 ph3 pv2 gray f6 white w-90",
                            attrs: {
                              id: "tagEditTextArea4",
                              placeholder: "It will appear on CTA",
                              maxlength: "100",
                            },
                            domProps: { value: _vm.buyActionText },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.buyActionText = $event.target.value
                              },
                            },
                          }),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.activeTab === "audio",
                            expression: "activeTab === 'audio'",
                          },
                        ],
                      },
                      [
                        _c("div", { staticClass: "ad-layout" }, [
                          _c(
                            "div",
                            {
                              staticClass: "flex justify-between mt3 mb3 w-100",
                            },
                            [
                              _c("BaseLabel", {
                                staticClass: "mr4",
                                attrs: {
                                  for: "tagEditTextArea2",
                                  text: "Title*",
                                },
                              }),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.titleValue,
                                    expression: "titleValue",
                                  },
                                ],
                                staticClass:
                                  "db bg-adori-very-light-gray bw0 br2 ph3 pv2 gray f6 white w-80",
                                class:
                                  (_vm.checkValidation &&
                                    _vm.hasClickedNext &&
                                    !_vm.isUploadingTag) ||
                                  (_vm.checkValidation &&
                                    _vm.hasStartedTyping &&
                                    !_vm.isUploadingTag)
                                    ? "bw1 ba b--red"
                                    : "",
                                attrs: {
                                  id: "tagEditTextArea2",
                                  placeholder: "Name of Ad",
                                  maxlength: "200",
                                },
                                domProps: { value: _vm.titleValue },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.titleValue = $event.target.value
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          (_vm.hasAudioAd &&
                            _vm.checkValidation &&
                            _vm.hasClickedNext &&
                            !_vm.isUploadingTag) ||
                          (_vm.checkValidation &&
                            _vm.hasStartedTyping &&
                            !_vm.isUploadingTag)
                            ? _c(
                                "div",
                                { staticClass: "f6 adori-red pl2 ml5" },
                                [
                                  _vm._v(
                                    "\n                    Cannot be empty\n                  "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            { staticClass: "flex mt4 mb3 w-100" },
                            [
                              _c("BaseLabel", {
                                staticClass: "mr3",
                                attrs: { for: "choose", text: "Choose" },
                              }),
                              _c(
                                "select",
                                {
                                  staticClass:
                                    "db bg-adori-gray gray ba b--gray br2 f6 w-100 h2",
                                  on: {
                                    change: function ($event) {
                                      return _vm.changeTagCollection(
                                        $event.target.value
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "option",
                                    {
                                      staticClass: "dn",
                                      attrs: {
                                        disabled: "disabled",
                                        selected: "",
                                      },
                                    },
                                    [_vm._v("Add to collection")]
                                  ),
                                  _vm._l(
                                    _vm.tagCollectionsOptions,
                                    function (item, i) {
                                      return _c(
                                        "option",
                                        {
                                          key: item.value,
                                          domProps: { value: item.value },
                                        },
                                        [
                                          _vm._v(
                                            "\n                        " +
                                              _vm._s(item.text) +
                                              "\n                      "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.activeTab === "display_ad",
                            expression: "activeTab === 'display_ad'",
                          },
                        ],
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "relative" },
                          [
                            _c(
                              "div",
                              { staticClass: "flex justify-between items-end" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "flex items-end" },
                                  [
                                    _c("BaseLabel", {
                                      staticClass: "mb1",
                                      attrs: {
                                        for: "tagEditTextArea",
                                        text: "keywords",
                                        icon: "short_text",
                                      },
                                    }),
                                    _c(
                                      "span",
                                      { staticClass: "ml1 gray f6" },
                                      [
                                        _c("BaseTooltip", {
                                          attrs: {
                                            info: "Enter key-value pair (up to 3) to target display ads based on keywords",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "light-gray dib mb1 f6" },
                                  [_vm._v("(optional)")]
                                ),
                              ]
                            ),
                            _c("BaseMultiTagInput", {
                              attrs: { initialValue: _vm.keywordValue },
                              on: { multInput: _vm.keywordUpdate },
                            }),
                            _c(
                              "div",
                              { staticClass: "w-100" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex justify-between items-end",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "flex items-end" },
                                      [
                                        _c("BaseLabel", {
                                          staticClass: "mb1",
                                          attrs: {
                                            for: "mapLocationPicker",
                                            text: "geo targets",
                                            icon: "short_text",
                                          },
                                        }),
                                        _c(
                                          "span",
                                          { staticClass: "ml1 gray f6" },
                                          [
                                            _c("BaseTooltip", {
                                              attrs: {
                                                info: "Select (up to 5) countries to target display ads based on location",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "light-gray dib mb1 f6" },
                                      [_vm._v("(optional)")]
                                    ),
                                  ]
                                ),
                                _vm._l(_vm.displayAdGeoTargets, function (c) {
                                  return _c("div", { key: c.code }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "fl mr2 br4 f6 ph4 pv2 br2 ba b--adori-gray light-gray bg-dark-gray btn-shadow o-90 glow pointer relative mb1",
                                      },
                                      [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(c.country) +
                                            "\n                        "
                                        ),
                                        _c(
                                          "i",
                                          {
                                            staticClass:
                                              "f6 material-icons light-gray v-top ml1 absolute tr5 pointer",
                                            attrs: {
                                              "data-country": c.country,
                                            },
                                            on: { click: _vm.removeLocation },
                                          },
                                          [_vm._v("close")]
                                        ),
                                      ]
                                    ),
                                  ])
                                }),
                                _c("BaseButtonBorder", {
                                  staticClass: "fl mr2 br4 mb3",
                                  attrs: {
                                    text: "Add Location",
                                    icon: "add",
                                    onClick: _vm.addLocation,
                                  },
                                }),
                              ],
                              2
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex justify-between w-100 mb2 items-end",
                              },
                              [
                                _c("BaseLabel", {
                                  attrs: {
                                    for: "tagEditTextArea2",
                                    text: "Campaign Run Date*",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "flex w-100",
                                class: !_vm.dateValidation ? "mb2" : "",
                              },
                              [
                                _c("BaseCheckbox", {
                                  staticClass: "mr3",
                                  attrs: {
                                    text: "Forever",
                                    initialState: _vm.displayAdForeverOption,
                                  },
                                  on: {
                                    toggle: function ($event) {
                                      _vm.displayAdForeverOption = $event
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                            !_vm.displayAdForeverOption
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex justify-between w-100 mb2",
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "flex" },
                                      [
                                        _c("date-picker", {
                                          staticClass:
                                            "adori-date-picker w-50 mr3",
                                          attrs: {
                                            lang: "en",
                                            type: "date",
                                            format: "YYYY-MM-DD",
                                            "not-before": _vm.today,
                                            placeholder: "Enter Start Date",
                                          },
                                          model: {
                                            value: _vm.displayAdStartDateValue,
                                            callback: function ($$v) {
                                              _vm.displayAdStartDateValue = $$v
                                            },
                                            expression:
                                              "displayAdStartDateValue",
                                          },
                                        }),
                                        _c("date-picker", {
                                          staticClass: "adori-date-picker w-50",
                                          attrs: {
                                            lang: "en",
                                            type: "date",
                                            format: "YYYY-MM-DD",
                                            "not-before": _vm.today,
                                            placeholder: "Enter End Date",
                                          },
                                          model: {
                                            value: _vm.displayAdEndDateValue,
                                            callback: function ($$v) {
                                              _vm.displayAdEndDateValue = $$v
                                            },
                                            expression: "displayAdEndDateValue",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.dateValidation
                              ? _c(
                                  "div",
                                  { staticClass: "red ml6 pl3 mb3 f6" },
                                  [_vm._v("Date cannot be empty")]
                                )
                              : _vm._e(),
                            _vm.showGeoModal
                              ? _c("CountryModal", {
                                  attrs: {
                                    addLocation: _vm.addLocation,
                                    saveLocation: _vm.saveLocation,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.activeTab === "templates",
                            expression: "activeTab === 'templates'",
                          },
                        ],
                      },
                      [
                        _vm.isTemplatesLoading
                          ? _c(
                              "div",
                              { staticClass: "flex justify-center mt5" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "building-blocks mt5" },
                                  [
                                    _c("div"),
                                    _c("div"),
                                    _c("div"),
                                    _c("div"),
                                    _c("div"),
                                    _c("div"),
                                    _c("div"),
                                  ]
                                ),
                              ]
                            )
                          : _vm.adminTemplates.length
                          ? _c(
                              "div",
                              { staticClass: "templates-grid" },
                              [
                                _vm._l(
                                  _vm.adminTemplates,
                                  function (template, i) {
                                    return [
                                      _c("AudiogramTemplate", {
                                        attrs: {
                                          templateId: template.id,
                                          orientation: _vm.orientation,
                                          thumbnail:
                                            template.image.urls.regular,
                                        },
                                        on: {
                                          "load-template": function ($event) {
                                            return _vm.onChangeTemplate($event)
                                          },
                                        },
                                      }),
                                    ]
                                  }
                                ),
                              ],
                              2
                            )
                          : _vm.hasTemplatesNextPage
                          ? _c(
                              "div",
                              { staticClass: "tc pb3 ph4 mt3" },
                              [
                                _c("BaseButtonLoadMore", {
                                  attrs: {
                                    text: "Load more",
                                    onClick: _vm.nextTemplatesPage,
                                    rootBottomMargin: "800",
                                    onVisible: function () {},
                                    loading: _vm.isNextTemplatesFetching,
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.activeTab === "text",
                            expression: "activeTab === 'text'",
                          },
                        ],
                      },
                      [
                        _c("div", { staticClass: "element-grid" }, [
                          _c("div", { staticClass: "element-head" }, [
                            _c("div", { staticClass: "element-title" }, [
                              _vm._v("Basic text"),
                            ]),
                          ]),
                          _c("div", { staticClass: "text-basic mb40" }, [
                            _c(
                              "div",
                              {
                                staticClass: "text title",
                                on: {
                                  click: function ($event) {
                                    return _vm.newTextbox(
                                      "Add a header",
                                      "header"
                                    )
                                  },
                                },
                              },
                              [_c("span", [_vm._v("Add a header")])]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "text subtitle",
                                on: {
                                  click: function ($event) {
                                    return _vm.newTextbox(
                                      "Add a subheader",
                                      "subheader"
                                    )
                                  },
                                },
                              },
                              [_c("span", [_vm._v("Add a subheader")])]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "text paragraph",
                                on: {
                                  click: function ($event) {
                                    return _vm.newTextbox(
                                      "Add a paragraph",
                                      "paragraph"
                                    )
                                  },
                                },
                              },
                              [_c("span", [_vm._v("Add a paragraph")])]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "text paragraph",
                                on: {
                                  click: function ($event) {
                                    return _vm.newTextbox(
                                      "Add a caption",
                                      "caption"
                                    )
                                  },
                                },
                              },
                              [_c("span", [_vm._v("Add a caption")])]
                            ),
                          ]),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.activeTab === "background",
                            expression: "activeTab === 'background'",
                          },
                        ],
                      },
                      [
                        _c("div", { staticClass: "element-grid" }, [
                          _c("div", { staticClass: "element-head" }, [
                            _c("div", { staticClass: "element-title" }, [
                              _vm._v("Background"),
                            ]),
                          ]),
                          _c("tbody", [
                            _c(
                              "tr",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.switchBackgroundFill(
                                      $event.target.attributes
                                    )
                                  },
                                },
                              },
                              [
                                _c("th", [
                                  _c("div", {
                                    staticClass: "color",
                                    class: {
                                      "color-active":
                                        _vm.activeColor == "color1",
                                    },
                                    attrs: { id: "color1" },
                                  }),
                                ]),
                                _c("th", [
                                  _c("div", {
                                    staticClass: "color",
                                    class: {
                                      "color-active":
                                        _vm.activeColor == "color2",
                                    },
                                    attrs: { id: "color2" },
                                  }),
                                ]),
                                _c("th", [
                                  _c("div", {
                                    staticClass: "color",
                                    class: {
                                      "color-active":
                                        _vm.activeColor == "color3",
                                    },
                                    attrs: { id: "color3" },
                                  }),
                                ]),
                                _c("th", [
                                  _c("div", {
                                    staticClass: "color",
                                    class: {
                                      "color-active":
                                        _vm.activeColor == "color4",
                                    },
                                    attrs: { id: "color4" },
                                  }),
                                ]),
                                _c("th", [
                                  _c("div", {
                                    staticClass: "color",
                                    class: {
                                      "color-active":
                                        _vm.activeColor == "color5",
                                    },
                                    attrs: { id: "color5" },
                                  }),
                                ]),
                                _c("th", [
                                  _c("div", {
                                    staticClass: "color",
                                    class: {
                                      "color-active":
                                        _vm.activeColor == "color6",
                                    },
                                    attrs: { id: "color6" },
                                  }),
                                ]),
                                _c("th", [
                                  _c("div", {
                                    staticClass: "color",
                                    class: {
                                      "color-active":
                                        _vm.activeColor == "color7",
                                    },
                                    attrs: { id: "color7" },
                                  }),
                                ]),
                              ]
                            ),
                            _c(
                              "tr",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.switchBackgroundFill(
                                      $event.target.attributes
                                    )
                                  },
                                },
                              },
                              [
                                _c("th", [
                                  _c("div", {
                                    staticClass: "color",
                                    class: {
                                      "color-active":
                                        _vm.activeColor == "color8",
                                    },
                                    attrs: { id: "color8" },
                                  }),
                                ]),
                                _c("th", [
                                  _c("div", {
                                    staticClass: "color",
                                    class: {
                                      "color-active":
                                        _vm.activeColor == "color9",
                                    },
                                    attrs: { id: "color9" },
                                  }),
                                ]),
                                _c("th", [
                                  _c("div", {
                                    staticClass: "color",
                                    class: {
                                      "color-active":
                                        _vm.activeColor == "color10",
                                    },
                                    attrs: { id: "color10" },
                                  }),
                                ]),
                                _c("th", [
                                  _c("div", {
                                    staticClass: "color",
                                    class: {
                                      "color-active":
                                        _vm.activeColor == "color11",
                                    },
                                    attrs: { id: "color11" },
                                  }),
                                ]),
                                _c("th", [
                                  _c("div", {
                                    staticClass: "color",
                                    class: {
                                      "color-active":
                                        _vm.activeColor == "color12",
                                    },
                                    attrs: { id: "color12" },
                                  }),
                                ]),
                                _c("th", [
                                  _c("div", {
                                    staticClass: "color",
                                    class: {
                                      "color-active":
                                        _vm.activeColor == "color13",
                                    },
                                    attrs: { id: "color13" },
                                  }),
                                ]),
                                _c("th", [
                                  _c("div", {
                                    staticClass: "color",
                                    class: {
                                      "color-active":
                                        _vm.activeColor == "color14",
                                    },
                                    attrs: { id: "color14" },
                                  }),
                                ]),
                              ]
                            ),
                            _c(
                              "tr",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.switchBackgroundFill(
                                      $event.target.attributes
                                    )
                                  },
                                },
                              },
                              [
                                _c("th", [
                                  _c("div", {
                                    staticClass: "color",
                                    class: {
                                      "color-active":
                                        _vm.activeColor == "color15",
                                    },
                                    attrs: { id: "color15" },
                                  }),
                                ]),
                                _c("th", [
                                  _c("div", {
                                    staticClass: "color",
                                    class: {
                                      "color-active":
                                        _vm.activeColor == "color16",
                                    },
                                    attrs: { id: "color16" },
                                  }),
                                ]),
                                _c("th", [
                                  _c("div", {
                                    staticClass: "color",
                                    class: {
                                      "color-active":
                                        _vm.activeColor == "color17",
                                    },
                                    attrs: { id: "color17" },
                                  }),
                                ]),
                                _c("th", [
                                  _c("div", {
                                    staticClass: "color",
                                    class: {
                                      "color-active":
                                        _vm.activeColor == "color18",
                                    },
                                    attrs: { id: "color18" },
                                  }),
                                ]),
                                _c("th", [
                                  _c("div", {
                                    staticClass: "color",
                                    class: {
                                      "color-active":
                                        _vm.activeColor == "color19",
                                    },
                                    attrs: { id: "color19" },
                                  }),
                                ]),
                                _c("th", [
                                  _c("div", {
                                    staticClass: "color",
                                    class: {
                                      "color-active":
                                        _vm.activeColor == "color20",
                                    },
                                    attrs: { id: "color20" },
                                  }),
                                ]),
                                _c("th", [
                                  _c("div", {
                                    staticClass: "color",
                                    class: {
                                      "color-active":
                                        _vm.activeColor == "color21",
                                    },
                                    attrs: { id: "color21" },
                                  }),
                                ]),
                              ]
                            ),
                            _c(
                              "tr",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.switchBackgroundFill(
                                      $event.target.attributes
                                    )
                                  },
                                },
                              },
                              [
                                _c("th", [
                                  _c("div", {
                                    staticClass: "color",
                                    class: {
                                      "color-active":
                                        _vm.activeColor == "color22",
                                    },
                                    attrs: { id: "color22" },
                                  }),
                                ]),
                                _c("th", [
                                  _c("div", {
                                    staticClass: "color",
                                    class: {
                                      "color-active":
                                        _vm.activeColor == "color23",
                                    },
                                    attrs: { id: "color23" },
                                  }),
                                ]),
                                _c("th", [
                                  _c("div", {
                                    staticClass: "color",
                                    class: {
                                      "color-active":
                                        _vm.activeColor == "color24",
                                    },
                                    attrs: { id: "color24" },
                                  }),
                                ]),
                                _c("th", [
                                  _c("div", {
                                    staticClass: "color",
                                    class: {
                                      "color-active":
                                        _vm.activeColor == "color25",
                                    },
                                    attrs: { id: "color25" },
                                  }),
                                ]),
                                _c("th", [
                                  _c("div", {
                                    staticClass: "color",
                                    class: {
                                      "color-active":
                                        _vm.activeColor == "color26",
                                    },
                                    attrs: { id: "color26" },
                                  }),
                                ]),
                                _c("th", [
                                  _c("div", {
                                    staticClass: "color",
                                    class: {
                                      "color-active":
                                        _vm.activeColor == "color27",
                                    },
                                    attrs: { id: "color27" },
                                  }),
                                ]),
                                _c("th", [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "color",
                                      attrs: { id: "color28" },
                                    },
                                    [
                                      _c("div", [
                                        _c("img", {
                                          staticClass: "absolute",
                                          attrs: {
                                            src: require("@/assets/audiogram/plus.svg"),
                                          },
                                        }),
                                        _c("input", {
                                          staticClass: "bg-color-input",
                                          attrs: {
                                            type: "color",
                                            value: "#FFFFFF",
                                          },
                                          on: {
                                            input: function ($event) {
                                              return _vm.onBgColorChange(
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                        ]),
                        _vm.isVideoEpisode
                          ? _c("div", { staticClass: "element-grid mt4" }, [
                              _c("div", { staticClass: "element-head" }, [
                                _c("div", { staticClass: "element-title" }, [
                                  _vm._v("Transparent Background"),
                                ]),
                              ]),
                              _c("div", {
                                staticClass: "transparent-picker pointer",
                                on: {
                                  click: function ($event) {
                                    return _vm.onBgColorChange()
                                  },
                                },
                              }),
                            ])
                          : _vm._e(),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.activeTab === "image",
                            expression: "activeTab === 'image'",
                          },
                        ],
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex items-center silver w-100 justify-center mb2",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "w-30 pv2 pointer img-tab",
                                class: {
                                  "bb b--red white":
                                    _vm.imageTab === "image_upload",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.setImageTab("image_upload")
                                  },
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "flex justify-center" },
                                  [_vm._v("Upload")]
                                ),
                              ]
                            ),
                            !_vm.isGif
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "w-30 pv2 pointer img-tab",
                                    class: {
                                      "bb b--red white":
                                        _vm.imageTab === "image_search",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.setImageTab("image_search")
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "flex justify-center" },
                                      [_vm._v(" Search")]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                staticClass: "w-30 pv2 pointer img-tab",
                                class: {
                                  "bb b--red white":
                                    _vm.imageTab === "gif_search",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.setImageTab("gif_search")
                                  },
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "flex justify-center" },
                                  [_vm._v("Gif")]
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.imageTab === "image_search",
                                expression: "imageTab === 'image_search'",
                              },
                            ],
                            staticClass: "relative",
                          },
                          [
                            _c("AudiogramSearchImages", {
                              on: {
                                "load-image": function ($event) {
                                  return _vm.loadAsset($event, "uploads")
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.imageTab === "gif_search",
                                expression: "imageTab === 'gif_search'",
                              },
                            ],
                            staticClass: "relative",
                          },
                          [
                            _c("GifGallery", {
                              on: { "load-image": _vm.handleImageLoad },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.imageTab === "image_upload",
                                expression: "imageTab === 'image_upload'",
                              },
                            ],
                            on: {
                              dragover: function ($event) {
                                $event.preventDefault()
                              },
                              drop: function ($event) {
                                $event.preventDefault()
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "relative" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "form-group",
                                  class: { "dark-fill": _vm.bgDragColor },
                                  on: {
                                    dragover: function () {
                                      return (_vm.bgDragColor = true)
                                    },
                                    dragleave: function () {
                                      return (_vm.bgDragColor = false)
                                    },
                                    click: _vm.imageUploader,
                                    drop: function ($event) {
                                      return _vm.dropImageFile($event)
                                    },
                                  },
                                },
                                [
                                  _c("label", { staticClass: "upload" }, [
                                    _vm.uploadingImage
                                      ? _c("div", [
                                          _c("img", {
                                            staticClass: "h70",
                                            attrs: {
                                              src: require("@/assets/spinner.svg"),
                                            },
                                          }),
                                          _c("div", [
                                            _c("span", [
                                              _vm._v("Uploading Image"),
                                            ]),
                                          ]),
                                        ])
                                      : _c("div", [
                                          _c("img", {
                                            staticClass: "gallery",
                                            attrs: {
                                              width: "24px",
                                              height: "24px",
                                              alt: "placeholder",
                                              src: require("@/assets/audiogram/gallery.png"),
                                            },
                                          }),
                                          _c("div", [
                                            _c("strong", [
                                              _vm._v("Choose Image"),
                                            ]),
                                            _vm._v(" or drag it here"),
                                          ]),
                                          _c("span", [
                                            _vm._v("Supports: JPG, PNG"),
                                          ]),
                                        ]),
                                  ]),
                                ]
                              ),
                              _vm.isLibImageLoading
                                ? _c(
                                    "div",
                                    { staticClass: "flex justify-center mt5" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "building-blocks mt5" },
                                        [
                                          _c("div"),
                                          _c("div"),
                                          _c("div"),
                                          _c("div"),
                                          _c("div"),
                                          _c("div"),
                                          _c("div"),
                                        ]
                                      ),
                                    ]
                                  )
                                : _c(
                                    "div",
                                    [
                                      _vm.imageList.length === 0
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "w-100 flex flex-column justify-center items-center",
                                            },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: require("@/assets/imgae-search.svg"),
                                                  alt: "",
                                                  width: "250",
                                                  height: "250",
                                                },
                                              }),
                                              _c(
                                                "div",
                                                { staticClass: "f5 silver" },
                                                [
                                                  _vm._v(
                                                    "Oops! No images found.. Upload images"
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _c("AudiogramImagesList", {
                                            attrs: {
                                              selectedRangeStart:
                                                _vm.selectedRangeStart,
                                              selectedRangeEnd:
                                                _vm.selectedRangeEnd,
                                              totalImages: _vm.totalImageCount,
                                              imageSources: _vm.imageList,
                                              selectedTab: "UPLOADS",
                                            },
                                            on: {
                                              handleNavigation:
                                                _vm.handleNavigation,
                                              "load-image": _vm.handleImageLoad,
                                              viewImages: _vm.viewImages,
                                            },
                                          }),
                                    ],
                                    1
                                  ),
                            ]),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.activeTab === "video",
                            expression: "activeTab === 'video'",
                          },
                        ],
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex items-center silver w-100 justify-center mb2",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "w-50 ph3 pv2 pointer img-tab",
                                class: {
                                  "bb b--red white":
                                    _vm.videoTab === "video_upload",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.setVideoTab("video_upload")
                                  },
                                },
                              },
                              [
                                _c("span", { staticClass: "ma4" }, [
                                  _vm._v("Upload"),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "w-50 ph3 pv2 pointer img-tab",
                                class: {
                                  "bb b--red white":
                                    _vm.videoTab === "video_search",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.setVideoTab("video_search")
                                  },
                                },
                              },
                              [
                                _c("span", { staticClass: "ma4" }, [
                                  _vm._v(" Search"),
                                ]),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.videoTab === "video_search",
                                expression: "videoTab === 'video_search'",
                              },
                            ],
                            staticClass: "relative",
                          },
                          [
                            _c("AudiogramSearchVideos", {
                              on: { "load-video": _vm.handleVideoLoad },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.videoTab === "video_upload",
                                expression: "videoTab === 'video_upload'",
                              },
                            ],
                            on: {
                              dragover: function ($event) {
                                $event.preventDefault()
                              },
                              drop: function ($event) {
                                $event.preventDefault()
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "relative" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "form-group",
                                  class: { "dark-fill": _vm.bgDragColor },
                                  on: {
                                    dragover: function () {
                                      return (_vm.bgDragColor = true)
                                    },
                                    dragleave: function () {
                                      return (_vm.bgDragColor = false)
                                    },
                                    click: _vm.videoUploader,
                                    drop: function ($event) {
                                      return _vm.dropVideoFile($event)
                                    },
                                  },
                                },
                                [
                                  _c("label", { staticClass: "upload" }, [
                                    _vm.uploadingImage
                                      ? _c("div", [
                                          _c("img", {
                                            staticClass: "h70",
                                            attrs: {
                                              src: require("@/assets/spinner.svg"),
                                            },
                                          }),
                                          _c("div", [
                                            _c("span", [
                                              _vm._v("Uploading Video"),
                                            ]),
                                          ]),
                                        ])
                                      : _c("div", [
                                          _c("img", {
                                            staticClass: "gallery",
                                            attrs: {
                                              width: "24px",
                                              height: "24px",
                                              alt: "placeholder",
                                              src: require("@/assets/audiogram/video_gallery.png"),
                                            },
                                          }),
                                          _c("div", [
                                            _c("strong", [
                                              _vm._v("Choose Video"),
                                            ]),
                                            _vm._v(" or drag it here"),
                                          ]),
                                          _c("span", [_vm._v("Supports: Mp4")]),
                                        ]),
                                  ]),
                                ]
                              ),
                              _vm.isLibVideoLoading
                                ? _c(
                                    "div",
                                    { staticClass: "flex justify-center mt5" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "building-blocks mt5" },
                                        [
                                          _c("div"),
                                          _c("div"),
                                          _c("div"),
                                          _c("div"),
                                          _c("div"),
                                          _c("div"),
                                          _c("div"),
                                        ]
                                      ),
                                    ]
                                  )
                                : _c(
                                    "div",
                                    [
                                      _vm.videoList.length === 0
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "w-100 flex flex-column justify-center items-center",
                                            },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: require("@/assets/imgae-search.svg"),
                                                  alt: "",
                                                  width: "250",
                                                  height: "250",
                                                },
                                              }),
                                              _c(
                                                "div",
                                                { staticClass: "f5 silver" },
                                                [
                                                  _vm._v(
                                                    "Oops! No Videos found.. Upload Videos"
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _c("AudiogramVideosList", {
                                            attrs: {
                                              selectedRangeStart:
                                                _vm.selectedVideoRangeStart,
                                              selectedRangeEnd:
                                                _vm.selectedVideoRangeEnd,
                                              totalVideos: _vm.totalVideoCount,
                                              videoSources: _vm.videoList,
                                              selectedTab: "UPLOADS",
                                            },
                                            on: {
                                              handleNavigation:
                                                _vm.handleVideoNavigation,
                                              "load-video": _vm.handleVideoLoad,
                                            },
                                          }),
                                    ],
                                    1
                                  ),
                            ]),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.activeTab === "elements",
                            expression: "activeTab === 'elements'",
                          },
                        ],
                      },
                      _vm._l(_vm.allElementAssets, function (category) {
                        return _c(
                          "div",
                          [
                            _c("AudiogramElement", {
                              attrs: { category: category },
                              on: {
                                "load-element": function ($event) {
                                  return _vm.loadAsset($event, "elements")
                                },
                              },
                            }),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                  ]),
                ]),
              ]),
            ]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isSelectedTagAd,
                    expression: "!isSelectedTagAd",
                  },
                ],
                staticClass: "canvas-area tc ml3",
              },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.isGif && !_vm.isVideo,
                        expression: "!isGif && !isVideo",
                      },
                    ],
                    staticClass: "top-controls mb3",
                  },
                  [
                    _c("div", { staticClass: "controls-items-left flex" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.showTextToolbar,
                              expression: "showTextToolbar",
                            },
                          ],
                          staticClass: "flex justify-center",
                        },
                        [
                          _c("div", [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.font,
                                    expression: "font",
                                  },
                                ],
                                staticClass:
                                  "font-picker bg-adori-gray white f6 w-100 br2 h2 ba b--gray",
                                on: {
                                  change: [
                                    function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.font = $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    },
                                    function ($event) {
                                      return _vm.changeFont($event.target.value)
                                    },
                                  ],
                                },
                              },
                              _vm._l(_vm.fontsList, function (item, i) {
                                return _c(
                                  "option",
                                  { key: i, domProps: { value: item.family } },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(item.family) +
                                        "\n                  "
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "on-clickaway",
                                    rawName: "v-on-clickaway",
                                    value: _vm.closeTextColorPicker,
                                    expression: "closeTextColorPicker",
                                  },
                                ],
                                attrs: { id: "color-choose" },
                                on: { click: _vm.toggleTextColorPicker },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "relative" },
                                  [
                                    _c(
                                      "BaseNewTooltip",
                                      { attrs: { text: "Text color" } },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "font-color" },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: require("@/assets/audiogram/text-color.svg"),
                                              },
                                            }),
                                            _c("div", {
                                              staticClass: "mini-swatch",
                                              style:
                                                "background-color: " +
                                                _vm.textColorValue,
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                          },
                                        },
                                      },
                                      [
                                        _vm.displayTextColorPicker
                                          ? _c("Sketch", {
                                              staticClass: "sketch-picker",
                                              attrs: {
                                                value: _vm.textColorValue,
                                              },
                                              on: {
                                                input:
                                                  _vm.updateTextColorFromPicker,
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c("div", { attrs: { id: "text-format" } }, [
                              _c(
                                "div",
                                { staticClass: "text-format" },
                                [
                                  _c("img", {
                                    staticClass: "text-size",
                                    attrs: {
                                      src: require("@/assets/audiogram/text-format/text-size.svg"),
                                    },
                                  }),
                                  _c(
                                    "BaseNewTooltip",
                                    { attrs: { text: "Text size" } },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.textSize,
                                            expression: "textSize",
                                          },
                                        ],
                                        staticClass: "font-width",
                                        attrs: {
                                          type: "number",
                                          placeholder: "30",
                                          min: "10",
                                          max: "800",
                                        },
                                        domProps: { value: _vm.textSize },
                                        on: {
                                          input: [
                                            function ($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.textSize = $event.target.value
                                            },
                                            _vm.changeTextSize,
                                          ],
                                        },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "text-format",
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeTextFormat("bold")
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "BaseNewTooltip",
                                    { attrs: { text: "Bold" } },
                                    [
                                      _vm.formatBold
                                        ? _c("img", {
                                            attrs: {
                                              src: require("@/assets/audiogram/text-format/text-bold-active.svg"),
                                            },
                                          })
                                        : _c("img", {
                                            attrs: {
                                              src: require("@/assets/audiogram/text-format/text-bold.svg"),
                                            },
                                          }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "text-format",
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeTextFormat("italic")
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "BaseNewTooltip",
                                    { attrs: { text: "Italics" } },
                                    [
                                      _vm.formatItalic
                                        ? _c("img", {
                                            attrs: {
                                              src: require("@/assets/audiogram/text-format/text-italic-active.svg"),
                                            },
                                          })
                                        : _c("img", {
                                            attrs: {
                                              src: require("@/assets/audiogram/text-format/text-italic.svg"),
                                            },
                                          }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "text-format",
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeTextFormat("underline")
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "BaseNewTooltip",
                                    { attrs: { text: "Underline" } },
                                    [
                                      _vm.formatUnderline
                                        ? _c("img", {
                                            attrs: {
                                              src: require("@/assets/audiogram/text-format/text-underline-active.svg"),
                                            },
                                          })
                                        : _c("img", {
                                            attrs: {
                                              src: require("@/assets/audiogram/text-format/text-underline.svg"),
                                            },
                                          }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "text-format",
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeTextFormat(
                                        "strikethrough"
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "BaseNewTooltip",
                                    { attrs: { text: "Cross out" } },
                                    [
                                      _vm.formatStrikethrough
                                        ? _c("img", {
                                            attrs: {
                                              src: require("@/assets/audiogram/text-format/text-strikethrough-active.svg"),
                                            },
                                          })
                                        : _c("img", {
                                            attrs: {
                                              src: require("@/assets/audiogram/text-format/text-strikethrough.svg"),
                                            },
                                          }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { attrs: { id: "text-align" } }, [
                              _c(
                                "div",
                                {
                                  staticClass: "text-align",
                                  class: {
                                    "align-text-active": _vm.alignLeftActive,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.alignText("left")
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "BaseNewTooltip",
                                    { attrs: { text: "Align text left" } },
                                    [
                                      _vm.alignLeftActive
                                        ? _c("img", {
                                            attrs: {
                                              src: require("@/assets/audiogram/text-alignment/align-left-active.svg"),
                                            },
                                          })
                                        : _c("img", {
                                            attrs: {
                                              src: require("@/assets/audiogram/text-alignment/align-left.svg"),
                                            },
                                          }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "text-align align-text-active",
                                  class: {
                                    "align-text-active": _vm.alignCenterActive,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.alignText("center")
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "BaseNewTooltip",
                                    { attrs: { text: "Align text center" } },
                                    [
                                      _vm.alignCenterActive
                                        ? _c("img", {
                                            attrs: {
                                              src: require("@/assets/audiogram/text-alignment/align-center-active.svg"),
                                            },
                                          })
                                        : _c("img", {
                                            attrs: {
                                              src: require("@/assets/audiogram/text-alignment/align-center.svg"),
                                            },
                                          }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "text-align",
                                  class: {
                                    "align-text-active": _vm.alignRightActive,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.alignText("right")
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "BaseNewTooltip",
                                    { attrs: { text: "Align text right" } },
                                    [
                                      _vm.alignRightActive
                                        ? _c("img", {
                                            attrs: {
                                              src: require("@/assets/audiogram/text-alignment/align-right-active.svg"),
                                            },
                                          })
                                        : _c("img", {
                                            attrs: {
                                              src: require("@/assets/audiogram/text-alignment/align-right.svg"),
                                            },
                                          }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "text-align",
                                  class: {
                                    "align-text-active": _vm.alignJustifyActive,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.alignText("justify")
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "BaseNewTooltip",
                                    { attrs: { text: "Align text normal" } },
                                    [
                                      _vm.alignJustifyActive
                                        ? _c("img", {
                                            attrs: {
                                              src: require("@/assets/audiogram/text-alignment/justify-active.svg"),
                                            },
                                          })
                                        : _c("img", {
                                            attrs: {
                                              src: require("@/assets/audiogram/text-alignment/justify.svg"),
                                            },
                                          }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                !_vm.showTextToolbar && !_vm.isOpenInEditMode,
                              expression:
                                "!showTextToolbar && !isOpenInEditMode",
                            },
                          ],
                          staticClass: "flex",
                        },
                        [
                          _c("div", { staticClass: "mr2" }, [
                            _c(
                              "i",
                              {
                                staticClass:
                                  "f6 material-icons white v-mid fw6",
                              },
                              [_vm._v("crop")]
                            ),
                            _c("span", [_vm._v(" Resize: ")]),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.orientation,
                                    expression: "orientation",
                                  },
                                ],
                                staticClass:
                                  "resize bg-adori-gray white f6 ba br2",
                                on: {
                                  change: [
                                    function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.orientation = $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    },
                                    function ($event) {
                                      return _vm.resizeCanvas(
                                        $event.target.value
                                      )
                                    },
                                  ],
                                },
                              },
                              _vm._l(_vm.OrientationList, function (item, i) {
                                return _c(
                                  "option",
                                  { key: i, domProps: { value: item.id } },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(item.value) +
                                        "\n                  "
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]),
                        ]
                      ),
                      !_vm.showTextToolbar && _vm.showElementColor
                        ? _c("div", { staticClass: "flex" }, [
                            !_vm.isOpenInEditMode
                              ? _c("div", { staticClass: "partition" })
                              : _vm._e(),
                            _c("div", { attrs: { id: "color-choose" } }, [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "on-clickaway",
                                      rawName: "v-on-clickaway",
                                      value: _vm.closeElementColorPicker,
                                      expression: "closeElementColorPicker",
                                    },
                                  ],
                                  attrs: { id: "color-choose" },
                                  on: { click: _vm.toggleElementColorPicker },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "relative" },
                                    [
                                      _c(
                                        "BaseNewTooltip",
                                        { attrs: { text: "Element color" } },
                                        [
                                          _c("div", {
                                            staticClass: "square-swatch",
                                            style:
                                              "background-color: " +
                                              _vm.elementColorValue,
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                            },
                                          },
                                        },
                                        [
                                          _vm.displayElementColorPicker
                                            ? _c("Sketch", {
                                                staticClass: "sketch-picker",
                                                attrs: {
                                                  value:
                                                    _vm.elementSketchColors,
                                                },
                                                on: {
                                                  input:
                                                    _vm.updateElementColorFromPicker,
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ]),
                    _c(
                      "div",
                      { staticClass: "controls-items-right flex ml2" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "obj-pos",
                            on: { click: _vm.moveObjectToFront },
                          },
                          [
                            _c(
                              "BaseNewTooltip",
                              { attrs: { text: "Bring forward" } },
                              [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/audiogram/move-forward.svg"),
                                  },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "obj-pos mr1",
                            on: { click: _vm.moveObjectToBack },
                          },
                          [
                            _c(
                              "BaseNewTooltip",
                              { attrs: { text: "Bring backward" } },
                              [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/audiogram/move-backward.svg"),
                                  },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "BaseNewTooltip",
                          { attrs: { text: "Download image" } },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "item mr3",
                                on: { click: _vm.downloadImage },
                              },
                              [
                                _c(
                                  "i",
                                  {
                                    staticClass:
                                      "f4 material-icons white v-mid fw6",
                                  },
                                  [_vm._v("download")]
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "BaseNewTooltip",
                          { attrs: { text: "Delete selected" } },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "item",
                                on: { click: _vm.deleteObject },
                              },
                              [
                                _c(
                                  "i",
                                  {
                                    staticClass:
                                      "f4 material-icons white v-mid fw6",
                                  },
                                  [_vm._v("delete_outline")]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "relative flex justify-center mb2",
                    attrs: { tabIndex: "1000" },
                    on: {
                      keyup: function ($event) {
                        return _vm.checkDelete($event)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isGif,
                            expression: "isGif",
                          },
                        ],
                        staticStyle: { "min-height": "280px" },
                      },
                      [
                        _vm.enabled
                          ? _c("img", {
                              staticStyle: { "max-height": "330px" },
                              attrs: { src: _vm.tagEditStoreData.data.image },
                            })
                          : _vm._e(),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isVideo,
                            expression: "isVideo",
                          },
                        ],
                        staticStyle: { "min-height": "280px" },
                      },
                      [
                        _vm.enabled
                          ? _c("video", {
                              staticStyle: { "max-height": "330px" },
                              attrs: {
                                controls: "",
                                src: _vm.tagEditStoreData.data.videoSrc,
                              },
                            })
                          : _vm._e(),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isGif && !_vm.isVideo,
                            expression: "!isGif && !isVideo",
                          },
                        ],
                      },
                      [_c("canvas", { ref: "can" })]
                    ),
                    _vm._l(_vm.inputValues, function (v, i) {
                      return _vm.selectedTagType === "poll"
                        ? _c("BaseAdoriTagCaption", {
                            key: i,
                            staticClass:
                              "ba br2 b--adori-gray ml2 lh-copy f-12px tl",
                            class: i != 0 ? "truncate " : "",
                            staticStyle: { width: "calc(100% - 400px)" },
                            attrs: {
                              caption: v,
                              topMargin: 0.25 + 0.09 * i + 0.1 * !!i,
                            },
                          })
                        : _vm._e()
                    }),
                    _c("a", {
                      ref: "imageDownloadRef",
                      staticClass: "dn",
                      attrs: { download: "" },
                    }),
                  ],
                  2
                ),
                _c("div", { staticClass: "flex justify-end relative" }, [
                  _vm.toggleSaveShareScreen
                    ? _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "on-clickaway",
                              rawName: "v-on-clickaway",
                              value: function () {
                                return (_vm.toggleSaveShareScreen = false)
                              },
                              expression:
                                "() => (toggleSaveShareScreen = false)",
                            },
                          ],
                          staticClass: "save-share-container",
                        },
                        [
                          !_vm.isYoutube && _vm.selectedTagType !== "poll"
                            ? _c(
                                "div",
                                { staticClass: "flex flex-column" },
                                [
                                  _c("BaseCheckbox", {
                                    staticClass: "ml0",
                                    attrs: {
                                      text: "Tag can be saved",
                                      initialState: _vm.saveable,
                                    },
                                    on: {
                                      toggle: function ($event) {
                                        _vm.saveable = $event
                                      },
                                    },
                                  }),
                                  _c("BaseCheckbox", {
                                    attrs: {
                                      text: "Tag can be shared",
                                      initialState: _vm.shareable,
                                    },
                                    on: {
                                      toggle: function ($event) {
                                        _vm.shareable = $event
                                      },
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("div", { staticClass: "mh2 mt1" }, [
                            _c(
                              "select",
                              {
                                staticClass:
                                  "db bg-adori-gray gray ba b--gray br2 f6 w-100 h2",
                                on: {
                                  change: function ($event) {
                                    return _vm.changeTagCollection(
                                      $event.target.value
                                    )
                                  },
                                },
                              },
                              [
                                _c(
                                  "option",
                                  {
                                    staticClass: "dn",
                                    attrs: {
                                      disabled: "disabled",
                                      selected: "",
                                    },
                                  },
                                  [_vm._v("Add to collection")]
                                ),
                                _vm._l(
                                  _vm.tagCollectionsOptions,
                                  function (item, i) {
                                    return _c(
                                      "option",
                                      {
                                        key: item.value,
                                        domProps: { value: item.value },
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(item.text) +
                                            "\n                "
                                        ),
                                      ]
                                    )
                                  }
                                ),
                              ],
                              2
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ]),
                _c("div", { staticClass: "flex justify-between mt2" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.isGif && !_vm.isVideo,
                          expression: "!isGif && !isVideo",
                        },
                      ],
                      staticClass: "flex",
                    },
                    [
                      _c("BaseButtonRed", {
                        staticClass: "clear-btn ml2 mr2",
                        attrs: {
                          text: "Clear",
                          icon: "clear",
                          onClick: _vm.clearCanvas,
                        },
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "mr2 undo",
                          class: _vm.isUndoDisabled
                            ? "o-40 not-allowed"
                            : "pointer",
                          on: {
                            click: function ($event) {
                              return _vm.buttonAction("undo")
                            },
                          },
                        },
                        [
                          _c("BaseNewTooltip", { attrs: { text: "Undo" } }, [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/audiogram/arrow-up-left.svg"),
                              },
                            }),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "undo",
                          class: _vm.isRedoDisabled
                            ? "o-40 not-allowed"
                            : "pointer",
                          on: {
                            click: function ($event) {
                              return _vm.buttonAction("redo")
                            },
                          },
                        },
                        [
                          _c("BaseNewTooltip", { attrs: { text: "Redo" } }, [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/audiogram/arrow-up-right.svg"),
                              },
                            }),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", [
                    _vm.loadingCanvas
                      ? _c("div", { staticClass: "lds-ellipsis" }, [
                          _c("div"),
                          _c("div"),
                          _c("div"),
                          _c("div"),
                        ])
                      : _vm._e(),
                  ]),
                  _c(
                    "div",
                    { staticClass: "flex" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex items-center justify-center mr3 pointer",
                          on: {
                            click: function ($event) {
                              _vm.toggleSaveShareScreen =
                                !_vm.toggleSaveShareScreen
                            },
                          },
                        },
                        [
                          _c(
                            "i",
                            {
                              staticClass:
                                "material-icons v-mid white text-shadow",
                            },
                            [_vm._v(" settings ")]
                          ),
                        ]
                      ),
                      _c("BaseButtonRed", {
                        attrs: {
                          text: _vm.saveText,
                          onClick: _vm.handleSave,
                          loading: _vm.audiogramLoader,
                          disabled: _vm.audiogramLoader,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isSelectedTagAd,
                    expression: "isSelectedTagAd",
                  },
                ],
                staticClass: "canvas-area tc ml3",
              },
              [
                _vm.selectedTagType === "display_ad"
                  ? _c("img", {
                      attrs: {
                        src: require("@/assets/tags/mockup_display.png"),
                        height: "550",
                      },
                    })
                  : _vm._e(),
                _vm.selectedTagType === "audio"
                  ? _c("img", {
                      attrs: {
                        src: require("@/assets/tags/mockup_audio.png"),
                        height: "550",
                      },
                    })
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "flex justify-end mt2" },
                  [
                    _c("BaseButtonRed", {
                      attrs: {
                        text: _vm.saveText,
                        onClick: _vm.handleSave,
                        loading: _vm.audiogramLoader,
                        disabled: _vm.audiogramLoader,
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]),
          _vm.isTagPreviewShown
            ? _c("ModalTagPreview", {
                on: {
                  "close-preview": function () {
                    _vm.isTagPreviewShown = false
                  },
                },
              })
            : _vm._e(),
          _vm.showImagePreview
            ? _c("ModalPreviewImages", {
                attrs: { image: _vm.image },
                on: {
                  close: function ($event) {
                    _vm.showImagePreview = false
                  },
                },
              })
            : _vm._e(),
          _vm.showPlans
            ? _c("ModalPlans", {
                attrs: { customModal: "" },
                on: {
                  closeModal: function ($event) {
                    _vm.showPlans = false
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }