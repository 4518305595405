var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "white" }, [
    _vm.currentPlan
      ? _c("div", {}, [
          _c("div", { staticClass: "subscription-details" }, [
            _c("h3", [_vm._v("Subscription Details")]),
            _c("div", { staticClass: "current-plan" }, [
              _c("h4", [_vm._v("Current Plan")]),
              _c("span", { staticClass: "black mr3" }, [
                _c("strong", [_vm._v("Plan Name:")]),
                _vm._v(" " + _vm._s(_vm.currentPlan.name)),
              ]),
              _vm.planDetails
                ? _c("span", { staticClass: "black mr3" }, [
                    _c("strong", [_vm._v("Subscription Status:")]),
                    _c(
                      "span",
                      {
                        staticClass: "status-badge ml2 bg-orange",
                        class: {
                          "bg-green": _vm.planDetails.status === "active",
                          "bg-adori-red": _vm.planDetails.status === "inactive",
                        },
                      },
                      [_vm._v(_vm._s(_vm.planDetails.status))]
                    ),
                  ])
                : _vm._e(),
              _vm.planDetails
                ? _c("span", { staticClass: "black" }, [
                    _c("strong", [_vm._v("Renewed:")]),
                    _c("span", [_vm._v(" " + _vm._s(_vm.renewalDate))]),
                  ])
                : _vm._e(),
            ]),
          ]),
        ])
      : _vm._e(),
    _c("div", { staticClass: "f5 flex center justify-center mv3" }, [
      _c(
        "div",
        {
          staticClass: "pa2 br3 br--left pointer",
          class:
            _vm.selectedPlanMode == "MONTHLY"
              ? "bg-adori-red"
              : "bg-adori-light-gray",
          on: {
            click: function ($event) {
              return _vm.setPlanMode("MONTHLY")
            },
          },
        },
        [_vm._v("\n      Monthly\n    ")]
      ),
      _c(
        "div",
        {
          staticClass: "pa2 br3 br--right pointer",
          class:
            _vm.selectedPlanMode == "ANNUALLY"
              ? "bg-adori-red"
              : "bg-adori-light-gray",
          on: {
            click: function ($event) {
              return _vm.setPlanMode("ANNUALLY")
            },
          },
        },
        [_vm._v("\n      Annually\n    ")]
      ),
    ]),
    _c("div", { staticClass: "pricing-container br3" }, [
      _c("div", { staticClass: "pricing-table" }, [
        _c(
          "div",
          { staticClass: "pricing-header" },
          [
            _c("div", { staticClass: "feature-label" }),
            _vm._l(_vm.plans[_vm.selectedPlanMode], function (tier) {
              return _c(
                "div",
                {
                  key: tier.name,
                  staticClass: "pricing-column flex-column",
                  class: {
                    "premium-column relative overflow-hidden": tier.popular,
                  },
                },
                [
                  _c("div", { staticClass: "tier-name" }, [
                    _vm._v(
                      "\n            " + _vm._s(tier.name) + "\n            "
                    ),
                    tier.popular
                      ? _c("span", { staticClass: "popular-tag" }, [
                          _vm._v("Popular"),
                        ])
                      : _vm._e(),
                  ]),
                  tier.price == 0
                    ? _c("div", { staticClass: "tier-price f3" }, [
                        _vm._v("$" + _vm._s(tier.price)),
                        _c("span", { staticClass: "f6" }, [_vm._v("/forever")]),
                      ])
                    : _c("div", { staticClass: "tier-price f3" }, [
                        _vm._v("$" + _vm._s(tier.price)),
                        _c("span", { staticClass: "f6" }, [_vm._v("/month")]),
                      ]),
                  tier.price != 0 && _vm.selectedPlanMode == "ANNUALLY"
                    ? _c("div", { staticClass: "silver mt1" }, [
                        _vm._v(
                          "\n            $" +
                            _vm._s(tier.annualPrice) +
                            " billed annually\n          "
                        ),
                      ])
                    : _vm._e(),
                  tier.price != 0 && _vm.selectedPlanMode == "ANNUALLY"
                    ? _c("div", { staticClass: "silver mt1 green" }, [
                        _vm._v(
                          "\n            Save $" +
                            _vm._s(tier.discountedAmount) +
                            "\n          "
                        ),
                      ])
                    : _vm._e(),
                  _c(
                    "button",
                    {
                      staticClass:
                        "mta w-100 pointer br2 bg-transparent ba bg-adori-red shadow-2 mt3 white pa2 f6 pointer flex justify-center",
                      class: {
                        cmtop:
                          tier.price == 0 && _vm.selectedPlanMode == "ANNUALLY",
                        "bg-adori-very-light-gray": tier.planActive,
                        "o-40 not-allowed": _vm.isUpgrading,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.choosePlan(tier.networkType)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.btnText(tier.networkType)) +
                          "\n            "
                      ),
                      _vm.isUpgrading
                        ? _c("div", { staticClass: "ml2 loader loader-inline" })
                        : _vm._e(),
                    ]
                  ),
                ]
              )
            }),
          ],
          2
        ),
        _c("div", { staticClass: "features-grid" }, [
          _c(
            "div",
            { staticClass: "feature-row" },
            [
              _c("div", { staticClass: "feature-label" }, [
                _vm._v("Video creation"),
              ]),
              _vm._l(_vm.plans[_vm.selectedPlanMode], function (tier) {
                return _c(
                  "div",
                  {
                    key: tier.name + "-video",
                    staticClass: "feature-cell",
                    class: { "premium-column": tier.popular },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(tier.features.videoCreation) +
                        "\n          "
                    ),
                  ]
                )
              }),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "feature-row" },
            [
              _c("div", { staticClass: "feature-label" }, [
                _vm._v("Video export"),
              ]),
              _vm._l(_vm.plans[_vm.selectedPlanMode], function (tier) {
                return _c(
                  "div",
                  {
                    key: tier.name + "-export",
                    staticClass: "feature-cell",
                    class: { "premium-column": tier.popular },
                  },
                  [
                    tier.features.videoExport
                      ? _c("span", [
                          _vm._v(
                            "\n              " +
                              _vm._s(tier.features.videoExport) +
                              "\n            "
                          ),
                        ])
                      : _c("span", { staticClass: "cross" }, [_vm._v("✕")]),
                  ]
                )
              }),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "feature-row" },
            [
              _c("div", { staticClass: "feature-label" }, [
                _vm._v("Maximum script length video"),
              ]),
              _vm._l(_vm.plans[_vm.selectedPlanMode], function (tier) {
                return _c(
                  "div",
                  {
                    key: tier.name + "-script",
                    staticClass: "feature-cell",
                    class: { "premium-column": tier.popular },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(tier.features.maxScriptLength) +
                        "\n          "
                    ),
                  ]
                )
              }),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "feature-row" },
            [
              _c("div", { staticClass: "feature-label" }, [
                _vm._v("Resolution"),
              ]),
              _vm._l(_vm.plans[_vm.selectedPlanMode], function (tier) {
                return _c(
                  "div",
                  {
                    key: tier.name + "-resolution",
                    staticClass: "feature-cell",
                    class: { "premium-column": tier.popular },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(tier.features.resolution) +
                        "\n          "
                    ),
                  ]
                )
              }),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "feature-row" },
            [
              _c("div", { staticClass: "feature-label" }, [
                _vm._v("AI Image Generator"),
              ]),
              _vm._l(_vm.plans[_vm.selectedPlanMode], function (tier) {
                return _c(
                  "div",
                  {
                    key: tier.name + "-ai",
                    staticClass: "feature-cell",
                    class: { "premium-column": tier.popular },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(tier.features.aiImageGenerator) +
                        "\n          "
                    ),
                  ]
                )
              }),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "feature-row" },
            [
              _c("div", { staticClass: "feature-label" }, [
                _vm._v("Publish to YouTube"),
              ]),
              _vm._l(_vm.plans[_vm.selectedPlanMode], function (tier) {
                return _c(
                  "div",
                  {
                    key: tier.name + "-youtube",
                    staticClass: "feature-cell",
                    class: { "premium-column": tier.popular },
                  },
                  [
                    tier.features.publishToYoutube
                      ? _c("span", { staticClass: "checkmark" }, [_vm._v("✓")])
                      : _c("span", { staticClass: "cross" }, [_vm._v("✕")]),
                  ]
                )
              }),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "feature-row" },
            [
              _c("div", { staticClass: "feature-label" }, [
                _vm._v("Customer Voice cloning"),
              ]),
              _vm._l(_vm.plans[_vm.selectedPlanMode], function (tier) {
                return _c(
                  "div",
                  {
                    key: tier.name + "-voice",
                    staticClass: "feature-cell",
                    class: { "premium-column": tier.popular },
                  },
                  [
                    tier.features.customerVoiceCloning
                      ? _c("span", { staticClass: "checkmark" }, [_vm._v("✓")])
                      : _c("span", { staticClass: "cross" }, [_vm._v("✕")]),
                  ]
                )
              }),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "feature-row" },
            [
              _c("div", { staticClass: "feature-label" }, [
                _vm._v("Curation Support"),
              ]),
              _vm._l(_vm.plans[_vm.selectedPlanMode], function (tier) {
                return _c(
                  "div",
                  {
                    key: tier.name + "-curation",
                    staticClass: "feature-cell",
                    class: { "premium-column": tier.popular },
                  },
                  [
                    typeof tier.features.curationSupport === "string"
                      ? [
                          _vm._v(
                            "\n              " +
                              _vm._s(tier.features.curationSupport) +
                              "\n            "
                          ),
                        ]
                      : _c("span", { staticClass: "cross" }, [_vm._v("✕")]),
                  ],
                  2
                )
              }),
            ],
            2
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }