var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.exportLoading
    ? _c("div", [
        _c("div", {
          staticClass:
            "w-100 bg-adori-gray ba mv2 br2 bw1 b--adori-gray flex justify-center items-center skeleton cus-size",
        }),
      ])
    : _c(
        "div",
        [
          _c("a", {
            ref: "videoDownloadRef",
            staticClass: "dn",
            attrs: { download: "" },
          }),
          _c(
            "div",
            {
              staticClass:
                "flex items-center justify-between ba b--adori-gray pa3 br2 mv2",
            },
            [
              _c("div", { staticClass: "flex items-center" }, [
                _c("img", {
                  staticClass: "mr3",
                  attrs: { src: _vm.exportImage, alt: "", height: "30" },
                }),
                _c(
                  "div",
                  {
                    staticClass: "f6 overflow-y-hidden truncate light-gray",
                    staticStyle: { width: "200px" },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.exportData
                            ? _vm.exportData.title
                            : "Loading audio details..."
                        ) +
                        "\n      "
                    ),
                  ]
                ),
                _c("div", { staticClass: "f6 gray ml2" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.exportData
                          ? _vm.exportDate
                          : "Loading audio details..."
                      ) +
                      "\n      "
                  ),
                ]),
                _vm.showProgressBar
                  ? _c(
                      "div",
                      { staticClass: "flex items-center ml2 progress-width" },
                      [
                        _c("ProgressBar", {
                          attrs: {
                            options: _vm.options,
                            value: _vm.progressBarValue,
                            max: 100,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.showProgressBar
                  ? _c("div", { staticClass: "ml2 loader loader-inline" })
                  : _vm._e(),
              ]),
              _vm.isRenderingFailed
                ? _c("div", { staticClass: "red f6" }, [
                    _c(
                      "i",
                      {
                        staticClass:
                          "material-icons f5 white v-mid pa1 pointer",
                      },
                      [_vm._v(" warning ")]
                    ),
                    _c("span", [_vm._v("Video rendering failed")]),
                  ])
                : !_vm.showProgressBar
                ? _c("div", { staticClass: "flex items-center" }, [
                    _c(
                      "button",
                      {
                        staticClass:
                          "flex items-center youtube-link f5 br2 mr2 pointer white",
                        attrs: {
                          disabled:
                            _vm.youtubeProcessing ||
                            _vm.isAccountLoading ||
                            _vm.youtubeLoader,
                        },
                        on: {
                          click: function ($event) {
                            _vm.youtubeId
                              ? _vm.youtubeLink()
                              : _vm.publishVideo()
                          },
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "mr1 adori-red pointer",
                          attrs: {
                            src: require("@/assets/social-youtube-red.png"),
                            alt: "",
                            height: "12",
                          },
                        }),
                        _c("span", [_vm._v(_vm._s(_vm.youtubeBtnText))]),
                        _vm.youtubeProcessing ||
                        _vm.isAccountLoading ||
                        _vm.youtubeLoader
                          ? _c("div", {
                              staticClass: "ml2 loader loader-inline",
                            })
                          : _vm._e(),
                      ]
                    ),
                    _c(
                      "button",
                      {
                        staticClass:
                          "flex items-center youtube-link f5 br2 pointer white",
                        attrs: { disabled: _vm.downloadLoader },
                        on: { click: _vm.downloadVideo },
                      },
                      [
                        _c(
                          "i",
                          {
                            staticClass:
                              "material-icons f5 white text-shadow pointer",
                          },
                          [_vm._v(" download ")]
                        ),
                        _c("span", [_vm._v("Download")]),
                        _vm.downloadLoader
                          ? _c("div", {
                              staticClass: "ml2 loader loader-inline",
                            })
                          : _vm._e(),
                      ]
                    ),
                  ])
                : _vm._e(),
            ]
          ),
          _vm.showYoutubeSettings
            ? _c("ModalYoutubeSettingsV2", {
                attrs: {
                  trackData: _vm.audioData,
                  tagPositions: _vm.tagPositions,
                  publishLoader: _vm.youtubeProcessing,
                },
                on: {
                  closeModal: function ($event) {
                    _vm.showYoutubeSettings = false
                  },
                  publish: _vm.handlePublish,
                },
              })
            : _vm._e(),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }