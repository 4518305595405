import Vue from 'vue'
import { StoreOptions, GetterTree, MutationTree, ActionTree } from 'vuex'
import AdoriService from '@/services/adori'
import store from '@/store'
import { LEVELS } from '@/constants/permissions'
import { SUBSCRIPTION_STATUS } from '@/constants/subscription'
import { daysRemaining, toReadableDate } from '@/utils/time'
import get from 'lodash.get'
import * as Sentry from '@sentry/vue'

let collectPermissions: any = (perms: any, index: number, networkPerms: any, showPerms: any) => {
  if (perms.length === index) return []
  else {
    let perm = perms[index]
    const permTokens = perm.split('.')
    const level = permTokens[0]
    const resource = permTokens[1]
    const action = permTokens[2]
    let resourcePermissions = null
    if (level.toUpperCase() === LEVELS.network.toUpperCase()) {
      resourcePermissions = networkPerms.filter((r: any) => r.resource.toUpperCase() === resource.toUpperCase())[0]
    } else if (level.toUpperCase() === LEVELS.show.toUpperCase()) {
      resourcePermissions = showPerms.filter((r: any) => r.resource.toUpperCase() === resource.toUpperCase())[0]
    }
    const actionPermissions =
      (resourcePermissions &&
        resourcePermissions.perms.filter((a: any) => a.action.toUpperCase() === action.toUpperCase())[0]) ||
      null
    let data: any = []
    if (actionPermissions && actionPermissions.implied) data = [...perms, ...actionPermissions.implied]
    else data = [...perms]
    data = Array.from(new Set(data))
    return [...data, ...collectPermissions(data, index + 1, networkPerms, showPerms)]
  }
}

const state: any = {
  networks: null,
  currentNetwork: null,
  currentNetworkPermissions: null,
  currentNetworkSubscriptions: null,
  currentNetworkPaymentCards: null,
  networkId: '',
  roles: null,
  networkUsers: null,
  showUsers: {},
  currentNetworkShowPermissions: {},
  validNetworkOrShowUser: null,
  validNetworkUser: null,
  networkType: null,
  trialPeriodEnded: false,
  alertPromptMessage: null,
  selectedProduct: 'VIDEO',
}

const initialState: any = {
  ...JSON.parse(JSON.stringify(state)),
}

const getters: GetterTree<any, any> = {
  networks: (state) => state.networks,
  currentNetwork: (state) => state.currentNetwork,
  currentNetworkPermissions: (state) => state.currentNetworkPermissions,
  currentNetworkSubscriptions: (state) => state.currentNetworkSubscriptions,
  currentNetworkPaymentCards: (state) => state.currentNetworkPaymentCards,
  networkId: (state) => state.networkId || (state.currentNetwork && state.currentNetwork.id),
  currentRole: (state) => state.currentNetwork && state.currentNetwork.role.name,
  roles: (state) => state.roles.network_roles.system_roles,
  showroles: (state) => state.roles.show_roles.system_roles,
  networkUsers: (state) => state.networkUsers && state.networkUsers.data.network_users,
  showUsers: (state) => (feedUid: string) => state.showUsers[feedUid] && state.showUsers[feedUid].data.show_users,
  currentNetworkShowPermissions: (state) => state.currentNetworkShowPermissions,
  currentNetworkShowPermissionsForShow: (state) => (feedUid: string) => state.currentNetworkShowPermissions[feedUid],
  validNetworkOrShowUser: (state) => state.validNetworkOrShowUser,
  validNetworkUser: (state) => state.validNetworkUser,
  networkType: (state) => state.networkType,
  trialPeriodEnded: (state) => state.trialPeriodEnded,
  isStudio: (state) => ['CUSTOM', 'SAAS'].includes(state.networkType),
  isYoutubeNew: (state) => ['YOUTUBE_METERED', 'YOUTUBE_ENTERPRISE', 'YOUTUBE_PRO'].includes(state.networkType),
  isYoutubeOld: (state) => ['YOUTUBE'].includes(state.networkType),
  isVideo: (state) =>
    [
      'VIDEO_FREE',
      'VIDEO_BASIC',
      'VIDEO_PRO',
      'ADORI_BASIC_MONTHLY',
      'ADORI_BASIC_YEARLY',
      'ADORI_PREMIUM_MONTHLY',
      'ADORI_PREMIUM_YEARLY',
      'ADORI_BUSINESS_MONTHLY',
      'ADORI_BUSINESS_YEARLY',
    ].includes(state.networkType),
  isYoutube: (state) =>
    [
      'YOUTUBE',
      'YOUTUBE_METERED',
      'YOUTUBE_ENTERPRISE',
      'YOUTUBE_PRO',
      'VIDEO_FREE',
      'VIDEO_BASIC',
      'VIDEO_PRO',
      'ADORI_BASIC_MONTHLY',
      'ADORI_BASIC_YEARLY',
      'ADORI_PREMIUM_MONTHLY',
      'ADORI_PREMIUM_YEARLY',
      'ADORI_BUSINESS_MONTHLY',
      'ADORI_BUSINESS_YEARLY',
    ].includes(state.networkType),
  alertPromptMessage: (state) => state.alertPromptMessage,
  selectedProduct: (state) => state.selectedProduct,
}

const mutations: MutationTree<any> = {
  resetState(state) {
    Object.keys(state).forEach((key: any) => {
      Vue.set(state, key, initialState[key])
    })
  },

  setAlertPromptMessage() {
    Vue.set(state, 'alertPromptMessage', null)
  },

  setNetworks(state, networks) {
    Vue.set(state, 'networks', networks)
  },

  setCurrentNetwork(state, currentNetwork) {
    Vue.set(state, 'currentNetwork', currentNetwork)
  },
  setNetworkType(state, type) {
    Vue.set(state, 'networkType', type)
  },

  setCurrentNetworkPermissions(state, currentNetworkPermissions) {
    Vue.set(state, 'currentNetworkPermissions', currentNetworkPermissions)
  },

  setNetworkId(state, networkId) {
    Vue.set(state, 'networkId', networkId)
  },

  setRoles(state, roles) {
    Vue.set(state, 'roles', roles)
  },

  setUsers(state, networkUsers) {
    Vue.set(state, 'networkUsers', networkUsers)
  },

  setShowUsers(state, { feedUid, showUsers }) {
    Vue.set(state.showUsers, feedUid, showUsers)
  },

  setCurrentNetworkShowPermissions(state, { feedUid, networkShowPermissions, clear = false }) {
    if (!clear) Vue.set(state.currentNetworkShowPermissions, feedUid, networkShowPermissions)
    else Vue.set(state, 'currentNetworkShowPermissions', {})
  },

  setValidNetworkOrShowUser(state, validNetworkOrShowUser) {
    Vue.set(state, 'validNetworkOrShowUser', validNetworkOrShowUser)
  },

  setValidNetworkUser(state, validNetworkUser) {
    Vue.set(state, 'validNetworkUser', validNetworkUser)
  },

  updateCurrentNetworkInfo(state, info) {
    const data = { ...state.currentNetwork, ...info }
    Vue.set(state.currentNetwork, 'network', data)
  },

  updateCurrentNetworkSubscription(state, info) {
    Vue.set(state, 'currentNetworkSubscriptions', info)
  },

  setCurrentNetworkPaymentCards(state, cards) {
    Vue.set(state, 'currentNetworkPaymentCards', cards)
  },

  setTrialPeriodEnded(state, status) {
    Vue.set(state, 'trialPeriodEnded', status)
  },
  setSelectedProduct(state, product) {
    Vue.set(state, 'selectedProduct', product)
    localStorage.setItem('selectedProduct', product)
  },
}

const actions: ActionTree<any, any> = {
  resetNetworksState(context) {
    context.commit('resetState')
  },

  async getMembersNetworks(context, { search, limit = 50, offset = 0 }) {
    let networks: any = {}
    if (!!search) {
      networks = await AdoriService.fetchProfileNetworks({
        params: {
          limit,
          offset,
          search,
        },
      })
    } else {
      networks = await AdoriService.fetchProfileNetworks({
        params: {
          limit,
          offset,
        },
      })
    }
    networks.member_networks = [...context.getters.profile.networks.member_networks, ...networks.member_networks]
    networks.member_shows = [...context.getters.profile.networks.member_shows]

    if (networks) {
      context.dispatch('setNetworks', {
        networks: networks,
        index: 0,
      })
    }
  },

  async setNetworks(context, { networks, index }) {
    const networkPerms = context.getters.networkPerms
    const showPerms = context.getters.showPerms

    let showMemebers: any = []
    networks.member_shows.forEach((member: any) => {
      showMemebers.push({
        network: member.network,
        role: member.feeds[0].role,
        feeds: member.feeds,
      })
    })
    // @ts-ignore
    const storeNetwork = !!localStorage.getItem('network') ? JSON.parse(localStorage.getItem('network')) : false

    let allNetworks = storeNetwork
      ? [...networks.member_networks, ...showMemebers, storeNetwork]
      : [...networks.member_networks, ...showMemebers]

    allNetworks = [
      ...allNetworks.filter((network: any) => network.role.name === 'Network Owner'),
      ...allNetworks.filter((network: any) => network.role.name !== 'Network Owner'),
    ]

    if (localStorage.getItem('networkId') === null) {
      localStorage.setItem('networkId', get(allNetworks[index], 'network.id'))
      localStorage.setItem('network', JSON.stringify(allNetworks[index]))
    } else {
      const tempIndex = allNetworks.findIndex((element: any) => {
        return element.network.id === localStorage.getItem('networkId')
      })
      index = tempIndex === -1 ? index : tempIndex
    }

    context.commit('setCurrentNetworkShowPermissions', {
      clear: true,
    })
    const currentNetwork = allNetworks[index]
    const currentNetworkShows =
      networks.member_shows[index - networks.member_networks.length] || networks.member_shows[0]
    context.commit('setNetworks', allNetworks || [])
    context.commit('setCurrentNetwork', allNetworks[index] || {})
    context.commit('setCurrentNetwork', allNetworks[index] || {})
    const networkType = get(allNetworks[index], 'network.networkType', {})
    const networkId = get(allNetworks[index], 'network.id', '')
    context.commit('setNetworkType', networkType)
    context.commit('setNetworkId', networkId)
    if (networks.member_networks.length !== 0 && currentNetwork.role.name.includes('Network')) {
      let currentNetworkPermissions = currentNetwork.role.permissions
      let curPerms: any = [...currentNetworkPermissions]
      curPerms = collectPermissions(curPerms, 0, networkPerms, showPerms)
      curPerms = Array.from(new Set(curPerms))
      context.commit('setCurrentNetworkPermissions', curPerms)
    }
    if (networks.member_shows.length !== 0) {
      currentNetworkShows.feeds.map((show: any) => {
        const currentNetworkShowPermissions = show.role.permissions
        let curShowPerms: any = [...currentNetworkShowPermissions]
        curShowPerms = collectPermissions(curShowPerms, 0, networkPerms, showPerms)
        curShowPerms = Array.from(new Set(curShowPerms))
        // context.commit('setCurrentNetworkShowPermissions', {})
        context.commit('setCurrentNetworkShowPermissions', {
          feedUid: get(show, 'feed.uid', null) || get(show, 'ytFeed.uid', null),
          networkShowPermissions: curShowPerms,
        })
      })
    }
    context.commit('setValidNetworkUser', context.state.currentNetwork.role.name.includes('Network'))
    context.commit('setValidNetworkOrShowUser', networks.member_networks.length > 0 || networks.member_shows.length > 0)
  },

  async changeNetwork(context, networkId: string) {
    let index = -1
    const networks = context.getters.profile.networks
    let allNetworks: any
    if (
      networks &&
      networks.member_networks &&
      networks.member_networks.length > index &&
      networks.member_shows.length > 0
    ) {
      let showMemebers: any = []
      networks.member_shows.forEach((member: any) => {
        showMemebers.push({
          network: member.network,
          role: member.feeds[0].role,
        })
      })
      allNetworks = [...networks.member_networks, ...showMemebers]
      allNetworks = [
        ...allNetworks.filter((network: any) => network.role.name === 'Network Owner'),
        ...allNetworks.filter((network: any) => network.role.name !== 'Network Owner'),
      ]
      index = allNetworks.findIndex((networkObj: any) => networkObj.network.id === networkId)
    } else if (networks && networks.member_networks && networks.member_networks.length > 0) {
      allNetworks = [...networks.member_networks]
      index = networks.member_networks.findIndex((networkObj: any) => networkObj.network.id === networkId)
    } else if (networks && networks.member_shows && networks.member_shows.length > 0) {
      let showMemebers: any = []
      networks.member_shows.forEach((member: any) => {
        showMemebers.push({
          network: member.network,
          role: member.feeds[0].role,
        })
      })
      allNetworks = [...showMemebers]
      index = networks.member_shows.findIndex((networkObj: any) => networkObj.network.id === networkId)
    }
    localStorage.setItem('networkId', get(allNetworks[index], 'network.id'))
    await context.dispatch('loadUser', true)
  },

  async getRoles(context) {
    const networkId = context.getters.networkId
    const roles: any = await AdoriService.fetchRoles(networkId)
    context.commit('setRoles', roles || {})
  },

  async getNetworkUsers(context) {
    const networkId = context.getters.networkId
    const networkUsers: any = await AdoriService.fetchNetworkUsers(networkId)
    context.commit('setUsers', networkUsers || {})
  },

  async addNetworkUser(context, { email, role }) {
    const networkId = context.getters.networkId
    const payload = {
      email,
      role,
    }
    await AdoriService.addNetworkUser(networkId, payload).then(
      (response: any) => {
        if (response.status === 'INVITE_SENT') {
          store.dispatch('pushNotification', {
            text: 'Invite sent to user!',
            type: 'SUCCESS',
          })
        } else if (response.status === 'SUCCESS') {
          store.dispatch('pushNotification', {
            text: 'User added to network!',
            type: 'SUCCESS',
          })
        }
      },
      (error: any) => {
        Sentry.captureException(error)
      }
    )
  },

  async addShowUser(context, { email, role, feedUid }) {
    const networkId = context.getters.networkId
    const payload = {
      email,
      role,
    }
    await AdoriService.addShowUser(networkId, feedUid, payload).then(
      (response: any) => {
        if (response.status === 'INVITE_SENT') {
          store.dispatch('pushNotification', {
            text: 'Invite sent to user!',
            type: 'SUCCESS',
          })
        } else if (response.status === 'SUCCESS') {
          store.dispatch('pushNotification', {
            text: 'User added to network!',
            type: 'SUCCESS',
          })
        }
      },
      (error: any) => {
        Sentry.captureException(error)
      }
    )
  },

  async getShowUsers(context, feedUid) {
    const networkId = context.getters.networkId
    const showUsers: any = await AdoriService.fetchShowUsers(networkId, feedUid)
    context.commit('setShowUsers', { feedUid, showUsers: showUsers || {} })
  },

  async addShowYoutubeUser(context, { email, role, feedUid }) {
    const networkId = context.getters.networkId
    const payload = {
      email,
      role,
    }
    await AdoriService.addShowYoutubeUser(networkId, feedUid, payload).then(
      (response: any) => {
        if (response.status === 'INVITE_SENT') {
          store.dispatch('pushNotification', {
            text: 'Invite sent to user!',
            type: 'SUCCESS',
          })
        } else if (response.status === 'SUCCESS') {
          store.dispatch('pushNotification', {
            text: 'User added to network!',
            type: 'SUCCESS',
          })
        }
      },
      (error: any) => {
        Sentry.captureException(error)
      }
    )
  },

  async getShowYoutubeUsers(context, feedUid) {
    const networkId = context.getters.networkId
    const showUsers: any = await AdoriService.fetchShowYoutubeUsers(networkId, feedUid)
    context.commit('setShowUsers', { feedUid, showUsers: showUsers || {} })
  },

  async uploadCurrentNetworkInfo(context, info) {
    const networkId = context.getters.networkId
    const details: any = await AdoriService.updateProfileNetwork(networkId, info)
    context.dispatch('updateProfileNetworkInfo', details)
    context.commit('updateCurrentNetworkInfo', details)
  },

  async getNetworkSubscriptions(context) {
    const networkId = context.getters.networkId
    try {
      const subs: any = await AdoriService.fetchPaymentSubscription(networkId)
      context.commit('updateCurrentNetworkSubscription', standardizeSubscriptionSchema(subs))
    } catch (error) {
      context.commit('updateCurrentNetworkSubscription', null)
    }
  },

  async getCurrentNetworkPaymentCards(context) {
    const networkId = context.getters.networkId
    const cards = (await AdoriService.fetchPaymentCards(networkId)).data
    context.commit('setCurrentNetworkPaymentCards', cards)
  },
}

const networks: StoreOptions<any> = {
  state,
  getters,
  mutations,
  actions,
}

export default networks

// HELPER FUNCTIONS
const standardizeSubscriptionSchema = (subs: any) => {
  if (!subs.subscription) return subs
  const subscription = { ...subs.subscription }
  const trial = subscription.status === SUBSCRIPTION_STATUS.trial ? getTrialInfo(subscription) : null
  const current = subscription.status !== SUBSCRIPTION_STATUS.trial ? getCurrentInfo(subscription) : null
  const studio = { trial, current }
  subscription['studio'] = studio
  return { ...subs, subscription }
}

const getTrialInfo = (subs: any) => {
  const remaining = daysRemaining(subs.trialStart, subs.trialEnd)
  const readableDate = toReadableDate(subs.trialEnd)
  return { ...remaining, readableDate }
}

const getCurrentInfo = (subs: any) => {
  const remaining = daysRemaining(subs.currentPeriodStart, subs.currentPeriodEnd)
  const readableDate = toReadableDate(subs.currentPeriodEnd)
  return { ...remaining, readableDate }
}
