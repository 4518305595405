var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "TheMainPage",
    [
      _c("TheHeader"),
      _c(
        "div",
        { staticClass: "flex flex-wrap" },
        [
          _c("TheSideNavigation"),
          _c(
            "div",
            { staticClass: "limiter scrollbar" },
            [
              _c("TheTitle", [
                _c(
                  "div",
                  {
                    staticClass: "absolute topShift",
                    attrs: { slot: "left" },
                    slot: "left",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "flex items-center pointer dim",
                        on: { click: _vm.goBack },
                      },
                      [
                        _c(
                          "i",
                          {
                            staticClass:
                              "material-icons f3 light-gray mr2 v-btm",
                          },
                          [_vm._v("arrow_back")]
                        ),
                        _c("span", { staticClass: "light-gray fw1" }, [
                          _vm._v("Back"),
                        ]),
                      ]
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "flex",
                    attrs: { slot: "right" },
                    slot: "right",
                  },
                  [
                    _vm.parsedBlogScenes.length
                      ? _c(
                          "div",
                          { staticClass: "flex items-center" },
                          [
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.isUnsavedChanges,
                                    expression: "isUnsavedChanges",
                                  },
                                ],
                                staticClass: "silver mr2",
                              },
                              [
                                _vm._v("unsaved "),
                                _c(
                                  "i",
                                  {
                                    staticClass:
                                      "material-icons light-gray f4 v-mid",
                                  },
                                  [_vm._v("cached")]
                                ),
                              ]
                            ),
                            _c("BaseButtonBorder", {
                              staticClass: "mr2 ba",
                              attrs: {
                                text: "Save",
                                loading: _vm.isUpdateDraftLoading,
                                disabled: _vm.isUpdateDraftLoading,
                                onClick: _vm.saveDraft,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.parsedBlogScenes.length
                      ? _c("BaseButtonRed", {
                          attrs: {
                            text: _vm.btnCreateText,
                            onClick: _vm.selectVoice,
                            disabled: _vm.exportLimitExceeded,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                [
                  _vm.isParseFetching ||
                  _vm.isDraftFetching ||
                  _vm.isSaveDraftLoading
                    ? _c("div", { staticClass: "w-100 flex mt-3" }, [
                        _c("div", {
                          staticClass:
                            "w-30 bb bw1 b--adori-gray bg-adori-light-gray flex justify-center items-center mr3 mb3 br2 skeleton custom-size-category",
                        }),
                        _c("div", {
                          staticClass:
                            "w-70 bb bw1 b--adori-gray bg-adori-light-gray flex justify-center items-center mb3 br2 skeleton custom-size",
                        }),
                      ])
                    : _vm.parsingMethod == "URL" && !_vm.parsedBlogScenes.length
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "flex flex-column mt-3 justify-center items-center silver pa4 tc bg-adori-light-gray ba b--adori-gray br2 br-bottom",
                        },
                        [
                          _c("div", { staticClass: "flex" }, [
                            _c(
                              "i",
                              {
                                staticClass:
                                  "material-icons white f2 v-mid mb1",
                              },
                              [_vm._v(" article ")]
                            ),
                            _c(
                              "i",
                              {
                                staticClass:
                                  "material-icons white f2 v-mid mb1",
                              },
                              [_vm._v(" trending_flat ")]
                            ),
                            _c(
                              "i",
                              {
                                staticClass:
                                  "material-icons white f2 v-mid mb1",
                              },
                              [_vm._v(" smart_display ")]
                            ),
                          ]),
                          _c(
                            "div",
                            { staticClass: "light-gray ph3 pv2 f4 db b-color" },
                            [
                              _vm._v(
                                "Convert your Blog post into an Engaging Video"
                              ),
                            ]
                          ),
                          _c("div", { staticClass: "w-40" }, [
                            _vm._v(
                              "Insert blog link and get AI-generated scenes with relevant images"
                            ),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass:
                                "w-40 flex mt4 items-center v-mid justify-center",
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.blogUrlInp,
                                    expression: "blogUrlInp",
                                  },
                                ],
                                ref: "_input",
                                staticClass:
                                  "w-60 db bw0 bg-adori-gray br2 ph3 pv2 gray f6 white mr2",
                                attrs: {
                                  type: "text",
                                  placeholder: "Paste blog URL",
                                },
                                domProps: { value: _vm.blogUrlInp },
                                on: {
                                  keypress: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.handleBlogParse.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.blogUrlInp = $event.target.value
                                  },
                                },
                              }),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "mb4 w-100 flex mt4 items-center v-mid justify-center",
                            },
                            [
                              _vm.isPremium
                                ? _c("BaseCheckbox", {
                                    ref: "summarize",
                                    staticClass: "mr4 f5 silver",
                                    attrs: {
                                      text: "Summarize",
                                      initialState: _vm.isSummarize,
                                      disabled: false,
                                    },
                                    on: { toggle: _vm.toggleCheckBox },
                                  })
                                : _vm._e(),
                              _vm.isSummarize
                                ? _c("div", { staticClass: "mr2 f6" }, [
                                    _vm._v("Script language:"),
                                  ])
                                : _vm._e(),
                              _vm.isSummarize
                                ? _c(
                                    "BasePopupMenu",
                                    {
                                      ref: "basePopupMenu",
                                      staticClass:
                                        "dropdown flex items-center ba b--adori-gray light-gray bg-dark-gray br2 mr4 pv0",
                                      attrs: {
                                        icon: "arrow_drop_down",
                                        items: _vm.languagesMenu,
                                      },
                                    },
                                    [
                                      _c("div", [
                                        _c("span", { staticClass: "f6" }, [
                                          _vm._v(
                                            _vm._s(_vm.selectedScriptLanguage)
                                          ),
                                        ]),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.isSummarize
                                ? _c("div", { staticClass: "mr2 f6" }, [
                                    _vm._v("Script length:"),
                                  ])
                                : _vm._e(),
                              _vm.isSummarize
                                ? _c(
                                    "BasePopupMenu",
                                    {
                                      ref: "basePopupMenu",
                                      staticClass:
                                        "dropdown flex items-center ba b--adori-gray light-gray bg-dark-gray br2 pv0",
                                      attrs: {
                                        icon: "arrow_drop_down",
                                        items: _vm.scriptLengthMenu,
                                      },
                                    },
                                    [
                                      _c("div", [
                                        _vm.selectedScriptLength.isLocked
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "bn br3 f6 mr2 bg-adori-red",
                                                staticStyle: {
                                                  "border-radius": "7px",
                                                  padding: "2px 6px",
                                                },
                                              },
                                              [_vm._v("Pro")]
                                            )
                                          : _vm._e(),
                                        _c("span", { staticClass: "f6" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.selectedScriptLength.text
                                            )
                                          ),
                                        ]),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c("BaseButtonRed", {
                            staticClass: "pv2",
                            attrs: {
                              text: _vm.selectedScriptLength.isLocked
                                ? "🔒 Create Video"
                                : "Create Video",
                              onClick: _vm.handleBlogParse,
                              loading: _vm.isParseFetching,
                              disabled: !_vm.blogUrlInp || _vm.isParseFetching,
                            },
                          }),
                          _c("div", { staticClass: "divider" }),
                          _c(
                            "div",
                            {
                              staticClass:
                                "light-gray ph3 pv2 f4 db b-color mt4",
                            },
                            [_vm._v("Supported blog sites")]
                          ),
                          _c(
                            "div",
                            { staticClass: "web pt2" },
                            [
                              _vm._l(_vm.blogListData, function (item, i) {
                                return _c(
                                  "span",
                                  {
                                    key: i,
                                    staticClass: "chip",
                                    on: {
                                      click: function ($event) {
                                        return _vm.openBlog(item.value)
                                      },
                                    },
                                  },
                                  [
                                    _c("span", { staticClass: "chip-text" }, [
                                      _vm._v(_vm._s(item.title)),
                                    ]),
                                  ]
                                )
                              }),
                              _c("span", { staticClass: "more" }, [
                                _vm._v("and many more..."),
                              ]),
                            ],
                            2
                          ),
                          _c("div", { staticClass: "light-gray mt4 pt2 f4" }, [
                            _vm._v("Is your blog not supported?"),
                          ]),
                          _c("BaseButtonRed", {
                            staticClass: "pv1 mt2",
                            attrs: {
                              text: "Contact Us",
                              onClick: _vm.openChat,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm.parsingMethod == "FILE" &&
                      !_vm.parsedBlogScenes.length
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "flex flex-column mt-3 justify-center items-center silver pa4 tc bg-adori-light-gray ba b--adori-gray br2 br-bottom",
                        },
                        [
                          _c("div", { staticClass: "flex" }, [
                            _c(
                              "i",
                              {
                                staticClass:
                                  "material-icons white f2 v-mid mb1",
                              },
                              [_vm._v(" text_snippet ")]
                            ),
                            _c(
                              "i",
                              {
                                staticClass:
                                  "material-icons white f2 v-mid mb1",
                              },
                              [_vm._v(" trending_flat ")]
                            ),
                            _c(
                              "i",
                              {
                                staticClass:
                                  "material-icons white f2 v-mid mb1",
                              },
                              [_vm._v(" smart_display ")]
                            ),
                          ]),
                          _c(
                            "div",
                            { staticClass: "light-gray ph3 pv2 f4 db b-color" },
                            [_vm._v("Convert pdf into an Engaging Video")]
                          ),
                          _c("div", { staticClass: "w-40" }, [
                            _vm._v(
                              "Upload pdf or text file and get AI-generated scenes with relevant images"
                            ),
                          ]),
                          _c(
                            "div",
                            { staticClass: "mt4 mb5" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "w-100 br2 flex flex-column items-center justify-center b--light-gray b--dashed pv5 ph4 pointer",
                                  class:
                                    _vm.isUploadingAudio || _vm.dropped
                                      ? "not-allowed o-20"
                                      : _vm.bgDragColor
                                      ? "dark-fill"
                                      : "link",
                                  on: {
                                    dragover: [
                                      function ($event) {
                                        $event.preventDefault()
                                      },
                                      function ($event) {
                                        _vm.bgDragColor = true
                                      },
                                    ],
                                    dragleave: function ($event) {
                                      _vm.bgDragColor = false
                                    },
                                    drop: function ($event) {
                                      $event.preventDefault()
                                      return _vm.dropFile($event)
                                    },
                                    click: _vm.handleUploadFile,
                                  },
                                },
                                [
                                  _c(
                                    "i",
                                    {
                                      staticClass:
                                        "material-icons f1 silver mb2",
                                    },
                                    [_vm._v("upload_file")]
                                  ),
                                  _c("div", { staticClass: "f4 silver" }, [
                                    _vm._v(
                                      "Click to select .pdf, .txt file or drag it here"
                                    ),
                                  ]),
                                  _vm.selectedTextFile
                                    ? _c("span", { staticClass: "mt2 gray" }, [
                                        _vm._v(_vm._s(_vm.selectedFileName)),
                                      ])
                                    : _vm._e(),
                                  _vm.selectedTextFile
                                    ? _c("span", { staticClass: "f6 gray" }, [
                                        _vm._v(
                                          "(" +
                                            _vm._s(_vm.selectedFileSize) +
                                            ")"
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "mb4 w-100 white flex mt4 items-center v-mid justify-center",
                                },
                                [
                                  _vm.isPremium
                                    ? _c("BaseCheckbox", {
                                        ref: "summarize",
                                        staticClass: "mr4 f5 silver",
                                        attrs: {
                                          text: "Summarize",
                                          initialState: _vm.isSummarize,
                                          disabled: false,
                                        },
                                        on: { toggle: _vm.toggleCheckBox },
                                      })
                                    : _vm._e(),
                                  _vm.isSummarize
                                    ? _c("div", { staticClass: "mr2 f6" }, [
                                        _vm._v("Script language:"),
                                      ])
                                    : _vm._e(),
                                  _vm.isSummarize
                                    ? _c(
                                        "BasePopupMenu",
                                        {
                                          ref: "basePopupMenu",
                                          staticClass:
                                            "dropdown flex items-center ba b--adori-gray light-gray bg-dark-gray br2 mr4 pv0",
                                          attrs: {
                                            icon: "arrow_drop_down",
                                            items: _vm.languagesMenu,
                                          },
                                        },
                                        [
                                          _c("div", [
                                            _c("span", { staticClass: "f6" }, [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.selectedScriptLanguage
                                                )
                                              ),
                                            ]),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.isSummarize
                                    ? _c("div", { staticClass: "mr2 f6" }, [
                                        _vm._v("Script length:"),
                                      ])
                                    : _vm._e(),
                                  _vm.isSummarize
                                    ? _c(
                                        "BasePopupMenu",
                                        {
                                          ref: "basePopupMenu",
                                          staticClass:
                                            "dropdown flex items-center ba b--adori-gray light-gray bg-dark-gray br2 pv0",
                                          attrs: {
                                            icon: "arrow_drop_down",
                                            items: _vm.scriptLengthMenu,
                                          },
                                        },
                                        [
                                          _c("div", [
                                            _vm.selectedScriptLength.isLocked
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "bn br3 f6 mr2 bg-adori-red",
                                                    staticStyle: {
                                                      "border-radius": "7px",
                                                      padding: "2px 6px",
                                                    },
                                                  },
                                                  [_vm._v("Pro")]
                                                )
                                              : _vm._e(),
                                            _c("span", { staticClass: "f6" }, [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.selectedScriptLength.text
                                                )
                                              ),
                                            ]),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c("BaseButtonRed", {
                                staticClass: "scn-btn ph4",
                                attrs: {
                                  text: _vm.isParseFetching
                                    ? "Converting"
                                    : "Convert",
                                  onClick: _vm.handleFileParse,
                                  loading: _vm.isParseFetching,
                                  disabled:
                                    !_vm.selectedTextFile ||
                                    _vm.isParseFetching,
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm.parsingMethod == "IDEA" &&
                      !_vm.parsedBlogScenes.length
                    ? _c("div", { staticClass: "mt-3" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex flex-column mt-3 justify-center items-center silver pa4 tc bg-adori-light-gray ba b--adori-gray br2 br-bottom",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "w-50" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "light-gray ph3 pv2 f4 db b-color",
                                  },
                                  [
                                    _c(
                                      "i",
                                      {
                                        staticClass:
                                          "material-icons light-gray mr2 v-btm",
                                      },
                                      [_vm._v(" tips_and_updates ")]
                                    ),
                                    _vm._v("Idea to Video\n              "),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "pb gray f6 flex items-center",
                                  },
                                  [_vm._v("Topic (required)")]
                                ),
                                _c("div", { staticClass: "relative flex" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.topic,
                                        expression: "topic",
                                      },
                                    ],
                                    ref: "_input",
                                    staticClass:
                                      "db bg-adori-gray b--gray ba br2 ph3 pv3 gray f6 white w-100 mt1",
                                    attrs: {
                                      type: "text",
                                      placeholder: "Type your topic here...",
                                    },
                                    domProps: { value: _vm.topic },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.topic = $event.target.value
                                      },
                                    },
                                  }),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "drop-div dim",
                                      on: { click: _vm.openTopicRecommend },
                                    },
                                    [
                                      _c(
                                        "i",
                                        {
                                          staticClass:
                                            "material-icons v-mid white text-shadow",
                                        },
                                        [_vm._v(" arrow_drop_down ")]
                                      ),
                                    ]
                                  ),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "pb gray f6 flex items-center mt3",
                                  },
                                  [
                                    _vm._v(
                                      "\n                Description (optional)\n                "
                                    ),
                                    _c("BaseTooltip", {
                                      attrs: {
                                        info: "The more information you give about outline of video the better the script will be generated",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.ideaDescription,
                                      expression: "ideaDescription",
                                    },
                                  ],
                                  staticClass:
                                    "db bg-adori-gray b--gray br2 ph3 pv3 gray f6 white w-100 scrollbar mb2 mt1",
                                  attrs: {
                                    rows: "5",
                                    type: "text",
                                    placeholder:
                                      "Add relevant information and outline of video",
                                  },
                                  domProps: { value: _vm.ideaDescription },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.ideaDescription = $event.target.value
                                    },
                                  },
                                }),
                                _c(
                                  "div",
                                  { staticClass: "flex justify-between" },
                                  [
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "pb gray f6 flex items-center mt3",
                                          },
                                          [_vm._v("Length")]
                                        ),
                                        _vm.isSummarize
                                          ? _c(
                                              "BasePopupMenu",
                                              {
                                                ref: "basePopupMenu",
                                                staticClass:
                                                  "dropdown flex items-center ba b--gray light-gray bg-adori-gray br2 pv1 mt1",
                                                attrs: {
                                                  icon: "arrow_drop_down",
                                                  items: _vm.scriptLengthMenu,
                                                },
                                              },
                                              [
                                                _c("div", [
                                                  _vm.selectedScriptLength
                                                    .isLocked
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "bn br3 f6 mr2 bg-adori-red",
                                                          staticStyle: {
                                                            "border-radius":
                                                              "7px",
                                                            padding: "2px 6px",
                                                          },
                                                        },
                                                        [_vm._v("Pro")]
                                                      )
                                                    : _vm._e(),
                                                  _c(
                                                    "span",
                                                    { staticClass: "f6" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm
                                                            .selectedScriptLength
                                                            .text
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "pb gray f6 flex items-center mt3",
                                          },
                                          [_vm._v("Language")]
                                        ),
                                        _vm.isSummarize
                                          ? _c(
                                              "BasePopupMenu",
                                              {
                                                ref: "basePopupMenu",
                                                staticClass:
                                                  "dropdown flex items-center ba b--gray light-gray bg-adori-gray br2 mr4 pv1 mt1",
                                                attrs: {
                                                  icon: "arrow_drop_down",
                                                  items: _vm.languagesMenu,
                                                },
                                              },
                                              [
                                                _c("div", [
                                                  _c(
                                                    "span",
                                                    { staticClass: "f6" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.selectedScriptLanguage
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _c("div", [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "pb gray f6 flex items-center mt3",
                                        },
                                        [_vm._v("Tone")]
                                      ),
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.voiceTone,
                                              expression: "voiceTone",
                                            },
                                          ],
                                          staticClass:
                                            "ph2 bg-adori-gray white f6 w-100 br2 h2 ba b--gray mt1",
                                          on: {
                                            change: [
                                              function ($event) {
                                                var $$selectedVal =
                                                  Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function (o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function (o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                _vm.voiceTone = $event.target
                                                  .multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              },
                                              function ($event) {},
                                            ],
                                          },
                                        },
                                        _vm._l(
                                          _vm.voiceToneListData,
                                          function (item, i) {
                                            return _c(
                                              "option",
                                              {
                                                key: i,
                                                domProps: { value: item.title },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(item.title) +
                                                    "\n                    "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "flex justify-between" },
                                  [
                                    _c("div", [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "pb gray f6 flex items-center mt3",
                                        },
                                        [_vm._v("Auto-pick assets settings")]
                                      ),
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.selectedAutoPickSetting,
                                              expression:
                                                "selectedAutoPickSetting",
                                            },
                                          ],
                                          staticClass:
                                            "ph2 bg-adori-gray white f6 w-100 br2 h2 ba b--gray mt1",
                                          on: {
                                            change: [
                                              function ($event) {
                                                var $$selectedVal =
                                                  Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function (o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function (o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                _vm.selectedAutoPickSetting =
                                                  $event.target.multiple
                                                    ? $$selectedVal
                                                    : $$selectedVal[0]
                                              },
                                              function ($event) {},
                                            ],
                                          },
                                        },
                                        _vm._l(
                                          _vm.autoPickData,
                                          function (item, i) {
                                            return _c(
                                              "option",
                                              {
                                                key: i,
                                                domProps: { value: item.value },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(item.title) +
                                                    "\n                    "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]),
                                    _c("div", [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "pb gray f6 flex items-center mt3",
                                        },
                                        [_vm._v("Orientation")]
                                      ),
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.selectedOrientation,
                                              expression: "selectedOrientation",
                                            },
                                          ],
                                          staticClass:
                                            "ph2 bg-adori-gray white f6 w-100 br2 h2 ba b--gray mt1",
                                          on: {
                                            change: [
                                              function ($event) {
                                                var $$selectedVal =
                                                  Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function (o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function (o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                _vm.selectedOrientation = $event
                                                  .target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              },
                                              function ($event) {},
                                            ],
                                          },
                                        },
                                        _vm._l(
                                          _vm.videoResolutionData,
                                          function (item, i) {
                                            return _c(
                                              "option",
                                              {
                                                key: i,
                                                domProps: { value: item.value },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(item.title) +
                                                    "\n                    "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]),
                                  ]
                                ),
                                _c("BaseButtonRed", {
                                  staticClass: "gen-btn mt3",
                                  attrs: {
                                    text: _vm.btnGenerateText,
                                    icon: "auto_fix_high",
                                    onClick: _vm.sendQuery,
                                    loading: _vm.isOpenAiFetching,
                                    disabled:
                                      _vm.isOpenAiFetching ||
                                      !_vm.topic.length ||
                                      _vm.selectedScriptLength.isLocked,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ])
                    : !_vm.isParseFetching && !_vm.parsedBlogScenes.length
                    ? _c("div", [
                        _c(
                          "div",
                          { staticClass: "mt4 relative" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "absolute",
                                staticStyle: { top: "-33px", right: "150px" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "pb2 w-100 white flex mt1-5 items-center v-mid justify-end",
                                    staticStyle: {
                                      position: "sticky",
                                      "z-index": "1",
                                      top: "55px",
                                    },
                                  },
                                  [
                                    _vm.isPremium
                                      ? _c("BaseCheckbox", {
                                          ref: "summarize",
                                          staticClass: "mr4 f5 silver",
                                          attrs: {
                                            text: "Summarize",
                                            initialState: _vm.isSummarize,
                                            disabled: false,
                                          },
                                          on: { toggle: _vm.toggleCheckBox },
                                        })
                                      : _vm._e(),
                                    _vm.isSummarize
                                      ? _c("div", { staticClass: "mr2 f6" }, [
                                          _vm._v("Script language:"),
                                        ])
                                      : _vm._e(),
                                    _vm.isSummarize
                                      ? _c(
                                          "BasePopupMenu",
                                          {
                                            ref: "basePopupMenu",
                                            staticClass:
                                              "dropdown flex items-center ba b--adori-gray light-gray bg-dark-gray br2 mr4 pv0",
                                            attrs: {
                                              icon: "arrow_drop_down",
                                              items: _vm.languagesMenu,
                                            },
                                          },
                                          [
                                            _c("div", [
                                              _c(
                                                "span",
                                                { staticClass: "f6" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.selectedScriptLanguage
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.isSummarize
                                      ? _c("div", { staticClass: "mr2 f6" }, [
                                          _vm._v("Script length:"),
                                        ])
                                      : _vm._e(),
                                    _vm.isSummarize
                                      ? _c(
                                          "BasePopupMenu",
                                          {
                                            ref: "basePopupMenu",
                                            staticClass:
                                              "dropdown flex items-center ba b--adori-gray light-gray bg-dark-gray br2 pv0",
                                            attrs: {
                                              icon: "arrow_drop_down",
                                              items: _vm.scriptLengthMenu,
                                            },
                                          },
                                          [
                                            _c("div", [
                                              _vm.selectedScriptLength.isLocked
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "bn br3 f6 mr2 bg-adori-red",
                                                      staticStyle: {
                                                        "border-radius": "7px",
                                                        padding: "2px 6px",
                                                      },
                                                    },
                                                    [_vm._v("Pro")]
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "span",
                                                { staticClass: "f6" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.selectedScriptLength
                                                        .text
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c("TextEditor", {
                              on: { "parse-text": _vm.handleTextParse },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm.parsedBlogScenes.length
                    ? _c("div", { staticClass: "mt2" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex mt1 justify-between items-center silver pa3 tc bg-adori-light-gray ba b--adori-gray br2 br-bottom relative",
                          },
                          [
                            _c("div"),
                            _c(
                              "div",
                              {
                                staticClass: "absolute f7 pointer o-50",
                                staticStyle: { left: "1rem" },
                                on: { click: _vm.generateReport },
                              },
                              [_vm._v("Send Report")]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "absolute pointer flex justify-center items-center",
                                staticStyle: { left: "6rem" },
                                on: { click: _vm.undo },
                              },
                              [
                                _c(
                                  "i",
                                  {
                                    staticClass: "material-icons f3 pointer",
                                    class:
                                      _vm.blogHistory.length &&
                                      _vm.currentHistoryIndex > 1 &&
                                      "white",
                                  },
                                  [_vm._v("undo")]
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "absolute pointer flex justify-center items-center",
                                staticStyle: { left: "8rem" },
                                on: { click: _vm.redo },
                              },
                              [
                                _c(
                                  "i",
                                  {
                                    staticClass: "material-icons f3 pointer",
                                    class:
                                      _vm.blogHistory.length &&
                                      _vm.currentHistoryIndex <
                                        _vm.blogHistory.length - 1 &&
                                      "white",
                                  },
                                  [_vm._v("redo")]
                                ),
                              ]
                            ),
                            _c("div", { staticClass: "flex flex-column" }, [
                              _c("p", { staticClass: "ml5 pl5" }, [
                                _vm._v(
                                  "Estimated Video Duration: " +
                                    _vm._s(_vm.formattedDuration)
                                ),
                              ]),
                              _vm.exportLimitExceeded
                                ? _c(
                                    "span",
                                    { staticClass: "red pb1 ml5 pl5" },
                                    [
                                      _vm._v(
                                        "You have exceeded the maximum video duration limit of " +
                                          _vm._s(_vm.exportLimit) +
                                          " minutes"
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.exportLimitExceeded
                                ? _c("span", { staticClass: "red ml5 pl5" }, [
                                    _vm._v(
                                      "\n                ✅\n                "
                                    ),
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "mr-auto white underline pointer",
                                        on: { click: _vm.openPlansModal },
                                      },
                                      [_vm._v("Upgrade")]
                                    ),
                                    _vm._v(
                                      "\n                to paid plan or delete some text"
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                            !_vm.isParseFetching
                              ? _c("BaseButtonRed", {
                                  staticClass: "auto-pick",
                                  attrs: {
                                    id: "blog_scenes_tour_step_9",
                                    text: "Auto-pick Assets",
                                    icon: "auto_awesome",
                                    onClick: _vm.handleAutomation,
                                    loading: _vm.isSceneImgFetching,
                                    disabled:
                                      _vm.isSceneImgFetching ||
                                      _vm.exportLimitExceeded,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "flex mt2" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "w-30 mr1 bg-adori-light-gray white pa3 mr3 relative",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "absolute right-0" },
                                [
                                  _c(
                                    "BaseNewTooltip",
                                    {
                                      attrs: {
                                        text: "Show tour of the product",
                                      },
                                    },
                                    [
                                      _c(
                                        "i",
                                        {
                                          staticClass:
                                            "mt3 material-icons f3 mr2 v-btm pointer",
                                          on: {
                                            click: function ($event) {
                                              return _vm.showTour(true)
                                            },
                                          },
                                        },
                                        [_vm._v("info")]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c("h3", [_vm._v("Video Setting")]),
                              _c(
                                "div",
                                { staticClass: "flex items-center mt3 mb2" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "pt1 white f6 flex items-center",
                                    },
                                    [_vm._v("Thumbnail")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex justify-end items-center mh2 btn-ai pointer",
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "pointer ai-icon",
                                        attrs: {
                                          src: require("@/assets/ai.svg"),
                                        },
                                        on: { click: _vm.toggleThumbnailPanel },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "pointer w-100 flex justify-center",
                                },
                                [
                                  _c("img", {
                                    staticStyle: {
                                      width: "100%",
                                      height: "10rem",
                                      "object-fit": "contain",
                                    },
                                    attrs: { src: _vm.thumbnailImage, alt: "" },
                                    on: { click: _vm.toggleThumbnailPanel },
                                  }),
                                ]
                              ),
                              _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "flex items-center mt3 mb2" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "pt1 white f6 flex items-center",
                                      },
                                      [_vm._v("Title")]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flex justify-end items-center mh2 btn-ai pointer",
                                        attrs: {
                                          id: "blog_scenes_tour_step_1",
                                        },
                                      },
                                      [
                                        _c("img", {
                                          staticClass: "pointer ai-icon",
                                          attrs: {
                                            src: require("@/assets/ai.svg"),
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.openImagePanel(
                                                "AI_TITLE"
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                                _c("input", {
                                  staticClass:
                                    "db bn bg-adori-gray b--gray br2 ph3 pv3 gray f6 white w-100 scrollbar mb2 mt1",
                                  attrs: {
                                    rows: "3",
                                    type: "text",
                                    placeholder: "enter the title",
                                  },
                                  domProps: { value: _vm.title },
                                  on: {
                                    input: function ($event) {
                                      return _vm.updateTitle(
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                              _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "flex items-center mt3 mb2" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "pt1 white f6 flex items-center",
                                      },
                                      [_vm._v("Description")]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flex justify-end items-center mh2 btn-ai pointer",
                                        attrs: {
                                          id: "blog_scenes_tour_step_2",
                                        },
                                      },
                                      [
                                        _c("img", {
                                          staticClass: "pointer ai-icon",
                                          attrs: {
                                            src: require("@/assets/ai.svg"),
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.openImagePanel(
                                                "AI_DESCRIPTION"
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                                _c("textarea", {
                                  staticClass:
                                    "db bn bg-adori-gray b--gray br2 ph3 pv3 gray f6 white w-100 scrollbar mb2 mt1",
                                  attrs: {
                                    rows: "3",
                                    type: "text",
                                    placeholder: "",
                                  },
                                  domProps: { value: _vm.description },
                                  on: {
                                    input: function ($event) {
                                      return _vm.updateDescription(
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                              _c(
                                "div",
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "flex items-center mt3 mb2",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "pt1 white f6 flex items-center",
                                        },
                                        [_vm._v("Keywords")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex justify-end items-center mh2 btn-ai pointer",
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "pointer ai-icon",
                                            attrs: {
                                              src: require("@/assets/ai.svg"),
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.openImagePanel(
                                                  "AI_KEYWORDS"
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c("input", {
                                    staticClass:
                                      "db bn bg-adori-gray b--gray br2 ph3 pv3 gray f6 white w-100 scrollbar mb2 mt1",
                                    attrs: {
                                      rows: "3",
                                      type: "text",
                                      placeholder:
                                        "enter keywords separated by comma",
                                    },
                                    domProps: { value: _vm.keywords },
                                    on: {
                                      input: function ($event) {
                                        return _vm.updateKeywords(
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _vm.blogUrlInp == "https://www.example.com" &&
                                  _vm.btnClicked
                                    ? _c("BaseButtonRed", {
                                        staticClass: "btn2 mt2",
                                        attrs: {
                                          text: "Example Video Preview",
                                          icon: "visibility",
                                          onClick: function () {
                                            _vm.showSampleBlogPreview =
                                              !_vm.showSampleBlogPreview
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "w-100" },
                            [
                              _vm._l(_vm.blogSceneList, function (scene, key) {
                                return _c("SceneBlock", {
                                  key: key,
                                  staticClass: "scene-block",
                                  attrs: {
                                    scenes: _vm.blogSceneList,
                                    scene: scene,
                                    text: scene.text,
                                    index: key,
                                  },
                                  on: {
                                    updateScene: _vm.updateScene,
                                    addScene: _vm.addScene,
                                    addBwScene: _vm.addBwScene,
                                    moveScene: _vm.moveScene,
                                    removeScene: _vm.removeScene,
                                    removeSceneImage: _vm.removeSceneImage,
                                    aiPanel: _vm.toggleAIPanel,
                                    blogImages: _vm.toggleBlogImages,
                                    viewImages: _vm.viewImages,
                                    viewVideos: _vm.viewVideos,
                                  },
                                })
                              }),
                              _c(
                                "button",
                                {
                                  staticClass: "btn-add-scene mt2",
                                  on: { click: _vm.addScene },
                                },
                                [
                                  _c("i", { staticClass: "material-icons" }, [
                                    _vm._v("add"),
                                  ]),
                                  _vm._v(
                                    "\n                Add Scene\n              "
                                  ),
                                ]
                              ),
                            ],
                            2
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.isModalLoader
                    ? _c("ModalLoader", {
                        attrs: {
                          isLoaderActive: _vm.isConverting,
                          loaderText: _vm.btnCreateText,
                          totalTagsCount: _vm.totalTags.length,
                          tagCount: _vm.currentTags.length,
                        },
                        on: {
                          closeModal: function ($event) {
                            _vm.isModalLoader = !_vm.isModalLoader
                          },
                          "voice-details": _vm.handleVoiceDetails,
                        },
                      })
                    : _vm._e(),
                  _vm.isSceneImgFetching
                    ? _c("ModalCommonLoader", {
                        attrs: { loaderText: "Auto picking assets" },
                      })
                    : _vm._e(),
                  _vm.isModalDemoLoader
                    ? _c("ModalDemoLoader", {
                        on: {
                          closeModal: function ($event) {
                            _vm.isModalDemoLoader = !_vm.isModalDemoLoader
                          },
                        },
                      })
                    : _vm._e(),
                  _c("AIPanel", {
                    ref: "_searchAndSelectTray",
                    attrs: {
                      data: _vm.aiData,
                      onSelectItem: _vm.selectItem,
                      context: _vm.selectedContext,
                      scene: _vm.selectedScene,
                    },
                    on: { viewImages: _vm.viewImages },
                  }),
                  _vm.showRateLimitPopup
                    ? _c("BaseRateLimitPopup", {
                        attrs: { secondsLeft: _vm.rateLimitSecondsLeft },
                        on: {
                          closeModal: function ($event) {
                            _vm.showRateLimitPopup = false
                          },
                        },
                      })
                    : _vm._e(),
                  _vm.showSampleBlogPreview
                    ? _c("BaseSampleBlogPreview", {
                        on: {
                          closeModal: function ($event) {
                            _vm.showSampleBlogPreview = false
                          },
                        },
                      })
                    : _vm._e(),
                  _vm.showImagePreview
                    ? _c("ModalPreviewImages", {
                        attrs: { image: _vm.image },
                        on: { close: _vm.previewClose },
                      })
                    : _vm._e(),
                  _vm.showVideoPreview
                    ? _c("ModalPreviewVideos", {
                        attrs: { video: _vm.video },
                        on: {
                          close: function ($event) {
                            _vm.showVideoPreview = false
                          },
                        },
                      })
                    : _vm._e(),
                  _vm.showContentModal
                    ? _c("ModalContent", {
                        attrs: {
                          context: _vm.selectedContext,
                          data: _vm.aiData,
                          scene: _vm.selectedScene,
                          isSowVideo: _vm.isSowVideo,
                        },
                        on: {
                          closeModal: function ($event) {
                            _vm.showContentModal = false
                          },
                          "load-image": _vm.handleContentLoad,
                          "load-video": _vm.handleContentVideoLoad,
                        },
                      })
                    : _vm._e(),
                  _vm.showAutomationTypeModal
                    ? _c("ModalAutomationType", {
                        on: {
                          closeModal: function ($event) {
                            _vm.showAutomationTypeModal = false
                          },
                          automate: _vm.automateAITags,
                        },
                      })
                    : _vm._e(),
                  _vm.showMusicModal
                    ? _c("ModalMusic", {
                        on: {
                          closeModal: function ($event) {
                            _vm.showMusicModal = false
                          },
                          selectMusic: _vm.handleSelectMusic,
                        },
                      })
                    : _vm._e(),
                  _vm.showPlans
                    ? _c("ModalPlans", {
                        attrs: { customModal: "" },
                        on: {
                          closeModal: function ($event) {
                            _vm.showPlans = false
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }