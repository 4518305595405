import { render, staticRenderFns } from "./PlansV2.vue?vue&type=template&id=5604e6dd&scoped=true&"
import script from "./PlansV2.vue?vue&type=script&lang=ts&"
export * from "./PlansV2.vue?vue&type=script&lang=ts&"
import style0 from "./PlansV2.vue?vue&type=style&index=0&id=5604e6dd&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5604e6dd",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5604e6dd')) {
      api.createRecord('5604e6dd', component.options)
    } else {
      api.reload('5604e6dd', component.options)
    }
    module.hot.accept("./PlansV2.vue?vue&type=template&id=5604e6dd&scoped=true&", function () {
      api.rerender('5604e6dd', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Payment/PlansV2.vue"
export default component.exports