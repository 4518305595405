



































import { Component, Prop, Mixins } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import { formatTimeAgo } from '@/utils/time'
import { audioQueryKeys } from '@/hooks/audio'
import { QueryClient, useQueryClient } from 'vue-query'
import { computed } from '@vue/composition-api'

import get from 'lodash.get'
import Billing from '@/mixins/Billing'
import mixpanel from 'mixpanel-browser'

// @ts-ignore
const webengage = window['webengage']

@Component({
  components: {},
  setup(props: any) {
    const audioTrackId: any = computed(() => props.audioTrack.uid)
    const queryClient = useQueryClient()

    return {
      queryClient,
    }
  },
})
export default class NewMyVideos extends Mixins(Billing) {
  @Prop() audioTrack!: any
  @Prop(Boolean) selectable!: boolean
  @Prop(String) selectionType!: string // One of 'SINGLE' or 'MULTI'
  @Prop(Boolean) editable!: boolean
  @Prop(Boolean) showEditButtons!: boolean
  @Prop(String) sourceComponent!: string
  @Prop() podcast!: any

  @Getter publicFeedEpisodes!: any
  @Getter isWhitelisted!: boolean

  @Action closeModal!: any
  @Action deleteYoutubeAudio!: any
  @Action pushNotification!: any
  @Action getPublicFeedEpisodes!: any
  @Action toggleCreditCardAlert!: any

  queryClient!: QueryClient

  mounted() {
    this.$store.commit('addAudio', this.audioTrack)
    sessionStorage.getItem(this.audioTrack.uid) === this.audioTrack.uid &&
      this.queryClient.invalidateQueries(audioQueryKeys.TRACKVIDEOCLIPS)
    sessionStorage.removeItem(this.audioTrack.uid)
  }

  get trackMenu() {
    const menu: any = []
    this.$permissions.isDeleteEpisodeShowAllowed(get(this.podcast, 'uid', '')) &&
      menu.push({
        name: 'Delete Video',
        icon: 'delete',
        onClick: () => this.deleteVideo(),
      })
    this.isWhitelisted &&
      menu.push({
        name: 'Legacy Create Video',
        icon: 'smart_display',
        onClick: () => this.goToOnboardingOld(),
      })

    return menu
  }

  get audioPreviewImage() {
    return this.audioTrack && this.audioTrack.imageInfo ? this.audioTrack.imageInfo.thumbnailURL : null
  }

  get audioDate() {
    return formatTimeAgo(this.audioTrack.createdOn)
  }

  get selectionIcon() {
    return this.$store.getters.isAudioSelected(this.audioTrack.uid) ? 'check_circle' : 'radio_button_unchecked'
  }

  get selectionStyle() {
    return this.$store.getters.isAudioSelected(this.audioTrack.uid) ? 'adori-red' : 'o-20'
  }

  async deleteVideo() {
    this.$store.dispatch('showConfirm', {
      title: 'Delete video?',
      description: 'Are you sure you want to delete the video?',
      yesButtonText: 'Yes',
      onConfirm: async () => {
        try {
          this.closeModal()
          await this.deleteYoutubeAudio({
            audioUid: this.audioTrack.uid,
            ytFeedUid: this.audioTrack.ytFeed && this.audioTrack.ytFeed.uid,
          })
          this.pushNotification({
            text: 'Video deleted successfully',
            type: 'SUCCESS',
          })
          this.sourceComponent === 'ALLAUDIO-TAB' && this.queryClient.invalidateQueries(audioQueryKeys.ALLTRACKS)
          if (this.sourceComponent === 'PODCASTS-TAB') {
            this.queryClient.invalidateQueries([audioQueryKeys.YTPUBLISHED, `${this.podcast.uid}`])
            this.queryClient.invalidateQueries([audioQueryKeys.YTUNPUBLISHED, `${this.podcast.uid}`])
          }
        } catch (err) {}
        this.closeModal()
      },
    })
  }

  // Todo: When implementing for Studio continue here
  handleAudioSelectionIconClicked() {}

  async goToOnboarding() {
    mixpanel.track('New Video')

    webengage?.track('new-video', { action: 'New Video copy will be created' })
    this.$gtag.event('new-video', { action: 'New Video copy will be created' })
    if (this.showPopup) {
      this.toggleCreditCardAlert(true)
      return 0
    }

    if (this.audioTrack) {
      this.$router.push(`/preview/${this.audioTrack.uid}`)
      return
    }
  }

  async goToOnboardingOld() {
    mixpanel.track('New Video')

    webengage?.track('new-video', { action: 'New Video copy will be created' })
    this.$gtag.event('new-video', { action: 'New Video copy will be created' })
    if (this.showPopup) {
      this.toggleCreditCardAlert(true)
      return 0
    }

    if (this.audioTrack && this.audioTrack.ytFeed === null) {
      const selectedEpisode: any = {
        audioUrl: this.audioTrack.audioUrl,
        name: this.audioTrack.name,
        description: this.audioTrack.description,
        explicitContent: this.audioTrack.explicitContent,
        guid: this.audioTrack.guid,
        imageId: this.audioTrack.imageId,
        imageUrl: this.audioTrack.imageUrl,
        isRss: false,
        keywords: this.audioTrack.keywords,
        durationSeconds: this.audioTrack.durationMillis / 1000,
        isReUpload: true,
        videoUrl: this.audioTrack.videoUrl,
      }

      this.$store.commit('unselectAllEpisodeForUpload')
      this.$store.commit('clearYoutubeState')
      this.$store.commit('resetYoutubeSetting')
      this.$store.commit('selectEpisodeForUpload', selectedEpisode)
      this.$store.commit('setYoutubeStep', 2)
      sessionStorage.setItem('upload', 'YES')
      sessionStorage.setItem('onboardingTrack', JSON.stringify(selectedEpisode))
      this.$router.push('/onboarding')
    } else {
      this.$store.commit('setCreatedYoutubeFeed', this.audioTrack.ytFeed)
      await this.getPublicFeedEpisodes({
        url: this.podcast.rss,
      })
      sessionStorage.setItem('feedUrl', this.podcast.rss)
      const selectedEpisode = this.publicFeedEpisodes.filter((obj: any) => obj.guid === this.audioTrack.guid)[0]

      if (!selectedEpisode) {
        this.pushNotification({
          text: 'Please try again later',
          type: 'WARNING',
        })
        return
      }
      selectedEpisode['isRss'] = true
      this.$store.commit('unselectAllEpisodeForUpload')
      this.$store.commit('clearYoutubeState')
      this.$store.commit('resetYoutubeSetting')
      this.$store.commit('selectEpisodeForUpload', selectedEpisode)
      this.$store.commit('setYoutubeStep', 2)
      sessionStorage.setItem('upload', 'YES')

      sessionStorage.removeItem('onboardingTrack')
      sessionStorage.removeItem('ytModalSetting')
      this.$router.push('/onboarding')
    }
  }
}
