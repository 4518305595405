


























































































































































import { Component, Vue } from 'vue-property-decorator'
import get from 'lodash.get'
import { SetupContext, computed, reactive, ref } from '@vue/composition-api'
import { genreEnum, moodEnum, musicQueryKeys, useGetLibMusic, useGetSearchMusic } from '@/hooks/music'
import AdoriServiceV6 from '@/services/adori_v6'
import { Getter } from 'vuex-class'
import { QueryClient, useQueryClient } from 'vue-query'
import MusicCard from '@/components/Audio/MusicCard.vue'
import { sliderOPtions } from '../Subtitle/constants'
import VueSlider from 'vue-slider-component'

@Component({
  components: {
    MusicCard,
    VueSlider,
  },
  setup(props, context: SetupContext) {
    const queryClient = useQueryClient()
    const currentContentTab = ref('STOCK')
    const genre = ref('')
    const mood = ref('')
    const showFilters = ref(false)
    const libMusicParams = reactive({
      limit: 10,
      offset: 0,
    })
    const isLibMusicActive = computed(() => currentContentTab.value === 'LIB')
    const { data: libMusicData, isFetching: isLibMusicLoading } = useGetLibMusic(libMusicParams, {
      enabled: isLibMusicActive,
    })

    const musicSearchParams = reactive({
      limit: 10,
      offset: 0,
      genre,
      mood,
    })
    const isMusicSearchActive = computed(() => currentContentTab.value === 'STOCK')
    const { data: stockMusicData, isFetching: isStockMusicLoading } = useGetSearchMusic(musicSearchParams, {
      enabled: isMusicSearchActive,
    })

    return {
      queryClient,
      currentContentTab,
      genre,
      mood,
      libMusicData,
      isLibMusicLoading,
      libMusicParams,
      musicSearchParams,
      stockMusicData,
      isStockMusicLoading,
      showFilters,
    }
  },
})
export default class ModalMusic extends Vue {
  @Getter networkId!: string

  currentContentTab!: string
  genre!: string
  genreList = [
    { name: 'Alternative & Punk', value: 'ALTERNATIVE' },
    { name: 'Ambient', value: 'AMBIENT' },
    { name: "Children's", value: 'CHILDREN' },
    { name: 'Cinematic', value: 'CINEMATIC' },
    { name: 'Classical', value: 'CLASSICAL' },
    { name: 'Country & Folk', value: 'COUNTRY' },
    { name: 'Dance & Electronic', value: 'DANCE' },
    { name: 'Hip-Hop & Rap', value: 'HIPHOP' },
    { name: 'Holiday', value: 'HOLIDAY' },
    { name: 'Jazz & Blues', value: 'JAZZ' },
    { name: 'Pop', value: 'POP' },
    { name: 'R&B & Soul', value: 'SOUL' },
    { name: 'Reggae', value: 'REGGAE' },
    { name: 'Rock', value: 'ROCK' },
  ]
  mood!: string
  moodList = [
    { name: 'Angry', value: 'ANGRY' },
    { name: 'Bright', value: 'BRIGHT' },
    { name: 'Calm', value: 'CALM' },
    { name: 'Dark', value: 'DARK' },
    { name: 'Dramatic', value: 'DRAMATIC' },
    { name: 'Funky', value: 'FUNKY' },
    { name: 'Happy', value: 'HAPPY' },
    { name: 'Inspirational', value: 'INSPIRATIONAL' },
    { name: 'Romantic', value: 'ROMANTIC' },
    { name: 'Sad', value: 'SAD' },
  ]
  searchQuery = ''
  searchText = ''
  bgDragColor: boolean = false
  uploadingAudio: boolean = false

  queryClient!: QueryClient
  libMusicData!: any
  isLibMusicLoading!: boolean
  libMusicParams!: any
  musicSearchParams!: any
  stockMusicData!: any
  isStockMusicLoading!: boolean
  musicVolume = 15
  showFilters!: boolean

  get options() {
    return { ...sliderOPtions, piecewise: true, tooltip: 'hover', tooltipDir: 'bottom' }
  }

  get sliderValue() {
    return this.musicVolume
  }

  set sliderValue(value: number) {
    this.musicVolume = value
  }

  get musicList() {
    return this.currentContentTab === 'LIB'
      ? get(this.libMusicData, 'data', []).map((audio: any) => ({
          url: audio.originalUrl,
          title: audio.title,
          source: 'UPLOADED',
          genre: 'library',
          mood: 'self',
          id: audio.id,
        }))
      : get(this.stockMusicData, 'data', []).map((audio: any) => ({
          url: audio.previewUrl,
          title: audio.title,
          // @ts-ignore
          genre: genreEnum[audio.genre],
          // @ts-ignore
          mood: moodEnum[audio.mood],
          source: 'STOCK',
          id: audio.id,
        }))
  }

  get selectedRangeStart() {
    return this.currentContentTab === 'LIB' ? this.libMusicParams.offset + 1 : this.musicSearchParams.offset + 1
  }

  get selectedRangeEnd() {
    return this.currentContentTab === 'LIB'
      ? this.libMusicParams.offset + this.musicList.length
      : this.musicSearchParams.offset + this.musicList.length
  }

  get totalMusicCount() {
    return this.currentContentTab === 'LIB' ? get(this.libMusicData, 'count', 0) : get(this.stockMusicData, 'count', 0)
  }

  toggleMusicFilters() {
    this.showFilters = !this.showFilters
    if (this.showFilters) {
      this.musicSearchParams.offset = 0
      this.genre = 'CINEMATIC'
      this.mood = 'CALM'
    } else {
      this.musicSearchParams.offset = 0
      this.genre = ''
      this.mood = ''
    }
  }

  handleNavigation(nav: string) {
    if (this.currentContentTab === 'LIB') {
      nav === 'next' && this.libMusicParams.offset <= this.totalMusicCount && (this.libMusicParams.offset += 10)
      nav === 'prev' && this.libMusicParams.offset >= 10 && (this.libMusicParams.offset -= 10)
    } else {
      nav === 'next' && this.musicSearchParams.offset <= this.totalMusicCount && (this.musicSearchParams.offset += 10)
      nav === 'prev' && this.musicSearchParams.offset >= 10 && (this.musicSearchParams.offset -= 10)
    }
  }

  handleSelectMusic(music: any) {
    this.$emit('selectMusic', {
      music,
      volume: this.musicVolume,
    })
    this.closeModal()
  }

  search() {
    this.searchText = this.searchQuery
  }

  audioUploader() {
    this.$store.dispatch('showFileUploader', {
      accept: 'audio/*',
      onChange: this.loadAudioFile,
      multiple: true,
    })
  }

  async loadAudioFile() {
    const file = this.$store.getters.selectedFile
    if (file) {
      this.uploadMusic(file)
    }
  }

  async dropImageFiles(e: any) {
    this.bgDragColor = false
    const files = e.dataTransfer.files

    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      const type = get(file, 'type', '')

      if (!type.includes('image')) {
        this.$store.dispatch('pushNotification', {
          text: 'Please choose valid image',
          type: 'ERROR',
        })
        continue
      }

      if (file) {
        // await this.uploadImage(file)
      }
    }
    this.uploadingAudio = false
  }

  async uploadMusic(file: any) {
    this.uploadingAudio = true
    try {
      await AdoriServiceV6.uploadMusic(this.networkId, file[0])
      this.resetMusicLib()
      this.uploadingAudio = false
    } catch (error) {
      this.uploadingAudio = false
    }
  }
  clearSearchQuery() {
    this.searchQuery = ''
  }

  closeModal() {
    this.$emit('closeModal')
  }

  resetMusicLib() {
    this.queryClient.invalidateQueries(musicQueryKeys.LIB_MUSIC)
  }
}
