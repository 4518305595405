var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      attrs: { hasCustomEvent: "", hideCloseButton: _vm.isLoaderActive },
      on: {
        closeModal: function () {
          _vm.$emit("closeModal")
        },
      },
    },
    [
      _c("template", { slot: "header" }, [
        _c(
          "p",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isLoaderActive,
                expression: "!isLoaderActive",
              },
            ],
            staticClass: "f4 white",
          },
          [_vm._v("Select Audio Voicing")]
        ),
      ]),
      _c("template", { slot: "body" }, [
        _vm.isLoaderActive
          ? _c("div", { staticClass: "w-100 flex flex-column items-center" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.loaderText === "Converting to Audio ....",
                      expression: "loaderText === 'Converting to Audio ....'",
                    },
                  ],
                  staticClass: "flex w-100 justify-between items-center",
                },
                [
                  _c("div", { staticClass: "text-loader" }),
                  _c(
                    "i",
                    {
                      staticClass: "material-icons light-gray f2 v-mid pa1 ml5",
                    },
                    [_vm._v("forward")]
                  ),
                  _c("div", { staticClass: "audio-loader" }),
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.loaderText === "Generating AI images ....",
                      expression: "loaderText === 'Generating AI images ....'",
                    },
                  ],
                  staticClass: "flex w-100 justify-center items-center",
                },
                [_c("div", { staticClass: "img-loader" })]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.loaderText === "Creating visuals ...",
                      expression: "loaderText === 'Creating visuals ...'",
                    },
                  ],
                  staticClass: "flex w-100 justify-center items-center",
                },
                [_c("div", { staticClass: "tag-loader" })]
              ),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.loaderText === "Creating visuals ...",
                      expression: "loaderText === 'Creating visuals ...'",
                    },
                  ],
                  staticClass: "silver mt3 tc f6",
                  staticStyle: { "max-width": "350px" },
                },
                [
                  _vm._v(
                    _vm._s(_vm.tagCount) + " / " + _vm._s(_vm.totalTagsCount)
                  ),
                ]
              ),
              _c(
                "span",
                {
                  staticClass: "text-display mt4",
                  attrs: { "data-content": _vm.loaderText },
                },
                [_vm._v(_vm._s(_vm.loaderText))]
              ),
              _c(
                "span",
                {
                  staticClass: "silver mt3 tc f6",
                  staticStyle: { "max-width": "350px" },
                },
                [_vm._v(_vm._s(_vm.randomLoadingText))]
              ),
            ])
          : _c("div", { staticClass: "flex flex-column items-center min-w5" }, [
              _c("div", { staticClass: "flex justify-between items-center" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "ba bw1 br4 b--transparent bg-black-20 flex items-center",
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "f6 pv1 ph2 silver link pointer mr1",
                        class:
                          _vm.selectedTab === "PREMIUM"
                            ? "ba br4 b--transparent bg-black-80 light-gray"
                            : "",
                      },
                      [
                        _c("input", {
                          staticClass: "input-reset",
                          attrs: { type: "radio" },
                          domProps: { checked: _vm.selectedTab === "PREMIUM" },
                          on: {
                            click: function ($event) {
                              _vm.selectedTab = "PREMIUM"
                            },
                          },
                        }),
                        _vm._v("Pro voices\n          "),
                      ]
                    ),
                    _c(
                      "label",
                      {
                        staticClass: "f6 pv1 ph2 silver link pointer mr1",
                        class:
                          _vm.selectedTab === "CLONING"
                            ? "ba br4 b--transparent bg-black-80 light-gray"
                            : "",
                      },
                      [
                        _c("input", {
                          staticClass: "input-reset",
                          attrs: { type: "radio" },
                          domProps: { checked: _vm.selectedTab === "CLONING" },
                          on: {
                            click: function ($event) {
                              _vm.selectedTab = "CLONING"
                            },
                          },
                        }),
                        _vm._v("Clone your voice\n          "),
                      ]
                    ),
                  ]
                ),
              ]),
              _vm.selectedTab === "PREMIUM"
                ? _c(
                    "div",
                    { staticClass: "flex flex-column items-center" },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/tts.svg"),
                          alt: "",
                          width: "150",
                          height: "150",
                        },
                      }),
                      _c("VoiceSelection", {
                        attrs: {
                          buttonTitle: "Create Video",
                          quality: _vm.selectedTab,
                        },
                        on: { "voice-details": _vm.handleVoiceDetails },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.selectedTab === "BASIC"
                ? _c(
                    "div",
                    { staticClass: "flex flex-column items-center" },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/tts.svg"),
                          alt: "",
                          width: "150",
                          height: "150",
                        },
                      }),
                      _c("VoiceSelection", {
                        attrs: {
                          buttonTitle: "Create Video",
                          quality: _vm.selectedTab,
                        },
                        on: { "voice-details": _vm.handleVoiceDetails },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.selectedTab === "CLONING"
                ? _c("div", { staticClass: "w-100" }, [
                    _vm.voicesList.length === 0
                      ? _c("div", [
                          _c(
                            "div",
                            { staticClass: "white flex justify-center f3 mt4" },
                            [
                              _vm._v(
                                "Join our exclusive waitlist for early access!"
                              ),
                            ]
                          ),
                          _c("div", [
                            _c("div", { staticClass: "f6 gray mt3 pl2" }, [
                              _vm._v(
                                "\n              Be one of the first to try Adori’s AI-powered voice cloning. As a member of our early access program,\n              you’ll get priority access to:\n            "
                              ),
                            ]),
                            _c("ul", { staticClass: "f6 gray" }, [
                              _c("li", [
                                _vm._v(
                                  "Enterprise-grade voice cloning with unmatched accuracy and scalability"
                                ),
                              ]),
                              _c("li", [
                                _vm._v(
                                  "Seamless integration into your existing systems and workflows"
                                ),
                              ]),
                              _c("li", [
                                _vm._v(
                                  "Early insights and feedback opportunities to shape the future of AI voice technology"
                                ),
                              ]),
                            ]),
                            _c("iframe", {
                              attrs: {
                                "data-tally-src":
                                  "https://tally.so/embed/n0b81y?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1",
                                loading: "lazy",
                                width: "100%",
                                frameborder: "0",
                                marginheight: "0",
                                marginwidth: "0",
                              },
                              on: { load: _vm.loadIframe },
                            }),
                          ]),
                        ])
                      : _c(
                          "div",
                          { staticClass: "flex flex-column items-center" },
                          [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/tts.svg"),
                                alt: "",
                                width: "150",
                                height: "150",
                              },
                            }),
                            _c("VoiceCloning", {
                              attrs: { buttonTitle: "Create Video" },
                              on: { "voice-details": _vm.handleVoiceDetails },
                            }),
                          ],
                          1
                        ),
                  ])
                : _vm._e(),
            ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }