import { CURRENT_ENV, ENVS } from '@/constants/env'
// @ts-ignore
import Vue from 'vue'

import VueShepherd from 'vue-shepherd'
import * as VueGoogleMaps from 'vue2-google-maps'
import Trend from 'vuetrend'
import Croppa from 'vue-croppa'
import 'vue-croppa/dist/vue-croppa.css'
// @ts-ignore
import Clipboard from 'v-clipboard'
import DatePicker from 'vue2-datepicker'
import Vue2TouchEvents from 'vue2-touch-events'
import Particles from 'particles.vue'
import Permissions from '@/plugins/permissions'
import Env from '@/plugins/env'
// @ts-ignore
import VueGtag from 'vue-gtag'
import { ElementTiptapPlugin } from 'element-tiptap'
import ElementUI from 'element-ui'

import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import './registerBaseComponents'

import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import { SMARTLOOK_ID, INTERCOM_ID } from '@/constants'
import smartlookClient from 'smartlook-client'
// @ts-ignore
import VueCompositionAPI from '@vue/composition-api'
// @ts-ignore
import { VueQueryPlugin } from 'vue-query'
import mixpanel from 'mixpanel-browser'
import { Crisp } from 'crisp-sdk-web'

Vue.config.productionTip = true

Sentry.init({
  Vue,
  environment: CURRENT_ENV,
  dsn: 'https://07243b939dfa4ab0935073d5af6820d8@o1021916.ingest.sentry.io/5988061',
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: [
        'localhost',
        'studio.adorilabs.com',
        'studio.dev.adorilabs.com',
        'studio.beta.adorilabs.com',
        'video.adorilabs.com',
        'video.dev.adorilabs.com',
        'video.beta.adorilabs.com',
        /^\//,
      ],
    }),
  ],

  beforeSend(event: any, hint: any) {
    if (event.message === 'FEEDBACK') {
      Sentry.showReportDialog({
        eventId: event.event_id,
        user: {
          name: store.getters.name,
          email: store.getters.email,
        },
        title: 'Feedback',
        subtitle: 'We would like to hear your thoughts,',
        subtitle2: 'suggestions, concerns or problems with anything so we can improve',
        labelName: 'Name',
        labelEmail: 'Email',
        labelComments: ' Comments',
        labelSubmit: 'Submit feedback',
        errorGeneric: 'An unknown error occurred while submitting your feedback. Please try again.',
      })
    }
    return event
  },
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})

// const script = document.createElement('script')
// script.src = 'https://growsurf.com/growsurf.js?v=2.0.0'
// script.setAttribute('grsf-campaign', GROW_SURF_CAMPAIGN_ID)
// script.async = true
// document.head.appendChild(script)

if (ENVS.prod) {
  mixpanel.init('6d67c22db4305318e87cfef60a2539f7', { debug: true, track_pageview: true, persistence: 'localStorage' })
  Vue.use(
    VueGtag,
    {
      config: { id: 'G-CSGSBEN2CJ' },
    },
    router
  )
} else {
  mixpanel.init('050beba000a8e7af43f2b21fafc83ce9', { debug: true, track_pageview: true, persistence: 'localStorage' })
}

Vue.use(VueShepherd)
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyA0za2AseBhiLcJlmXxjlJMg-w9Dse74gk',
    libraries: 'places',
  },
})

smartlookClient.init(SMARTLOOK_ID)
//@ts-ignore
// window.Intercom &&
//   //@ts-ignore
//   window.Intercom('boot', {
//     app_id: INTERCOM_ID,
//     alignment: 'left',
//     //Website visitor so may not have any user related info
//   })

// Crisp.configure('77c4b10d-1fa8-422b-a5cf-372c2a3b29cb')

Vue.use(Trend)
Vue.use(Croppa)
Vue.use(Clipboard)
Vue.use(DatePicker)
Vue.use(Vue2TouchEvents)
Vue.use(Particles)
Vue.use(Permissions)
Vue.use(Env)
// @ts-ignore
Vue.use(VueCompositionAPI)
Vue.use(VueQueryPlugin)
Vue.use(ElementTiptapPlugin, {
  lang: 'en',
  spellcheck: false,
})
Vue.use(ElementUI)

new Vue({
  router,
  store,
  render: (h: any) => h(App),
}).$mount('#app')
