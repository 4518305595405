
































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Mixins, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import get from 'lodash.get'
import AudiogramTemplate from '@/components/Audiogram/AudiogramTemplate.vue'
import AudiogramElement from '@/components/Audiogram/AudiogramElement.vue'
import { Sketch } from 'vue-color'
import { mixin as clickaway } from 'vue-clickaway'
import moment from 'moment'
import isEmpty from 'lodash.isempty'
import { isValidWebLink } from '@/utils/validation'

import AudiogramSearchImages from '@/components/Audiogram/AudiogramSearchImages.vue'
import AudiogramSearchVideos from '@/components/Audiogram/AudiogramSearchVideos.vue'
import AudiogramImagesList from '@/components/Audiogram/AudiogramImagesList.vue'
import AudiogramVideosList from '@/components/Audiogram/AudiogramVideosList.vue'
import MapLocationPicker from '@/components/Common/MapLocationPicker.vue'
import AdoriPhone from '@/components/Tags/AdorPhone.vue'
import TheToggleButton from '@/components/Common/TheToggleButton.vue'

// modals
import ModalTagPreview from '@/components/Modals/ModalTagPreview.vue'
import CountryModal from '@/components/Tags/Modals/CountryModal.vue'
import ModalPreviewImages from '@/components/Modals/ModalPreviewImages.vue'

// utils
import { contains, getQueryParam } from '@/utils/misc'
import { imageUrlToBase64, tagTypeMeta } from '@/utils/tag'

import Audiogram from '@/mixins/Audiogram'
import { tagQueryKeys } from '@/hooks/tag'
import { useGetAudioTrack } from '@/hooks/audio'
import ImageLibraries from '@/mixins/ImageLibraries'
import VideoLibraries from '@/mixins/VideoLibraries'
import GifGallery from '@/components/Common/Images/GifGallery.vue'
import ModalPlans from '../Modals/ModalPlans.vue'

import AdoriService from '@/services/adori'
import AdoriServiceV6 from '@/services/adori_v6'

@Component({
  components: {
    AudiogramTemplate,
    AudiogramElement,
    Sketch,
    AudiogramSearchImages,
    AudiogramSearchVideos,
    AudiogramImagesList,
    AudiogramVideosList,
    MapLocationPicker,
    ModalTagPreview,
    CountryModal,
    AdoriPhone,
    TheToggleButton,
    GifGallery,
    ModalPreviewImages,
    ModalPlans,
  },
  setup() {
    const trackUid: any = getQueryParam('uid')
    const { data: audioData } = useGetAudioTrack(trackUid)
    return {
      audioData,
    }
  },
})
export default class ModalTagEdit extends Mixins(clickaway, Audiogram, ImageLibraries, VideoLibraries) {
  @Getter imageSearchResult!: any
  @Getter networkId!: string
  @Getter fontsList!: any
  @Getter allElementAssets!: any
  @Getter tagCollections!: any
  @Getter placeHolderImages!: any
  @Getter networkType!: any
  @Getter timelinePosition!: any
  @Getter recentTagId!: any
  @Getter email!: string
  @Getter tagEffects!: string
  @Getter isYoutube!: Boolean
  @Getter isTimeLineEdited!: string

  @Action saveAudiogram!: any
  @Action closeModal!: any
  @Action clearAudiogramEdit!: any
  @Action setSelectedTagCollection!: any
  @Action setShowLoader!: any
  @Action getTagEffect!: any
  @Action windowOrientation!: any

  audioData!: any

  tagTitle: string = ''
  isTagPreviewShown: boolean = false
  toggleSaveShareScreen: boolean = false
  selectedTagCollection = ''
  selectedMP4Src: string = ''
  selectedMp4: any
  locationValue: any = null
  showGeoModal: boolean = false
  today: string = moment().utc().format('YYYY-MM-DD')

  showTagEffect: boolean = false
  kenBurnZoomDirectionList = [
    'top',
    'left',
    'right',
    'bottom',
    'center',
    'topleft',
    'topright',
    'centerleft',
    'centerright',
    'bottomleft',
    'bottomright',
  ]
  kenBurnZoomDirection = this.kenBurnZoomDirectionList[0]
  kenBurnZoomEffect = [
    { value: 1, title: 'no change' },
    { value: 1.5, title: 'zoom in' },
    { value: 0.5, title: 'zoom out' },
  ]
  kenBurnZoomEffectValue = 1
  kenBurnDuration = '01'

  selectedTagType = 'photo'
  isValidPhoneNumber: any = false
  currentPhoneNumber: any = null
  isUploadingTag: boolean = false
  hasStartedTyping: boolean = false
  ARTAgOptionList: string[] = [
    'Uppland Sofa',
    'Pinning Racks',
    'Dining Room',
    'Kitchen',
    'Galjon Pendant lamp',
    'Nordviken Bar stool',
    'Jamestown Hub',
    'Setup',
    'Covid Chart',
  ]
  hasClickedNext: boolean = false
  pollOptionArray: any = []
  isGif = false
  isVideo = false
  videoAsset!: any
  enabled = true
  showImagePreview = false
  image = ''
  showPlans = false

  openPlansModal() {
    if (['VIDEO_FREE'].includes(this.networkType)) {
      this.showPlans = true
    }
  }

  get isUpgradeReq() {
    return ['VIDEO_FREE'].includes(this.networkType) && this.isVideo
  }

  viewImages(image: string) {
    this.showImagePreview = true
    this.image = image
  }

  get isVideoEpisode() {
    return !!get(this.audioData, 'videoUrl', false)
  }

  get hasAudioAd() {
    return contains(['audio'], this.selectedTagType)
  }

  get hasDisplayAd() {
    return contains(['display_ad'], this.selectedTagType)
  }

  get hasVideoAd() {
    return contains(['video_ad'], this.selectedTagType)
  }

  get isSelectedTagAd() {
    if (this.selectedTagType === 'audio' || this.selectedTagType === 'display_ad') {
      return true
    } else return false
  }

  get displayAdGeoTargets() {
    let geotargets = get(this.$store, 'state.modal.tagEdit.data.displayAdMeta.defaultCampaign.geoTargets', [])
    return geotargets
  }

  get videoAdGeoTargets() {
    let geotargets = get(this.$store, 'state.modal.tagEdit.data.videoAdMeta.defaultCampaign.geoTargets', [])
    return geotargets
  }
  get networkIdAllTags() {
    if (this.networkId === 'nw_nRrC8ScA5RMri') {
      return true
    } else {
      return false
    }
  }
  // remove after demos
  get enabledForAccount() {
    if (this.email && this.email === 'demo+apple@adorilabs.com') {
      return true
    }
    return false
  }

  get tagTypes() {
    let tagTypesMeta = tagTypeMeta
    if (this.networkIdAllTags) {
      tagTypesMeta = tagTypesMeta.filter((tag) => {
        return tag.name !== 'artag'
      })
      return tagTypesMeta
    }
    // only apple demo remove post that
    if (this.enabledForAccount || this.isYoutube) {
      tagTypesMeta = tagTypeMeta.filter((tag) => {
        return this.isYoutube
          ? !['audio', 'display_ad', 'video_ad', 'poll'].includes(tag.name)
          : !['audio', 'display_ad', 'video_ad'].includes(tag.name)
      })
    }
    if (this.email !== 'demo+apple@adorilabs.com' && this.email !== 'demo+adori@adorilabs.com') {
      tagTypesMeta = tagTypesMeta.filter((tag) => {
        return tag.name !== 'artag' && tag.name !== 'video_ad'
      })
    }
    if (this.networkIdAllTags) {
      tagTypesMeta = tagTypesMeta.filter((tag) => {
        return tag.name !== 'artag'
      })
    }

    return tagTypesMeta
  }

  get inputValues() {
    return this.tagEditStoreData.data.inputValues
  }

  set inputValues(newInputValues: string[]) {
    this.$store.dispatch('changeTagInputValues', newInputValues)
  }

  get shareable() {
    return this.tagEditStoreData.shareable
  }

  set shareable(newShareable: boolean) {
    this.$store.dispatch('changeTagShareable', newShareable)
  }

  get saveable() {
    return this.tagEditStoreData.saveable
  }

  set saveable(newSaveable: boolean) {
    this.$store.dispatch('changeTagSaveable', newSaveable)
  }

  get titleValue() {
    const adName = get(this.$store, 'state.modal.tagEdit.data.caption', '')
    return adName
  }
  set titleValue(value: string) {
    this.$store.dispatch('changeTagCaption', value)
  }

  get hasSameOptions() {
    let optionsArray = this.inputValues.slice(1).filter((ele) => ele)
    this.pollOptionArray = optionsArray
    if (optionsArray.length >= 1) {
      this.hasStartedTyping = true
    }
    let filteredInputSet = new Set(optionsArray)
    let filteredInputArrayWithoutDuplicates = Array.from(filteredInputSet)
    return optionsArray.length > 1 && optionsArray.length === filteredInputArrayWithoutDuplicates.length
  }

  get checkValidation() {
    if (this.isUploadingTag) {
      return true
    }
    if (this.selectedTagType === 'call') {
      if (!this.inputValues[0] || this.inputValues[0] === '') {
        return true
      } else if (!this.isValidPhoneNumber) {
        return true
      }
    }
    if (this.selectedTagType === 'Web link') {
      return !isValidWebLink(this.inputValues[0] && this.inputValues[0].trim())
    }
    if (this.selectedTagType === 'poll') {
      if (!this.inputValues[0]) {
        return true
      }
      let optionsArray = this.inputValues.slice(1).filter((ele) => ele)

      if (optionsArray.length < 2) {
        return true
      }

      let filteredInputSet = new Set(optionsArray)
      let filteredInputArrayWithoutDuplicates = Array.from(filteredInputSet)
      return !(optionsArray.length > 1 && optionsArray.length === filteredInputArrayWithoutDuplicates.length)
    }
    if (this.selectedTagType === 'location') {
      const data = this.tagEditStoreData.data
      if (this.locationValue !== null) {
        this.hasStartedTyping = true
      }
      return !(data.locationId || data.location.place)
    }
    if (this.selectedTagType === 'MESSAGE') {
      if (this.tagEditStoreData.data.caption !== null) {
        this.hasStartedTyping = true
      }
      return !this.tagEditStoreData.data.caption
    }
    if (this.selectedTagType === 'audio') {
      if (this.tagEditStoreData.data.caption !== null) {
        this.hasStartedTyping = true
      }
      return !this.tagEditStoreData.data.caption
    }

    if (this.selectedTagType === 'buy') {
      if (this.buyActionUrl !== null) {
        return !isValidWebLink(this.buyActionUrl && this.buyActionUrl.trim())
      }
    }

    if (this.selectedTagType === 'ARTAG') {
      if (this.ARTagAssetUrl) {
        return !isValidWebLink(this.ARTagAssetUrl && this.ARTagAssetUrl.trim())
      }
    }
    return false
  }

  get dateValidation() {
    if (this.displayAdForeverOption === false) {
      if (
        this.displayAdStartDateValue === null ||
        this.displayAdStartDateValue === 'Invalid dateZ' ||
        this.displayAdEndDateValue === null ||
        this.displayAdEndDateValue === 'Invalid dateZ'
      ) {
        return true
      }
    }
    return false
  }

  get displayAdForeverOption() {
    if (this.tagEditStoreData.data.displayAdMeta.defaultCampaign.frequency === 'FOREVER') {
      return true
    }
    return false
  }
  set displayAdForeverOption(newValue: boolean) {
    if (newValue === true) {
      this.$store.dispatch('changeDisplayAdForeverOption', 'FOREVER')
    } else {
      this.$store.dispatch('changeDisplayAdForeverOption', 'DAILY')
    }
  }

  get displayAdStartDateValue() {
    const dateValue = this.tagEditStoreData.data.displayAdMeta.defaultCampaign.startTime
      ? moment(this.tagEditStoreData.data.displayAdMeta.defaultCampaign.startTime).format('YYYY-MM-DD')
      : null
    return dateValue
  }
  set displayAdStartDateValue(value: any) {
    value = value ? moment(value).format('YYYY-MM-DD') : null
    this.$store.dispatch('changeDisplayAdStartDateValue', value)
  }

  get displayAdEndDateValue() {
    const dateValue = this.tagEditStoreData.data.displayAdMeta.defaultCampaign.endTime
      ? moment(this.tagEditStoreData.data.displayAdMeta.defaultCampaign.endTime).format('YYYY-MM-DD')
      : null
    return dateValue
  }
  set displayAdEndDateValue(value: any) {
    value = value ? moment(value).format('YYYY-MM-DD') : null
    this.$store.dispatch('changeDisplayAdEndDateValue', value)
  }

  get keywordValue() {
    let keywords: any = []
    if (this.selectedTagType === 'display_ad') {
      keywords = get(this.$store, 'state.modal.tagEdit.data.displayAdMeta.defaultCampaign.keywords', [])
    } else if (this.selectedTagType === 'video_ad') {
      keywords = get(this.$store, 'state.modal.tagEdit.data.videoAdMeta.defaultCampaign.keywords', [])
    }
    if (!isEmpty(keywords[0])) {
      let initialArr = []
      for (const item of keywords) {
        initialArr.push({ key: item.split(':')[0], value: item.split(':')[1] })
      }
      keywords = initialArr
    }
    return keywords
  }

  get tagCollectionsOptions() {
    return Object.keys(this.tagCollections).map((tagName: string) => ({
      text: tagName,
      value: tagName,
    }))
  }

  get buyProductName() {
    return this.tagEditStoreData.data.buyMeta.name
  }
  set buyProductName(value: any) {
    this.$store.dispatch('changeBuyProductName', value)
  }

  get buyActionUrl() {
    return this.tagEditStoreData.data.buyMeta.actionUrl
  }
  set buyActionUrl(value: any) {
    this.$store.dispatch('changeBuyActionUrl', value)
  }

  get buyActionText() {
    return this.tagEditStoreData.data.buyMeta.actionText
  }

  set buyActionText(value: any) {
    this.$store.dispatch('changeBuyActionText', value)
  }

  get ARTagName() {
    return this.tagEditStoreData.data.artagMeta.name === '' ? 'Kitchen' : this.tagEditStoreData.data.artagMeta.name
  }

  set ARTagName(value: any) {
    this.$store.dispatch('changeARTagName', value)
  }

  get ARTagAssetUrl() {
    return this.tagEditStoreData.data.artagMeta.assetUrl
  }

  set ARTagAssetUrl(value: any) {
    this.$store.dispatch('changeARTagAssetUrl', value)
  }

  onARAssetChange(event: any, index: any) {
    this.$store.dispatch('changeARTagName', this.ARTAgOptionList[index - 1])
  }

  get isValidationForInput() {
    if (this.selectedTagType === 'call') {
      if (this.inputValues[0] !== undefined) {
        this.hasStartedTyping = true
      }
      return (!this.inputValues[0] && this.hasStartedTyping) || (!this.inputValues[0] && this.hasClickedNext)
    } else if (this.selectedTagType === 'poll') {
      return (
        (!this.inputValues[0] && this.hasStartedTyping) ||
        (!this.inputValues[0] && this.hasClickedNext) ||
        (!this.inputValues[0] && this.inputValues.length > 1)
      )
    } else if (this.selectedTagType === 'Web link') {
      if (this.inputValues[0] !== undefined) {
        this.hasStartedTyping = true
      }
      return (
        (!isValidWebLink(this.inputValues[0] && this.inputValues[0].trim()) && this.hasStartedTyping) ||
        (!isValidWebLink(this.inputValues[0] && this.inputValues[0].trim()) && this.hasClickedNext)
      )
    }
    return true
  }

  get inputMeta() {
    if (this.selectedTagType === 'Web link') {
      return {
        placeholder: 'Website',
        labelText: 'link',
        labelIcon: 'link',
      }
    } else if (this.selectedTagType === 'location') {
      return {
        placeholder: 'Enter Map Location',
        labelText: 'location',
        labelIcon: 'location_on',
      }
    } else if (this.selectedTagType === 'call') {
      return {
        placeholder: 'Enter Name',
        labelText: 'contact',
        labelIcon: 'phone',
      }
    } else if (this.selectedTagType === 'buy') {
      return {
        placeholder: 'Name of the product',
        labelText: 'Name',
        labelIcon: 'short_text',
      }
    } else {
      return {
        placeholder: 'Enter a Question',
        labelText: 'question',
        labelIcon: 'poll',
      }
    }
  }

  get modalTitle() {
    if (this.tagEditStoreData.tagId) return 'Edit tag details'
    else return 'Create a new tag'
  }

  get shapesAssets() {
    return this.allElementAssets.shapes
  }

  get PatternsAssets() {
    return this.allElementAssets.patterns
  }

  get isOpenInEditMode() {
    return this.tagEditStoreData.tagId ? true : false
  }

  get tagEditStoreData() {
    return this.$store.state.modal.tagEdit
  }

  async mounted() {
    this.setSourceComponent('TAG')
    this.loadingCanvas = true
    this.selectedTagType = this.tagEditStoreData.tagType || 'photo'
    if (this.selectedTagType === 'photo') this.setActiveTab('templates')
    else this.setActiveTab(this.selectedTagType)
    if (
      this.tagEditStoreData.tagId &&
      this.selectedTagType === 'photo' &&
      this.tagEditStoreData.data?.image?.includes('.gif')
    ) {
      this.tagTitle = this.tagEditStoreData.tagTitle || 'Untitled'
      this.isGif = true
      this.setActiveTab('image')
      this.loadingCanvas = false
      return
    }

    if (this.tagEditStoreData.tagId && this.selectedTagType === 'photo' && this.tagEditStoreData.data?.videoId) {
      this.tagTitle = this.tagEditStoreData.tagTitle || 'Untitled'
      this.tagEditStoreData.data.videoSrc = this.tagEditStoreData.data.video.originalUrl
      this.enabled = false
      setTimeout(() => {
        this.enabled = true
        this.isVideo = true
      }, 100)
      this.setActiveTab('video')
      this.loadingCanvas = false
      console.log(this.tagEditStoreData.data)
      return
    }
    this.initCanvasSize(this.tagEditStoreData.data.orientation)
    if (this.$store.state.modal.newTagOpenFrom) {
      this.initCanvasSize(this.$store.state.modal.newTagOpenFrom)
    }

    await this.initCanvas(this.isVideoEpisode)

    if (this.tagEditStoreData.tagId) {
      this.tagTitle = this.tagEditStoreData.tagTitle || 'Untitled'
      if (this.tagEditStoreData.data.canvasData) {
        this.editAudiogram(this.tagEditStoreData.data.canvasData)
      } else {
        //support caption of legacy tags
        if (this.tagEditStoreData.data.caption && !this.tagEditStoreData.tagTitle) {
          this.tagTitle = this.tagEditStoreData.data.caption
        }
        if (this.tagEditStoreData.data.image) {
          this.loadImageFromUrl(this.tagEditStoreData.data.image, this.tagEditStoreData)
        } else {
          this.getVideoElement(this.tagEditStoreData.data.video.originalUrl)
        }
      }

      if (get(this.tagEditStoreData.data, 'effectId', null)) {
        this.showTagEffect = true
        if (this.tagEffects[get(this.tagEditStoreData.data, 'effectId')]) {
          const currentTagEffect: any = this.tagEffects[get(this.tagEditStoreData.data, 'effectId')]
          this.kenBurnZoomDirection = currentTagEffect.zoomDirection
          this.kenBurnZoomEffectValue = currentTagEffect.zoomValue
          this.kenBurnDuration = `${currentTagEffect.zoomDuration}`
        } else {
          await this.getTagEffect(get(this.tagEditStoreData.data, 'effectId'))
          const currentTagEffect: any = this.tagEffects[get(this.tagEditStoreData.data, 'effectId')]
          this.kenBurnZoomDirection = currentTagEffect.zoomDirection
          this.kenBurnZoomEffectValue = currentTagEffect.zoomValue
          this.kenBurnDuration = `${currentTagEffect.zoomDuration}`
        }
      }
    }

    if (this.tagEditStoreData.suggestedTag && this.tagEditStoreData.data.image) {
      this.loadImageFromUrl(this.tagEditStoreData.data.image, this.tagEditStoreData)
    }
    this.loadingCanvas = false
    this.initCenteringGuidelines(this.canvasContext)
    this.initAligningGuidelines(this.canvasContext)
  }

  addLocation() {
    this.showGeoModal = !this.showGeoModal
  }
  removeLocation(e: any) {
    let curTarget = e.currentTarget
    if (curTarget.dataset) {
      const country = curTarget.dataset.country
      let geoTargets: any = []
      if (this.selectedTagType === 'display_ad') {
        geoTargets = this.displayAdGeoTargets
      } else if (this.selectedTagType === 'video_ad') {
        geoTargets = this.videoAdGeoTargets
      }
      const geo = geoTargets.filter((target: { country: string }) => target.country !== country)
      if (this.selectedTagType === 'display_ad') {
        this.$store.dispatch('changeDisplayAdGeoTargets', geo)
      } else if (this.selectedTagType === 'video_ad') {
        this.$store.dispatch('changeVideoAdGeoTargets', geo)
      }
    }
  }

  saveLocation(selectedData: { name: string; code: string }) {
    let geoTargets: { country: string }[] = []
    if (this.selectedTagType === 'display_ad') {
      if (this.displayAdGeoTargets && this.displayAdGeoTargets.length > 0) {
        geoTargets = this.displayAdGeoTargets
      }
    } else if (this.selectedTagType === 'video_ad') {
      if (this.videoAdGeoTargets && this.videoAdGeoTargets.length > 0) {
        geoTargets = this.videoAdGeoTargets
      }
    }
    const isInArray = geoTargets.find((el: any) => el.country === selectedData.name) !== undefined
    if (!isInArray && geoTargets.length < 5) {
      geoTargets.push({
        country: selectedData.name,
      })
    }
    if (this.selectedTagType === 'display_ad') {
      this.$store.dispatch('changeDisplayAdGeoTargets', geoTargets)
    } else if (this.selectedTagType === 'video_ad') {
      this.$store.dispatch('changeVideoAdGeoTargets', geoTargets)
    }
    this.addLocation()
  }

  keywordUpdate(data: any) {
    if (this.selectedTagType === 'display_ad') {
      this.$store.dispatch('changeDisplayAdKeywordValues', data)
    } else if (this.selectedTagType === 'video_ad') {
      this.$store.dispatch('changeVideoAdKeywordValues', data)
    }
  }

  handleFocus(event: any) {
    event.target.blur()
  }

  validPhoneNumber(data: any) {
    this.isValidPhoneNumber = data.isValid
    if (data && data.number.input && data.isValid) {
      let arrayData = []
      arrayData.push(this.inputValues[0])
      arrayData.push(data.number.e164)
      this.$store.dispatch('changeTagInputValues', arrayData)
    }
  }

  phoneNumberCurrentInput(data: any) {
    this.currentPhoneNumber = data
  }

  locationInput(value: any) {
    if (!value) {
      this.tagEditStoreData.data.location.place = null
    }
    this.locationValue = value
  }
  handleLocationDropdonw() {
    // @ts-ignore
    document.getElementById('mapLocationPicker').focus()
  }

  loadGif(asset: any) {
    this.tagEditStoreData.data.image = asset.url
    this.enabled = false
    setTimeout(() => {
      this.enabled = true
    }, 100)
  }

  handleImageLoad(asset: any) {
    if (asset.url.includes('.gif')) {
      this.isGif = true
      this.loadGif(asset)
    } else {
      this.isGif = false
      this.loadAsset(asset, 'uploads')
    }
  }

  handleVideoLoad(asset: any) {
    this.videoAsset = asset
    this.isVideo = true
    this.tagEditStoreData.data.videoSrc = asset.originalUrl
    this.enabled = false
    setTimeout(() => {
      this.enabled = true
    }, 100)
  }

  async handleVideoSave() {
    this.audiogramLoader = true
    this.saveText = 'Saving...'
    const orientation = this.orientation
    let video: any
    const parsed_url = new URL(this.tagEditStoreData.data.videoSrc)
    video = await AdoriServiceV6.uploadVideoUrl(
      this.networkId,
      this.tagEditStoreData.data.videoSrc,
      this.videoAsset?.thumbNail
    )

    this.tagEditStoreData.data.imageId = null
    this.tagEditStoreData.data.videoSrc = video.originalUrl
    this.tagEditStoreData.data.videoId = video.id
    if (!isEmpty(this.tagEditStoreData.data.canvasData)) {
      this.tagEditStoreData.data.canvasData.objects[1].src = video.originalUrl
    }

    const tagId = this.tagEditStoreData.tagId

    if (!tagId) {
      this.tagEditStoreData.data.orientation = orientation
      try {
        await this.$store.dispatch('uploadTag', false)

        if (this.timelinePosition && this.timelinePosition >= 0) {
          const tagItem = {
            tagId: this.recentTagId,
            offsetMillis: this.timelinePosition,
          }
          await this.$store.dispatch('addAudioTag', {
            audioUid: getQueryParam('uid'),
            tagPosition: tagItem,
          })
          this.$store.commit('setTimelineEdited', true)
        }
        this.$store.dispatch('closeModal')
        this.windowOrientation(orientation)
        this.queryClient.invalidateQueries([tagQueryKeys.GET_TAGS, this.networkId, orientation])
        this.setSelectedTagCollection('ALL_TAGS')
        this.$store.commit('unSetTimelinePosition')
        this.audiogramLoader = false
      } catch (err) {
        this.audiogramLoader = false
        this.saveText = 'Save Changes'
      }
    } else {
      this.setShowLoader(true)
      this.closeModal()
      await this.$store.dispatch('uploadTag', true)
      this.queryClient.invalidateQueries([tagQueryKeys.GET_TAGS, this.networkId, orientation])
      getQueryParam('uid') && (await this.$store.dispatch('getAudioTags', getQueryParam('uid')))
      this.setShowLoader(false)
    }
  }
  async handleGifSave() {
    this.audiogramLoader = true
    this.saveText = 'Saving...'
    const orientation = this.orientation
    let image: any
    const parsed_url = new URL(this.tagEditStoreData.data.image)
    const parts = parsed_url.hostname.split('.')
    const path = parsed_url.pathname.substring(1)
    const domain = parts.slice(-2).join('.')

    if (domain == 'adorilabs.com') {
      image = await AdoriService.getImageInfo(this.networkId, path)
    } else {
      image = await AdoriService.uploadImage(this.networkId, {
        url: this.tagEditStoreData.data.image,
      })
    }

    this.tagEditStoreData.data.image = image.originalUrl
    this.tagEditStoreData.data.imageId = image.id
    if (!isEmpty(this.tagEditStoreData.data.canvasData)) {
      this.tagEditStoreData.data.canvasData.objects[1].src = image.originalUrl
    }

    const tagId = this.tagEditStoreData.tagId

    if (!tagId) {
      this.tagEditStoreData.data.orientation = orientation
      try {
        await this.$store.dispatch('uploadTag', false)

        if (this.timelinePosition && this.timelinePosition >= 0) {
          const tagItem = {
            tagId: this.recentTagId,
            offsetMillis: this.timelinePosition,
          }
          await this.$store.dispatch('addAudioTag', {
            audioUid: getQueryParam('uid'),
            tagPosition: tagItem,
          })
          this.$store.commit('setTimelineEdited', true)
        }
        this.$store.dispatch('closeModal')
        this.windowOrientation(orientation)
        this.queryClient.invalidateQueries([tagQueryKeys.GET_TAGS, this.networkId, orientation])
        this.setSelectedTagCollection('ALL_TAGS')
        this.$store.commit('unSetTimelinePosition')
        this.audiogramLoader = false
      } catch (err) {
        this.audiogramLoader = false
        this.saveText = 'Save Changes'
      }
    } else {
      this.setShowLoader(true)
      this.closeModal()
      await this.$store.dispatch('uploadTag', true)
      this.queryClient.invalidateQueries([tagQueryKeys.GET_TAGS, this.networkId, orientation])
      getQueryParam('uid') && (await this.$store.dispatch('getAudioTags', getQueryParam('uid')))
      this.setShowLoader(false)
    }
  }

  async handleSave() {
    const orientation = this.orientation
    if (this.isTimeLineEdited) {
      this.$store.dispatch('uploadAudioTags', getQueryParam('uid'))
    }
    if (this.isGif) {
      this.handleGifSave()
      return
    }
    if (this.isVideo) {
      this.handleVideoSave()
      return
    }
    if (!this.checkValidation && !this.dateValidation) {
      //canvas related data
      this.audiogramLoader = true
      this.saveText = 'Saving...'
      this.upScaleCanvas()
      let canvas = this.canvasContext.toDatalessJSON(['id'])

      let videoCoords
      this.canvasContext.getObjects().forEach((obj: any) => {
        //check if video is present in canvas
        if (obj.id && obj.id === 'video') {
          videoCoords = {
            x: obj.aCoords.tl.x,
            y: obj.aCoords.tl.y,
            height: obj.height * obj.scaleX,
            width: obj.width * obj.scaleY,
            rotate: Math.atan2(obj.aCoords.tr.y - obj.aCoords.tl.y, obj.aCoords.tr.x - obj.aCoords.tl.x),
          }
        }
      })
      if (!videoCoords) this.$store.commit('setTagVideoId', '')
      if (videoCoords) this.tagEditStoreData.data.videoCoords = videoCoords

      let base64img = this.canvasContext.toDataURL({ quality: 1.0 })
      let encodeImg = base64img.split('base64,')[1]
      this.downScaleCanvas(orientation)
      this.tagEditStoreData.data.orientation = orientation
      this.tagEditStoreData.data.canvasData = canvas
      this.tagEditStoreData.data.imageData = encodeImg
      //end canvas related data

      this.tagEditStoreData.tagTitle = this.tagTitle
      const tagId = this.tagEditStoreData.tagId
      const onSave = this.$store.state.modal.tagEditOnSave

      if (this.selectedTagType === 'Web link') {
        this.inputValues[0] = this.inputValues[0].trim()
      }

      this.tagEditStoreData.data.caption = ''

      if (!tagId) {
        this.isUploadingTag = true
        try {
          await this.$store.dispatch('uploadTag', false)

          if (this.timelinePosition && this.timelinePosition >= 0) {
            const tagItem = {
              tagId: this.recentTagId,
              offsetMillis: this.timelinePosition,
            }
            await this.$store.dispatch('addAudioTag', {
              audioUid: getQueryParam('uid'),
              tagPosition: tagItem,
            })
            this.$store.commit('setTimelineEdited', true)
          }
          this.$store.dispatch('closeModal')
          this.windowOrientation(orientation)
          this.queryClient.invalidateQueries([tagQueryKeys.GET_TAGS, this.networkId, orientation])
          if (onSave) await onSave()
          this.setSelectedTagCollection('ALL_TAGS')
          this.$store.commit('unSetTimelinePosition')
          this.isUploadingTag = false
        } catch (err) {
          this.isUploadingTag = false
          this.saveText = 'Save Changes'
        }
      } else {
        this.$store.dispatch('showConfirm', {
          title: 'Overwrite this tag?',
          description: 'Do you want to overwrite the existing tag or create a new tag?',
          yesButtonText: 'Yes, overwrite this tag',
          noButtonText: 'No, create a new tag',
          onConfirm: async () => {
            try {
              this.setShowLoader(true)
              this.closeModal()

              await this.$store.dispatch('uploadTag', true)
              this.queryClient.invalidateQueries([tagQueryKeys.GET_TAGS, this.networkId, orientation])
              getQueryParam('uid') && (await this.$store.dispatch('getAudioTags', getQueryParam('uid')))
              if (onSave) onSave()
              this.setShowLoader(false)
            } catch (err) {
              this.isUploadingTag = false
              this.saveText = 'Save Changes'
              this.closeModal()
              this.setShowLoader(false)
            }
          },
          onCancel: async () => {
            this.setShowLoader(true)
            this.closeModal()
            await this.$store.dispatch('uploadTag', false)
            this.queryClient.invalidateQueries([tagQueryKeys.GET_TAGS, this.networkId, orientation])
            if (onSave) onSave()
            this.setSelectedTagCollection('ALL_TAGS')
            this.setShowLoader(false)
          },
        })
      }
    } else {
      this.hasClickedNext = true
    }
  }

  changeTagTitle(title: string) {
    this.tagTitle = title
  }

  changeTagType(type: string) {
    this.$store.dispatch('changeTagType', type)
    if (type === 'photo') this.setActiveTab('templates')
    else this.setActiveTab(type)
  }

  changeTagCollection(collection: string) {
    this.selectedTagCollection = collection
    this.$store.dispatch('selectUniqueItem', collection)
  }

  previewTag() {
    this.upScaleCanvas()
    const base64Img = this.canvasContext.toDataURL({
      format: 'jpeg',
      quality: 1.0,
    })
    this.downScaleCanvas(this.orientation)
    const setting = {
      img: base64Img,
      saveable: this.saveable,
      shareable: this.shareable,
      orientation: this.orientation,
      tagType: this.selectedTagType,
    }
    this.$store.dispatch('showTagPreview', setting)
    this.isTagPreviewShown = true
  }

  setImageTab(tab: string) {
    this.imageTab = tab
  }

  setVideoTab(tab: string) {
    this.videoTab = tab
  }

  beforeDestroy() {
    this.clearAudiogramEdit()
  }

  @Watch('inputValues', { deep: true })
  onInputValuesChanged(newInputValues: string[]) {
    this.$store.dispatch('changeTagInputValues', newInputValues)
  }
  @Watch('selectedTagType')
  resetValuesOnTagChange() {
    if (!this.isOpenInEditMode && !this.loadingCanvas) {
      this.tagEditStoreData.data.inputValues = []
      this.tagEditStoreData.data.location.place = null
      this.hasStartedTyping = false
      this.tagEditStoreData.data.caption = null
      this.hasClickedNext = false
    }
  }

  @Watch('hasClickedNext')
  hasClickedNextWatch() {
    this.$emit('clickedNextStatus', this.hasClickedNext)
  }
}
