import axios from 'axios'
import get from 'lodash.get'
import router from '@/router'
import { ADORI_SSO_BASE_URL, ADORI_API_BASE_URL_V6, ADORI_STUDIO_VERSION } from '@/constants'
import {
  AdvertiserFetchParams,
  AdvertiserPayload,
  CampaignFetchParams,
  CampaignPayload,
  CampaignUpdate,
} from '@/Interfaces/StoreInterFaces/campaignInterfaces/Campaign'

import {
  FlightFetchParams,
  FlightPayload,
  ShowsFetch,
  updateShows,
} from '@/Interfaces/StoreInterFaces/campaignInterfaces/Flight'

import { AnalyticsByTimeParams, AnalyticsParams } from '@/Interfaces/StoreInterFaces/campaignInterfaces/Report'

import * as Sentry from '@sentry/vue'

import store from '@/store'

const assignVideoQuality = (videoFiles: any) => {
  return videoFiles.map((video: any) => ({
    ...video,
    quality: video.height >= 720 ? 'hd' : 'sd',
  }))
}

const classifyVideos = (videoFiles: any, thumbnail: any) => {
  // Assign quality if not present
  const videos = assignVideoQuality(videoFiles)

  // Separate HD and SD videos
  const hdVideos = videos.filter((v: any) => v.quality === 'hd')
  const sdVideos = videos.filter((v: any) => v.quality === 'sd')

  // Sort videos by resolution within each quality category
  const hdSorted = hdVideos.sort((a: any, b: any) => a.width * a.height - b.width * b.height)
  const sdSorted = sdVideos.sort((a: any, b: any) => b.width * b.height - a.width * b.height)

  // Initialize classification object
  const categories = {
    large: null,
    medium: null,
    small: null,
    tiny: null,
  }

  if (hdSorted.length >= 1) {
    // Lowest resolution HD video
    categories.medium = hdSorted[0]
    // Second lowest HD or same if only one
    categories.large = hdSorted.length === 1 ? hdSorted[0] : hdSorted[1]
  }

  if (sdSorted.length >= 1) {
    // Highest resolution SD video
    categories.small = sdSorted[0]
    // Second highest SD or same if only one
    categories.tiny = sdSorted.length === 1 ? sdSorted[0] : sdSorted[1]
  }

  // Modify object format as per requirements
  return Object.fromEntries(
    Object.entries(categories).map(([category, videoInfo]: any) => [
      category,
      videoInfo
        ? {
            width: videoInfo.width,
            height: videoInfo.height,
            url: videoInfo.link,
            thumbnail: thumbnail,
          }
        : null,
    ])
  )
}

const formatPexelsVideo = (video: any) => {
  const thumbnail = video.video_pictures[0]?.picture || ''
  const classifiedVideos = classifyVideos(video.video_files, thumbnail)

  return {
    id: video.id.toString(),
    source: {
      name: 'Pexels',
      url: 'https://pexels.com/api/',
    },
    urls: classifiedVideos,
    user: {
      name: video.user.name,
      url: video.user.url,
    },
    duration: video.duration,
  }
}

const getPexelsVideos = async (params: any) => {
  const res = await axios({
    method: 'GET',
    url: 'https://api.pexels.com/videos/search',
    params: {
      per_page: params.limit,
      page: (params.offset + 8) / 8,
      query: params.query,
      orientation: params.orientation ? params.orientation : 'all',
      size: 'all',
      color: 'all',
      sort: 'popular',
      seo_tags: true,
    },
    headers: {
      Authorization: 'dGFgIwnAOblOaP1Ev4810p3MG3dmRurkclV056SH2PcYRsqdvfbM8SP4',
    },
  })

  const count = res.data.total_results
  const limit = res.data.per_page
  const offset = res.data.page * 8 - 1
  const data = res.data.videos.map(formatPexelsVideo)
  return {
    count,
    limit,
    offset,
    data,
  }
}

export default {
  // ---------------------------------------------------------
  // This will have all apis
  // ---------------------------------------------------------
  fetchIABCategories(categoryId?: number) {
    if (categoryId) {
      return AdoriAPI.get(`/iab/categories/v2/${categoryId}`)
    }
    return AdoriAPI.get(`/iab/categories/v2`)
  },

  fetchDAASTCategories(categoryId?: string) {
    if (categoryId) {
      return AdoriAPI.get(`/iab/categories/daast/${categoryId}`)
    }
    return AdoriAPI.get(`/iab/categories/daast`)
  },

  // ---------------------------------------------------------
  // Display ads related APIs
  // ---------------------------------------------------------

  // Test passed
  fetchDisplayAds(networkId: string, payload: any) {
    return AdoriAPI.get(`/networks/${networkId}/displayads`, payload)
  },

  fetchDisplayAd(networkId: string, adId: string) {
    return AdoriAPI.get(`/networks/${networkId}/displayads/${adId}`)
  },

  // Test passed
  createDisplayAd(networkId: string, payload: any) {
    return AdoriAPI.post(`/networks/${networkId}/displayads`, payload)
  },

  updateDisplayAd(networkId: string, adId: string, payload: any) {
    return AdoriAPI.patch(`/networks/${networkId}/displayads/${adId}`, payload)
  },

  attachDisplayAdToTrack(networkId: string, audioUid: string, payload: any) {
    return AdoriAPI.post(`/networks/${networkId}/audiotracks/${audioUid}/displayads`, payload)
  },

  fetchTrackDisplayAds(networkId: string, audioUid: string, payload: any) {
    return AdoriAPI.get(`/networks/${networkId}/audiotracks/${audioUid}/displayads`, payload)
  },

  fetchTrackDisplayAd(networkId: string, audioUid: string, adId: string, payload: any) {
    return AdoriAPI.get(`/networks/${networkId}/audiotracks/${audioUid}/displayads/${adId}`, payload)
  },
  // Advertiser APIs
  // ---------------------------------------------------------

  fetchAdvertisers(params: AdvertiserFetchParams, networkId: string) {
    return AdoriAPI({
      method: 'get',
      url: `/networks/${networkId}/advertisers`,
      params,
    })
  },

  fetchOneAdvertiser(networkId: string, advertiserId: string) {
    return AdoriAPI({
      method: 'get',
      url: `/networks/${networkId}/advertisers/${advertiserId}`,
    })
  },

  createAdvertiser(data: AdvertiserPayload, networkId: string) {
    return AdoriAPI({
      method: 'post',
      url: `/networks/${networkId}/advertisers`,
      data,
    })
  },

  // ---------------------------------------------------------
  // Campaign APIs
  // ---------------------------------------------------------
  fetchCampaigns(params: CampaignFetchParams, networkId: string) {
    return AdoriAPI({
      method: 'get',
      url: `/networks/${networkId}/campaigns`,
      params,
    })
  },

  fetchOneCampaign(networkId: string, campaignId: string) {
    return AdoriAPI({
      method: 'get',
      url: `/networks/${networkId}/campaigns/${campaignId}`,
    })
  },

  createCampaign(data: CampaignPayload, networkId: string) {
    return AdoriAPI({
      method: 'post',
      url: `/networks/${networkId}/campaigns`,
      data,
    })
  },

  getCampaignShows(campaignId: string, networkId: string) {
    return AdoriAPI({
      method: 'get',
      url: `/networks/${networkId}/campaigns/${campaignId}/shows`,
    })
  },

  updateCampaign(data: CampaignUpdate, networkId: string, campaignId: string) {
    return AdoriAPI({
      method: 'patch',
      url: `/networks/${networkId}/campaigns/${campaignId}`,
      data,
    })
  },

  deleteCampaign(networkId: string, campaignId: string) {
    return AdoriAPI({
      method: 'delete',
      url: `/networks/${networkId}/campaigns/${campaignId}`,
    })
  },

  exportCampaign(networkId: string, campaignId: string, params: any) {
    return AdoriAPI({
      method: 'GET',
      url: `/networks/${networkId}/campaigns/${campaignId}/analytics/export`,
      params,
    })
  },

  // ---------------------------------------------------------
  // Flight APIs
  // ---------------------------------------------------------

  fetchFlights(params: FlightFetchParams, networkId: string, campaignId: string) {
    return AdoriAPI({
      method: 'get',
      url: `networks/${networkId}/campaigns/${campaignId}/flights`,
      params,
    })
  },

  createFlight(data: FlightPayload, networkId: string, campaignId: string) {
    return AdoriAPI({
      method: 'post',
      url: `networks/${networkId}/campaigns/${campaignId}/flights`,
      data,
    })
  },

  updateFlight(data: FlightPayload, networkId: string, campaignId: string, flightId: string) {
    return AdoriAPI({
      method: 'patch',
      url: `networks/${networkId}/campaigns/${campaignId}/flights/${flightId}`,
      data,
    })
  },
  deleteFlight(networkId: string, campaignId: string, flightId: string) {
    return AdoriAPI({
      method: 'delete',
      url: `networks/${networkId}/campaigns/${campaignId}/flights/${flightId}`,
    })
  },

  fetchShows(params: ShowsFetch, networkId: string, campaignId: string, flightId: string) {
    return AdoriAPI({
      method: 'get',
      url: `networks/${networkId}/campaigns/${campaignId}/flights/${flightId}/shows`,
      params,
    })
  },

  updateShows(data: updateShows, networkId: string, campaignId: string, flightId: string) {
    return AdoriAPI({
      method: 'put',
      url: `networks/${networkId}/campaigns/${campaignId}/flights/${flightId}/shows`,
      data,
    })
  },

  // ---------------------------------------------------------
  // Campaign Report APIs
  // ---------------------------------------------------------

  analyticsByTime(params: AnalyticsByTimeParams, networkId: string, campaignId: string) {
    return AdoriAPI({
      method: 'get',
      url: `networks/${networkId}/campaigns/${campaignId}/analytics/time`,
      params,
    })
  },

  analyticsByPosition(params: AnalyticsParams, networkId: string, campaignId: string) {
    return AdoriAPI({
      method: 'get',
      url: `networks/${networkId}/campaigns/${campaignId}/analytics/position`,
      params,
    })
  },
  analyticsByFlight(params: AnalyticsParams, networkId: string, campaignId: string) {
    return AdoriAPI({
      method: 'get',
      url: `networks/${networkId}/campaigns/${campaignId}/analytics/flight`,
      params,
    })
  },
  analyticsByShow(params: AnalyticsParams, networkId: string, campaignId: string) {
    return AdoriAPI({
      method: 'get',
      url: `networks/${networkId}/campaigns/${campaignId}/analytics/show`,
      params,
    })
  },

  analyticsSummary(params: AnalyticsParams, networkId: string, campaignId: string) {
    return AdoriAPI({
      method: 'get',
      url: `networks/${networkId}/campaigns/${campaignId}/analytics/`,
      params,
    })
  },

  // Buy related APIs
  // ---------------------------------------------------------
  fetchBuyTags(networkId: string, payload: any) {
    return AdoriAPI.get(`/networks/${networkId}/buy`, payload)
  },

  fetchBuyTag(networkId: string, tagId: string) {
    return AdoriAPI.get(`/networks/${networkId}/buy/${tagId}`)
  },

  createBuyTag(networkId: string, payload: any) {
    return AdoriAPI.post(`/networks/${networkId}/buy`, payload)
  },

  updateBuyTag(networkId: string, tagId: string, payload: any) {
    return AdoriAPI.patch(`/networks/${networkId}/buy/${tagId}`, payload)
  },

  deleteBuyTag(networkId: string, tagId: string) {
    return AdoriAPI.delete(`/networks/${networkId}/buy/${tagId}`)
  },

  // ARTag related APIs
  // ---------------------------------------------------------
  fetchARTags(networkId: string, payload: any) {
    return AdoriAPI.get(`/networks/${networkId}/artag`, payload)
  },

  fetchARTag(networkId: string, tagId: string) {
    return AdoriAPI.get(`/networks/${networkId}/artag/${tagId}`)
  },

  createARTag(networkId: string, payload: any) {
    return AdoriAPI.post(`/networks/${networkId}/artag`, payload)
  },

  updateARTag(networkId: string, tagId: string, payload: any) {
    return AdoriAPI.patch(`/networks/${networkId}/artag/${tagId}`, payload)
  },

  deleteARTag(networkId: string, tagId: string) {
    return AdoriAPI.delete(`/networks/${networkId}/artag/${tagId}`)
  },

  // Video related APIs
  // ---------------------------------------------------------
  fetchVideos(networkId: string, limit: number = 10, offset: number = 0) {
    return AdoriAPI.get(`/networks/${networkId}/videos?limit=${limit}&offset=${offset}`)
  },
  searchVideos(networkId: string, searchLibrary: string, params: any) {
    return searchLibrary === 'pexels'
      ? getPexelsVideos(params)
      : AdoriAPI({
          method: 'get',
          url: `/networks/${networkId}/video/${searchLibrary}/search`,
          params,
        })
  },

  fetchVideo(networkId: string, videoId: string) {
    return AdoriAPI.get(`/networks/${networkId}/video/${videoId}`)
  },

  createVideo(networkId: string, fs: any) {
    let data = new FormData()
    data.append('upload', fs)
    return AdoriAPI.post(`/networks/${networkId}/videos`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },

  uploadVideoUrl(networkId: string, url: string, thumbnailUrl: string) {
    const data: any = {
      videoUrl: url,
    }
    thumbnailUrl && (data.thumbnailUrl = thumbnailUrl)
    return AdoriAPI({
      method: 'POST',
      url: `/networks/${networkId}/videos/upload_url`,
      data,
    })
  },

  updateVideo(networkId: string, videoId: string, payload: any) {
    return AdoriAPI.patch(`/networks/${networkId}/video/${videoId}`, payload)
  },

  // Video ads related APIs
  // ---------------------------------------------------------

  // Test passed
  fetchVideoAds(networkId: string, payload: any) {
    return AdoriAPI.get(`/networks/${networkId}/videoads`, payload)
  },

  fetchVideoAd(networkId: string, adId: string) {
    return AdoriAPI.get(`/networks/${networkId}/videoads/${adId}`)
  },

  // Test passed
  createVideoAd(networkId: string, payload: any) {
    return AdoriAPI.post(`/networks/${networkId}/videoads`, payload)
  },

  updateVideoAd(networkId: string, adId: string, payload: any) {
    return AdoriAPI.patch(`/networks/${networkId}/videoads/${adId}`, payload)
  },

  deleteVideoAd(networkId: string, tagId: string) {
    return AdoriAPI.delete(`/networks/${networkId}/videoads/${tagId}`)
  },

  // ---------------------------------------------------------
  // YouTube Integration Api
  // ---------------------------------------------------------

  youtubeRssFeedEpisodes(payload: any) {
    return AdoriAPI.post(`/feedparser?force_refresh=false`, payload, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },

  // ---------------------------------------------------------
  // Webfonts Api
  // ---------------------------------------------------------

  fetchWebFonts() {
    return axios.get(
      'https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyDqojr28KcwlR8sN-ITpGZlzayQyZXSc5w&sort=alpha'
    )
  },

  // ---------------------------------------------------------
  // Audiogram Api
  // ---------------------------------------------------------

  save_audiogram(payload: any) {
    if (payload.params.audiogram_id) {
      return AdoriAPI.patch(
        `/networks/${payload.params.network_id}/audiograms/${payload.params.audiogram_id}`,
        payload.data,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
    } else {
      return AdoriAPI.post(`/networks/${payload.params.network_id}/audiograms`, payload.data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
    }
  },

  get_all_audiograms(networkId: string, { params }: any) {
    if (params.orientation) {
      return AdoriAPI.get(
        `/networks/${networkId}/audiograms?orientation=${params.orientation}&limit=${params.limit}&offset=${params.offset}`
      )
    } else {
      return AdoriAPI.get(`/networks/${networkId}/audiograms?limit=${params.limit}&offset=${params.offset}`)
    }
  },
  get_one_audiograms(networkId: string, audiogramId: string) {
    return AdoriAPI.get(`/networks/${networkId}/audiograms/${audiogramId}`)
  },

  get_all_audiogram_templates(networkId: string, { params }: any) {
    return AdoriAPI.get(`/networks/${networkId}/audiograms/templates?limit=${params.limit}&offset=${params.offset}`)
  },
  get_audiogram_templates(networkId: string, params: any) {
    return AdoriAPI({
      method: 'GET',
      url: `/networks/${networkId}/audiograms/templates`,
      params,
    })
  },
  get_template_by_Id(networkId: string, ag_template_id: any) {
    return AdoriAPI.get(`/networks/${networkId}/audiograms/templates/${ag_template_id}`)
  },

  delete_audiogram(networkId: string, audiogramId: any) {
    return AdoriAPI.delete(`/networks/${networkId}/audiograms/${audiogramId}`)
  },

  get_audiogram_by_Id(networkId: string, audiogramId: any) {
    return AdoriAPI.get(`/networks/${networkId}/audiograms/${audiogramId}`)
  },

  get_all_waveforms(networkId: string) {
    return AdoriAPI.get(`/networks/${networkId}/audiograms/waveforms`)
  },

  get_all_elements_assets(networkId: string, params: any) {
    return AdoriAPI({
      method: 'GET',
      url: `/networks/${networkId}/audiograms/assets`,
      params,
    })
  },

  // ---------------------------------------------------------
  // Audio's Audiogram positions
  // ---------------------------------------------------------
  // Test passed
  fetchAudioAudiograms(networkId: string, audioUid: string) {
    return AdoriAPI.get(`/networks/${networkId}/audiotracks/${audioUid}/audiograms`)
  },
  // Test passed
  updateAudioAudiogram(networkId: string, audioUid: string, payload: any) {
    return AdoriAPI.put(`/networks/${networkId}/audiotracks/${audioUid}/audiograms`, payload)
  },
  // ---------------------------------------------------------
  // Billing APIs
  // ---------------------------------------------------------

  fetchBillingSummary(networkId: string) {
    return AdoriAPI.get(`/networks/${networkId}/billing/subscriptions/summary`)
  },

  fetchBillingInvoicesUpcoming(networkId: string) {
    return AdoriAPI.get(`/networks/${networkId}/billing/invoices/upcoming`)
  },

  getDefaultBillingCard(networkId: string) {
    return AdoriAPI.get(`/networks/${networkId}/billing/cards/default`)
  },

  getBillingUsage(networkId: string) {
    return AdoriAPI.get(`/networks/${networkId}/usage`)
  },

  addBillingCard(networkId: string, success_url: string, cancel_url: string) {
    const params = {
      success_url,
      cancel_url,
    }
    return AdoriAPI({
      method: 'post',
      url: `/networks/${networkId}/billing/cards`,
      params,
    })
  },

  upgradePlan(networkId: string, params: any) {
    return AdoriAPI({
      method: 'get',
      url: `/networks/${networkId}/billing/pay_subscribe`,
      params,
    })
  },

  getGrowSurfHash(networkId: string) {
    return AdoriAPI({
      method: 'get',
      url: `/networks/${networkId}/billing/referral/grsf/hash`,
    })
  },

  // ---------------------------------------------------------
  // Tag Effects APIs
  // ---------------------------------------------------------

  createTagEffect(networkId: string, data: any) {
    return AdoriAPI({
      method: 'POST',
      url: `/networks/${networkId}/tageffects`,
      data,
    })
  },
  getTagEffect(networkId: string, effectId: any) {
    return AdoriAPI({
      method: 'GET',
      url: `/networks/${networkId}/tageffects/${effectId}`,
    })
  },
  updateTagEffect(networkId: string, effectId: any, data: any) {
    return AdoriAPI({
      method: 'PUT',
      url: `/networks/${networkId}/tageffects/${effectId}`,
      data,
    })
  },

  // ---------------------------------------------------------
  // open ai
  // ---------------------------------------------------------

  openAi(networkId: string, data: any) {
    return AdoriAPI({
      method: 'POST',
      url: `/networks/${networkId}/openai`,
      data,
    })
  },
  dalleResults(networkId: string, data: any) {
    return AdoriAPI({
      method: 'POST',
      url: `/networks/${networkId}/dalle`,
      data,
    })
  },
  stabilityResults(networkId: string, data: any) {
    return AdoriAPI({
      method: 'POST',
      url: `/networks/${networkId}/ai_image`,
      data,
    })
  },
  stabilityStatus(networkId: string, taskId: string) {
    return AdoriAPI({
      method: 'GET',
      url: `/networks/${networkId}/stability/${taskId}`,
    })
  },

  getAiScripts(networkId: string, params: any) {
    return AdoriAPI({
      method: 'GET',
      url: `networks/${networkId}/openai_ideas`,
      params,
    })
  },
  getAiTemplates(networkId: string) {
    return AdoriAPI({
      method: 'GET',
      url: `networks/${networkId}/openai/templates`,
    })
  },
  getAiScript(networkId: string, scriptId: string) {
    return AdoriAPI({
      method: 'GET',
      url: `networks/${networkId}/openai_idea/${scriptId}`,
    })
  },

  postAiScript(networkId: string, data: any) {
    return AdoriAPI({
      method: 'POST',
      url: `networks/${networkId}/openai_idea`,
      data,
    })
  },
  patchAiScript(networkId: string, scriptId: string, data: any) {
    return AdoriAPI({
      method: 'PATCH',
      url: `networks/${networkId}/openai_idea/${scriptId}`,
      data,
    })
  },

  deleteAiScript(networkId: string, scriptId: string) {
    return AdoriAPI({
      method: 'DELETE',
      url: `networks/${networkId}/openai_idea/${scriptId}`,
    })
  },

  // Blog to video APIs
  // ---------------------------------------------------------

  postBlogParse(networkId: string, data: any) {
    return AdoriAPI({
      method: 'POST',
      url: `networks/${networkId}/parse_blog`,
      data,
    })
  },

  postFileParse(networkId: string, payload: any) {
    let data = new FormData()
    data.append('file', payload.file)
    if (payload.isSummarize) {
      data.append('scriptLength', payload.scriptLength)
      data.append('scriptLanguage', payload.scriptLanguage)
      data.append('isSummarize', payload.isSummarize)
    }

    return AdoriAPI.post(`/networks/${networkId}/parse_file`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },

  getBlogParseStatus(networkId: string, taskId: string) {
    return AdoriAPI({
      method: 'GET',
      url: `networks/${networkId}/parse_blog/${taskId}`,
    })
  },

  postTextParse(networkId: string, data: any) {
    return AdoriAPI({
      method: 'POST',
      url: `networks/${networkId}/parse_text`,
      data,
    })
  },

  fetchDrafts(networkId: string, params: any) {
    return AdoriAPI({
      method: 'GET',
      url: `networks/${networkId}/draft_scenes`,
      params,
    })
  },
  fetchOneDrafts(networkId: string, draftId: string) {
    return AdoriAPI({
      method: 'GET',
      url: `networks/${networkId}/draft_scenes/${draftId}`,
    })
  },

  postDraft(networkId: string, data: any) {
    return AdoriAPI({
      method: 'POST',
      url: `networks/${networkId}/draft_scenes`,
      data,
    })
  },
  patchDraft(networkId: string, draftId: string, data: any) {
    return AdoriAPI({
      method: 'PATCH',
      url: `networks/${networkId}/draft_scenes/${draftId}`,
      data,
    })
  },

  deleteDraft(networkId: string, draftId: any) {
    return AdoriAPI.delete(`/networks/${networkId}/draft_scenes/${draftId}`)
  },

  // Subtitle APIs
  // ---------------------------------------------------------

  fetchSubtitleTemplates(networkId: string) {
    return AdoriAPI.get(`/networks/${networkId}/subtitles/templates`)
  },

  fetchSubtitleCustomTemplates(networkId: string, { params }: any) {
    return AdoriAPI.get(`/networks/${networkId}/subtitles/styles?limit=${params.limit}&offset=${params.offset}`)
  },

  createSubtitleCustomTemplate(payload: any) {
    return AdoriAPI.post(`/networks/${payload.params.network_id}/subtitles/styles`, payload.data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },

  deleteSubtitleCustomTemplate(networkId: string, styleId: any) {
    return AdoriAPI.delete(`/networks/${networkId}/subtitles/styles/${styleId}`)
  },

  fetchAudioTrackSubtitle(networkId: string, trackUid: string) {
    return AdoriAPI.get(`/networks/${networkId}/audiotracks/${trackUid}/subtitles`)
  },

  uploadAudioTrackSubtitle(payload: any) {
    return AdoriAPI.patch(
      `/networks/${payload.params.network_id}/audiotracks/${payload.params.track_uid}/subtitles`,
      payload,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
  },

  generateTrackSubtitles(networkId: string, trackUid: string) {
    return AdoriAPI.get(`/networks/${networkId}/audiotracks/${trackUid}/generate-subtitles`)
  },

  downloadSubtitles(networkId: string, payload: any) {
    return AdoriAPI.get(`/networks/${networkId}/audiotracks/${payload.audioUid}/download-subtitles?attachment=true`, {
      params: {
        format: payload.format,
      },
    })
  },

  // ---------------------------------------------------------
  // Music API's
  // ---------------------------------------------------------

  uploadMusic(networkId: string, payload: any) {
    const formData = new FormData()
    formData.append('upload', payload)
    const contentType = payload.url ? 'application/json' : 'multipart/form-data'
    return AdoriAPI({
      method: 'POST',
      url: `/networks/${networkId}/background-music`,
      data: formData,
      headers: {
        'Content-Type': contentType,
      },
    })
  },

  fetchLibMusic(networkId: string, params: any) {
    return AdoriAPI({
      method: 'GET',
      url: `/networks/${networkId}/background-music`,
      params,
    })
  },

  searchMusic(networkId: string, params: any) {
    return AdoriAPI({
      method: 'GET',
      url: `/networks/${networkId}/stock/background-music`,
      params,
    })
  },

  // ---------------------------------------------------------
  // gamification
  // ---------------------------------------------------------

  fetchAllBadges(networkId: string) {
    return AdoriAPI.get(`/networks/${networkId}/gamification/badges`)
  },

  fetchMyBadges(networkId: string) {
    return AdoriAPI.get(`/networks/${networkId}/gamification/my-badges`)
  },

  fetchMyScore(networkId: string) {
    return AdoriAPI.get(`/networks/${networkId}/gamification/my-score`)
  },

  fetchLeaderboard(networkId: string) {
    return AdoriAPI.get(`/networks/${networkId}/gamification/leaderboard`)
  },

  fetchCloneVoices(networkId: string) {
    return AdoriAPI.get(`/networks/${networkId}/clone/voices`)
  },

  ideaToScript(networkId: string, data: any) {
    return AdoriAPI({
      method: 'POST',
      url: `/networks/${networkId}/idea_to_script`,
      data,
    })
  },
}

// ---------------------------------------------------------
// API Client
// ---------------------------------------------------------

export const AdoriAPI = axios.create({
  baseURL: ADORI_API_BASE_URL_V6,
})

AdoriAPI.interceptors.request.use(function (config) {
  const token = store.getters.token

  if (token !== null) {
    config.headers.Authorization = `Bearer ${token}`
  }

  config.headers['X-Adori-Studio-Version'] = ADORI_STUDIO_VERSION

  return config
})

AdoriAPI.interceptors.response.use(
  function (response) {
    return response.data
  },
  function (error) {
    const response = error.response

    Sentry.captureException(error)

    if (get(response, 'config.params') && get(response, 'config.params.ignoreError')) {
      return response.status
    }

    if (!response) {
      return Promise.reject(new Error('No response.'))
    }

    if (response.status && response.status === 401) {
      store.dispatch('clearSession')
      window.location.href = `${ADORI_SSO_BASE_URL}/logout?redirect_uri=${encodeURIComponent(
        window.location.origin + '/login'
      )}`
    }

    if (response.status && response.status === 403) {
      router.push('/permission-error')
      store.dispatch('pushNotification', {
        text: 'You do not have necessary permissions for this action. Contact network owner to know more.',
        type: 'WARNING',
      })
    } else if (response.status && response.status === 422) {
      store.dispatch('pushNotification', {
        text: response.data.title || 'Something went wrong',
        type: 'ERROR',
      })
    } else if (response.status && response.status === 412) {
      if (response.data.title.includes('Limit exhausted')) {
        store.commit('setCurrentModal', 'plans')
      } else {
        store.dispatch('pushNotification', {
          text: response.data.description || response.data.title || 'Something went wrong',
          type: 'ERROR',
        })
      }
    } else if (response.status && response.status === 404) {
      return Promise.reject(new Error('No response.'))
    } else if (response.status && response.status >= 400) {
      store.dispatch('pushNotification', {
        text: response.data.title || 'Something went wrong',
        type: 'ERROR',
      })
    }

    return Promise.reject(new Error(response.data.title || 'Something went wrong.'))
  }
)
