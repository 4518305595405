import { useMutation, useQuery, useQueryClient } from 'vue-query'
import AdoriService from '@/services/adori'
import store from '@/store'
import { queryStaleTime } from '@/utils/query.config'
import { ComputedRef } from '@vue/composition-api'

// Query Keys
export enum NetworkQueryKeys {
  NETWORKUSERS = 'NETWORKUSERS',
  SHOWUSERS = 'SHOWUSERS',
}

// Queries
export function fetchNetworkUsers(networkId: string) {
  return AdoriService.fetchNetworkUsers(networkId)
}
export function fetchShowUsers(networkId: string, showId: string, isYoutube: boolean) {
  return AdoriService[isYoutube ? 'fetchShowYoutubeUsers' : 'fetchShowUsers'](networkId, showId)
}

export function addNetworkUsers(networkId: string, payload: { email: string; role: string }) {
  return AdoriService.addNetworkUser(networkId, payload)
}

// Hooks
export const useGetNetworkUsers = ({ enabled }: any, onSuccess?: any) => {
  const networkId = store.getters.networkId || localStorage.getItem('networkId')
  return useQuery([NetworkQueryKeys.NETWORKUSERS, networkId], () => fetchNetworkUsers(networkId), {
    staleTime: queryStaleTime,
    cacheTime: queryStaleTime,
    onSuccess,
    enabled,
  })
}
export const useGetShowUsers = (isYoutube = false, showId: ComputedRef<string>, { enabled }: any, onSuccess?: any) => {
  const networkId = store.getters.networkId || localStorage.getItem('networkId')
  return useQuery(
    [NetworkQueryKeys.SHOWUSERS, networkId, showId],
    () => fetchShowUsers(networkId, showId.value, isYoutube),
    {
      staleTime: queryStaleTime,
      cacheTime: queryStaleTime,
      onSuccess,
      enabled,
    }
  )
}

// mutations

export const useAddNetworkUser = (payload: { email: string; role: string }) => {
  const queryClient = useQueryClient()
  const networkId = store.getters.networkId || localStorage.getItem('networkId')
  return useMutation(() => addNetworkUsers(networkId, payload), {
    onSuccess: (data: any) => {
      queryClient.invalidateQueries([NetworkQueryKeys.NETWORKUSERS, networkId])
      store.dispatch('updateIsSupport')
    },
  })
}
