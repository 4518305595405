var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "fixed flex justify-center items-center left-0 right-0 top-0 bottom-0 bg-black-80 no-select custom-index",
    },
    [
      _c(
        "div",
        {
          staticClass:
            "flex flex-column bg-adori-gray mh-95 min-w5 br4 shadow-5",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "flex items-center justify-between bg-adori-light-gray br3",
            },
            [
              _c("div", { staticClass: "flex silver gap-2" }, [
                _c(
                  "div",
                  {
                    staticClass: "ph3 pv2 pointer grow",
                    class: { bb: _vm.currentContentTab === "STOCK" },
                    on: {
                      click: function ($event) {
                        _vm.currentContentTab = "STOCK"
                      },
                    },
                  },
                  [
                    _c(
                      "i",
                      { staticClass: "material-icons light-gray f3 v-mid pa1" },
                      [_vm._v("search")]
                    ),
                    _vm._v("Stock Music\n        "),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "ph3 pv2 pointer grow",
                    class: { bb: _vm.currentContentTab === "LIB" },
                    on: {
                      click: function ($event) {
                        _vm.currentContentTab = "LIB"
                      },
                    },
                  },
                  [
                    _c(
                      "i",
                      { staticClass: "material-icons light-gray f3 v-mid pa1" },
                      [_vm._v("image")]
                    ),
                    _vm._v("My library\n        "),
                  ]
                ),
              ]),
              _c(
                "i",
                {
                  staticClass: "material-icons white f3 mr3",
                  on: { click: _vm.closeModal },
                },
                [_vm._v("close")]
              ),
            ]
          ),
          _c("div", [
            _c("div", { staticClass: "flex" }, [
              _c(
                "div",
                { staticClass: "w-30 mh3 mt3 mb2" },
                [
                  _c("div", { staticClass: "mt2 mb3 white" }, [
                    _c("i", { staticClass: "f6 material-icons white mr1" }, [
                      _vm._v("volume_up"),
                    ]),
                    _vm._v(
                      "Music Volume: " +
                        _vm._s(_vm.sliderValue) +
                        "%\n          "
                    ),
                  ]),
                  _c(
                    "vue-slider",
                    _vm._b(
                      {
                        ref: "slider",
                        staticStyle: { padding: "0 !important" },
                        attrs: { min: 0, max: 100, interval: 1 },
                        model: {
                          value: _vm.sliderValue,
                          callback: function ($$v) {
                            _vm.sliderValue = $$v
                          },
                          expression: "sliderValue",
                        },
                      },
                      "vue-slider",
                      _vm.options,
                      false
                    )
                  ),
                ],
                1
              ),
              _vm.currentContentTab === "STOCK"
                ? _c("div", { staticClass: "flex" }, [
                    _c("div", { staticClass: "mh3 mt4" }, [
                      _c(
                        "span",
                        {
                          staticClass: "chip mt2",
                          on: { click: _vm.toggleMusicFilters },
                        },
                        [_vm._m(0)]
                      ),
                    ]),
                    _vm.showFilters
                      ? _c("div", { staticClass: "flex gap-1 ph3" }, [
                          _c("div", { staticClass: "w-90" }, [
                            _c("div", { staticClass: "white mt3" }, [
                              _vm._v("Genre"),
                            ]),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.genre,
                                    expression: "genre",
                                  },
                                ],
                                staticClass:
                                  "bg-adori-very-light-gray ph2 white f6 w-160 br2 h2 ba b--adori-gray mt1",
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.genre = $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  },
                                },
                              },
                              _vm._l(_vm.genreList, function (item, i) {
                                return _c(
                                  "option",
                                  { key: i, domProps: { value: item.value } },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(item.name) +
                                        "\n                "
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]),
                          _c("div", { staticClass: "w-90" }, [
                            _c("div", { staticClass: "white mt3" }, [
                              _vm._v("Mood"),
                            ]),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.mood,
                                    expression: "mood",
                                  },
                                ],
                                staticClass:
                                  "bg-adori-very-light-gray ph2 white f6 w-160 br2 h2 ba b--adori-gray mt1",
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.mood = $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  },
                                },
                              },
                              _vm._l(_vm.moodList, function (item, i) {
                                return _c(
                                  "option",
                                  { key: i, domProps: { value: item.value } },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(item.name) +
                                        "\n                "
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.sliderValue >= 50,
                    expression: "sliderValue >= 50",
                  },
                ],
                staticClass: "yellow mt2 mh3 f6",
              },
              [
                _vm._v(
                  "\n        Warning!! Volume over 50 may drown background speech audio, impacting clarity.\n      "
                ),
              ]
            ),
            _vm.currentContentTab == "LIB"
              ? _c(
                  "div",
                  {
                    staticClass: "ph3",
                    on: {
                      dragover: function ($event) {
                        $event.preventDefault()
                      },
                      drop: function ($event) {
                        $event.preventDefault()
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        class: { "dark-fill": _vm.bgDragColor },
                        on: {
                          dragover: function () {
                            return (_vm.bgDragColor = true)
                          },
                          dragleave: function () {
                            return (_vm.bgDragColor = false)
                          },
                          click: _vm.audioUploader,
                          drop: function ($event) {
                            return _vm.dropImageFiles($event)
                          },
                        },
                      },
                      [
                        _c("label", { staticClass: "upload" }, [
                          _vm.uploadingAudio
                            ? _c("div", [
                                _c("img", {
                                  staticClass: "h70",
                                  attrs: {
                                    src: require("@/assets/spinner.svg"),
                                  },
                                }),
                                _vm._m(1),
                              ])
                            : _c("div", [
                                _c("img", {
                                  staticClass: "gallery",
                                  attrs: {
                                    width: "24px",
                                    height: "24px",
                                    alt: "placeholder",
                                    src: require("@/assets/audiogram/music-upload.png"),
                                  },
                                }),
                                _vm._m(2),
                                _c("span", [_vm._v(" Supports: Mp3, wav")]),
                              ]),
                        ]),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
            _vm.isLibMusicLoading || _vm.isStockMusicLoading
              ? _c("div", { staticClass: "flex justify-center mt5" }, [
                  _vm._m(3),
                ])
              : _vm.musicList.length === 0
              ? _c(
                  "div",
                  {
                    staticClass:
                      "w-100 flex flex-column justify-center items-center",
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/imgae-search.svg"),
                        alt: "",
                        width: "250",
                        height: "250",
                      },
                    }),
                    _c("div", { staticClass: "f5 silver" }, [
                      _vm._v("Oops! No Music found.."),
                    ]),
                  ]
                )
              : _c("div", [
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex justify-between items-center silver ph3 mt3",
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "pagination-btn br-100 pa0",
                          class:
                            _vm.selectedRangeStart === 1
                              ? "bn bg-black-40"
                              : "ba b--adori-gray bg-dark-gray pointer dim",
                          attrs: { disabled: _vm.selectedRangeStart === 1 },
                          on: {
                            click: function ($event) {
                              return _vm.handleNavigation("prev")
                            },
                          },
                        },
                        [
                          _c(
                            "i",
                            {
                              staticClass: "material-icons light-gray f3 v-mid",
                            },
                            [_vm._v(" arrow_left ")]
                          ),
                        ]
                      ),
                      _c("p", { staticClass: "silver" }, [
                        _c("span", { staticClass: "light-gray" }, [
                          _vm._v(
                            _vm._s(_vm.selectedRangeStart) +
                              " - " +
                              _vm._s(_vm.selectedRangeEnd)
                          ),
                        ]),
                        _vm._v("\n             of total \n            "),
                        _c("span", { staticClass: "light-gray" }, [
                          _vm._v(
                            _vm._s(_vm.totalMusicCount.toLocaleString("en-US"))
                          ),
                        ]),
                        _vm._v("\n             musics\n          "),
                      ]),
                      _c(
                        "button",
                        {
                          staticClass: "pagination-btn br-100 pa0",
                          class:
                            _vm.selectedRangeEnd >= _vm.totalMusicCount
                              ? "bn bg-black-40"
                              : "ba b--adori-gray bg-dark-gray pointer dim",
                          attrs: {
                            disabled:
                              _vm.selectedRangeEnd >= _vm.totalMusicCount,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.handleNavigation("next")
                            },
                          },
                        },
                        [
                          _c(
                            "i",
                            {
                              staticClass: "material-icons light-gray f3 v-mid",
                            },
                            [_vm._v(" arrow_right ")]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "ph3 w-100 flex flex-wrap gap-1 pv3" },
                    _vm._l(_vm.musicList, function (music) {
                      return _c("MusicCard", {
                        key: music.id,
                        attrs: { music: music },
                        on: { selectMusic: _vm.handleSelectMusic },
                      })
                    }),
                    1
                  ),
                ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "chip-text truncate",
        staticStyle: { "max-width": "200px" },
      },
      [
        _c("i", { staticClass: "f6 material-icons white mr1" }, [
          _vm._v("tune"),
        ]),
        _vm._v("Filters"),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("span", [_vm._v("Uploading Music")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("strong", [_vm._v("Choose Music")]),
      _vm._v(" or drag it here"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "building-blocks mt5" }, [
      _c("div"),
      _c("div"),
      _c("div"),
      _c("div"),
      _c("div"),
      _c("div"),
      _c("div"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }