
























import { Component, Prop, Vue } from 'vue-property-decorator'
import ModalPlans from './ModalPlans.vue'
import { Getter } from 'vuex-class'
import { computed, SetupContext } from 'vue-demi'
import { useGetVideoClipsByTrackId } from '@/hooks/audio'
import { QueryClient, useQueryClient } from 'vue-query'
import get from 'lodash.get'
import ExportedItem from '../Audio/ExportedItem.vue'

@Component({
  components: {
    ModalPlans,
    ExportedItem,
  },
  setup(_, { root }: SetupContext) {
    const audioTrackId: any = computed(() => root.$route.params.id)
    const queryClient = useQueryClient()
    const { data: exportsData, isLoading: exportsLoading } = useGetVideoClipsByTrackId(audioTrackId)

    return {
      queryClient,
      exportsData,
      exportsLoading,
    }
  },
})
export default class ModalExports extends Vue {
  showPlans = false
  queryClient!: QueryClient
  exportsData!: any
  exportsLoading!: any

  get exportsList() {
    return get(this.exportsData, 'data', [])
  }
}
