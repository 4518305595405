





















































































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ModalAutomationType extends Vue {
  selectedMode = ''
  selectedOrientation = ''

  automate() {
    this.$emit('automate', {
      type: this.selectedMode,
      aiParams:
        this.selectedOrientation === 'square'
          ? {
              width: '512',
              height: '512',
              aspect_ratio: '1:1',
              orientation: 'squarish',
            }
          : this.selectedOrientation === 'landscape'
          ? {
              width: '896',
              height: '512',
              aspect_ratio: '7:4',
              orientation: 'landscape',
            }
          : {
              width: '512',
              height: '896',
              aspect_ratio: '4:7',
              orientation: 'portrait',
            },
    })
  }
}
