












































import { Component, Vue } from 'vue-property-decorator'
import mixpanel from 'mixpanel-browser'

// @ts-ignore
const webengage = window['webengage']

@Component({
  components: {},
})
export default class AIBlog extends Vue {
  handleMethod(method: string) {
    // Method can be Blog URL or TEXT
    mixpanel.track(method)
    webengage?.track('blog-option', { option: method })
    if (method === 'URL') {
      this.$gtag.event('blog-url', {
        event_category: 'User Interaction',
        event_label: 'Insert Blog Url',
      })
    } else if (method === 'Text') {
      this.$gtag.event('blog-text', {
        event_category: 'User Interaction',
        event_label: 'Copy paste text',
      })
    } else if (method === 'FILE') {
      this.$gtag.event('upload-file', {
        event_category: 'User Interaction',
        event_label: 'Upload pdf or text file',
      })
    } else if (method === 'IDEA') {
      this.$gtag.event('ai-text', {
        event_category: 'User Interaction',
        event_label: 'Idea to Video',
      })
    }
    if (localStorage.getItem('blogId')) {
      this.$router.push(`/home/blog/${localStorage.getItem('blogId')}?method=${method}`)
    } else this.$router.push(`/home/blog/new?method=${method}`)
  }
  handleVideo() {
    this.$router.push(`/home/podcasts/undefined`)
  }
}
