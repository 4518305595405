


































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import debounce from 'lodash.debounce'

@Component
export default class SceneBlock extends Vue {
  @Prop() scenes: any
  @Prop() scene: any
  @Prop() index: any
  @Prop() text: any

  sceneText = ''
  activeHover: boolean = false

  created() {
    this.sceneText = `${this.text}`
  }
  @Watch('text')
  handleScene() {
    this.sceneText = `${this.text}`
  }

  changeText(value: string) {
    this.$emit('updateScene', {
      ...this.scene,
      text: value,
    })
  }

  debouncedText = debounce((value: string) => {
    this.changeText(value)
  }, 600)

  moveScene(index: number, direction: 'up' | 'down') {
    this.$emit('moveScene', { index, direction })
  }
  handleAddScene() {
    this.$emit('addScene', this.index)
  }
  addBwScene() {
    this.$emit('addBwScene', this.index)
  }
  handleRemoveScene() {
    this.$emit('removeScene', this.index)
  }
  toggleAIPanel() {
    this.$emit('aiPanel', this.scene)
  }
  toggleBlogImage() {
    this.$emit('blogImages', this.scene)
  }
  handleRemoveSceneImage() {
    this.$emit('removeSceneImage', this.scene)
  }

  handleOver(e: any) {
    e.target.play()
  }
  handleOut(e: any) {
    e.target.pause()
  }
}
