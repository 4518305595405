var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "scene-content flex items-center",
      class: _vm.index !== 0 && "mt2",
    },
    [
      _c("div", { staticClass: "scene-controls" }, [
        _c(
          "button",
          {
            staticClass:
              "btn-control bn br1 flex items-center justify-center pointer",
            attrs: { disabled: _vm.index === 0 },
            on: {
              click: function ($event) {
                return _vm.moveScene(_vm.index, "up")
              },
            },
          },
          [
            _c("i", { staticClass: "material-icons white" }, [
              _vm._v("arrow_upward"),
            ]),
          ]
        ),
        _c(
          "button",
          {
            staticClass:
              "btn-control bn br1 flex items-center justify-center pointer",
            on: { click: _vm.handleRemoveScene },
          },
          [_c("i", { staticClass: "material-icons white" }, [_vm._v("delete")])]
        ),
        _c(
          "button",
          {
            staticClass:
              "btn-control bn br1 flex items-center justify-center pointer",
            attrs: { disabled: _vm.index === _vm.scenes.length - 1 },
            on: {
              click: function ($event) {
                return _vm.moveScene(_vm.index, "down")
              },
            },
          },
          [
            _c("i", { staticClass: "material-icons white" }, [
              _vm._v("arrow_downward"),
            ]),
          ]
        ),
      ]),
      _c("div", { staticClass: "scene-text relative hide-child" }, [
        _c("div", { staticClass: "label" }, [
          _vm._v("scene " + _vm._s(_vm.index + 1)),
        ]),
        _c("div", { staticClass: "input-wrapper" }, [
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.scene.text,
                expression: "scene.text",
              },
            ],
            staticClass: "input-textarea scrollbar",
            attrs: { placeholder: "Enter scene description..." },
            domProps: { value: _vm.scene.text },
            on: {
              input: [
                function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.scene, "text", $event.target.value)
                },
                function ($event) {
                  return _vm.debouncedText($event.target.value)
                },
              ],
            },
          }),
          _c(
            "div",
            {
              staticClass: "btn-ai pointer",
              attrs: { title: "Enhance with AI" },
              on: { click: _vm.toggleAIPanel },
            },
            [
              _c("img", {
                staticClass: "ai-icon",
                attrs: { src: require("@/assets/ai.svg") },
              }),
            ]
          ),
        ]),
        _vm.index !== _vm.scenes.length - 1
          ? _c(
              "div",
              {
                staticClass: "absolute flex justify-center w-100 child",
                staticStyle: { bottom: "-30px" },
              },
              [
                _c(
                  "button",
                  {
                    staticClass:
                      "btn-control bn br1 flex items-center justify-center pointer",
                    on: { click: _vm.addBwScene },
                  },
                  [
                    _c("i", { staticClass: "material-icons white" }, [
                      _vm._v("add"),
                    ]),
                  ]
                ),
              ]
            )
          : _vm._e(),
      ]),
      _c(
        "div",
        { staticClass: "scene-thumbnail flex justify-center items-center" },
        [
          _vm.scene.image
            ? _c("div", { staticClass: "thumbnail-wrapper relative" }, [
                _vm.scene.image
                  ? _c("img", {
                      staticClass: "br2 pointer",
                      staticStyle: { width: "100%", "max-height": "9rem" },
                      attrs: {
                        src: _vm.scene.image,
                        alt: "Scene " + (_vm.index + 1),
                      },
                      on: { click: _vm.toggleBlogImage },
                    })
                  : _vm._e(),
                _c(
                  "button",
                  {
                    staticClass: "btn-remove",
                    on: { click: _vm.handleRemoveSceneImage },
                  },
                  [
                    _c("i", { staticClass: "material-icons" }, [
                      _vm._v("close"),
                    ]),
                  ]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn-view",
                    on: {
                      click: function ($event) {
                        return _vm.$emit("viewImages", _vm.scene.image)
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "material-icons f5" }, [
                      _vm._v("visibility"),
                    ]),
                  ]
                ),
              ])
            : _vm.scene.video
            ? _c("div", { staticClass: "thumbnail-wrapper relative" }, [
                _c(
                  "video",
                  {
                    staticClass: "br2 pointer",
                    staticStyle: { width: "100%", "max-height": "9rem" },
                    attrs: {
                      id: "blog_scenes_" + _vm.index + "_tour_step_7",
                      poster: _vm.scene.video.thumbNail,
                      muted: "",
                    },
                    domProps: { muted: true },
                    on: {
                      mouseover: _vm.handleOver,
                      mouseout: _vm.handleOut,
                      click: _vm.toggleBlogImage,
                    },
                  },
                  [
                    _c("source", {
                      attrs: {
                        src:
                          _vm.scene.video.previewUrl ||
                          _vm.scene.video.originalUrl,
                        type: "video/mp4",
                      },
                    }),
                    _vm._v(
                      "\n        Your browser does not support the video tag.\n      "
                    ),
                  ]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn-remove",
                    on: { click: _vm.handleRemoveSceneImage },
                  },
                  [
                    _c("i", { staticClass: "material-icons" }, [
                      _vm._v("close"),
                    ]),
                  ]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn-view",
                    on: {
                      click: function ($event) {
                        return _vm.$emit("viewVideos", _vm.scene.video)
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "material-icons f5" }, [
                      _vm._v("visibility"),
                    ]),
                  ]
                ),
              ])
            : _c(
                "div",
                {
                  staticClass: "div-plus",
                  on: {
                    mouseenter: function ($event) {
                      _vm.activeHover = true
                    },
                    mouseleave: function ($event) {
                      _vm.activeHover = false
                    },
                    click: _vm.toggleBlogImage,
                  },
                },
                [
                  _c("div", { staticClass: "pointer" }, [
                    _vm.activeHover
                      ? _c("div", { staticClass: "overlay" }, [
                          _c(
                            "i",
                            {
                              staticClass:
                                "material-icons white v-mid pointer ml3 o-icon",
                            },
                            [_vm._v(" add ")]
                          ),
                        ])
                      : _vm._e(),
                    _c(
                      "svg",
                      {
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          viewBox: "0 0 24 24",
                        },
                      },
                      [
                        _c("rect", {
                          attrs: { width: "24", height: "24", fill: "#2A2A2A" },
                        }),
                        _c("path", {
                          attrs: {
                            d: "M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z",
                            stroke: "#FFFFFF",
                            fill: "none",
                            "stroke-width": "1.5",
                          },
                        }),
                        _c("path", {
                          attrs: {
                            d: "M8.5 10C9.32843 10 10 9.32843 10 8.5C10 7.67157 9.32843 7 8.5 7C7.67157 7 7 7.67157 7 8.5C7 9.32843 7.67157 10 8.5 10Z",
                            fill: "#FFFFFF",
                          },
                        }),
                        _c("path", {
                          attrs: {
                            d: "M21 15L16 10L5 21H19C20.1046 21 21 20.1046 21 19V15Z",
                            fill: "#FFFFFF",
                            "fill-opacity": "0.5",
                          },
                        }),
                        _c("path", {
                          attrs: {
                            d: "M3 19L9 13L13 17L16 14L21 19",
                            stroke: "#FFFFFF",
                            "stroke-width": "1.5",
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round",
                          },
                        }),
                      ]
                    ),
                  ]),
                ]
              ),
          _c("div", { staticClass: "orientation-text" }, [
            _vm._v("\n      " + _vm._s(_vm.scene.orientation) + "\n    "),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }