export const SUBSCRIPTION_STATUS = {
  trial: 'TRIALING',
  active: 'ACTIVE',
  incomplete: 'INCOMPLETE',
  incompleteExp: 'INCOMPLETE_EXPIRED',
  pastDue: 'PAST_DUE',
  canceled: 'CANCELED',
  unPaid: 'UNPAID',
}

export enum usageMapping {
  generated_videos = `No of video Exports`,
  episode_count = `No of video Previews`,
  //   transcription = 'Transcription (For subtitles/ suggested tags) in minutes',
  video_ai = 'AI text generation',
  video_dalle = 'AI image generation',
  published_minutes = 'Total video generation in minutes',
}
