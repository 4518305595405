



















































































































































































































































































































































































































































































































































































































import TheMainPage from '@/components/Common/TheMainPage.vue'
import TheHeader from '@/components/Common/TheHeader.vue'
import TheSideNavigation from '@/components/Common/TheSideNavigation.vue'
import TheTitle from '@/components/Common/TheTitle.vue'
import SceneBlock from '@/components/AI/SceneBlock.vue'
import BaseRateLimitPopup from '@/components/Base/BaseRateLimitPopup.vue'
import BaseSampleBlogPreview from '@/components/Base/BaseSampleBlogPreview.vue'
import { useGetParsedBlog } from '@/hooks/openAi'
import { computed, ref, watch, reactive, SetupContext } from '@vue/composition-api'
import { QueryClient, useQueryClient } from 'vue-query'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import get from 'lodash.get'
import { Action, Getter } from 'vuex-class'
import AIPanel from '@/components/AI/Panes/AIPanel.vue'
import AdoriServiceV6 from '@/services/adori_v6'
import { uploadStatus } from '@/components/Publish/publish'
import ModalLoader from '@/components/Modals/ModalLoader.vue'
import ModalCommonLoader from '@/components/Modals/ModalCommonLoader.vue'
import ModalDemoLoader from '@/components/Modals/ModalDemoLoader.vue'
import ModalPreviewImages from '@/components/Modals/ModalPreviewImages.vue'
import ModalPreviewVideos from '@/components/Modals/ModalPreviewVideos.vue'
import ModalContent from '@/components/Modals/ModalContent.vue'
import ModalMusic from '@/components/Modals/ModalMusic.vue'
import ModalAutomationType from '@/components/Modals/ModalAutomationType.vue'
import AdoriService from '@/services/adori'
import { audioQueryKeys } from '@/hooks/audio'
import { formatOpenAiCaption, formatOpenAiKeywords } from '@/utils/misc'
import TextEditor from '@/components/AI/TextEditor.vue'
import { blogList } from '@/components/AI/setting'
import VueSlider from 'vue-slider-component'
import { sliderOPtions } from '@/components/Subtitle/constants'

import * as Sentry from '@sentry/vue'
import { blogDraftKeys, useGetOneDraft, useSaveDrafts, useUpdateDraft } from '@/hooks/blogDraft'
import mixpanel from 'mixpanel-browser'
import { useGetOpenAiContext } from '@/hooks/openAi'
import { voiceToneList, topicList, autoPickSettingList, videoResolutionList } from '@/components/AI/setting'
import ModalPlans from '@/components/Modals/ModalPlans.vue'
import { Crisp } from 'crisp-sdk-web'
import { billingQueryKeys } from '@/hooks/billing'

// @ts-ignore
const webengage = window['webengage']

@Component({
  components: {
    TheMainPage,
    TheHeader,
    TheSideNavigation,
    TheTitle,
    SceneBlock,
    AIPanel,
    ModalLoader,
    ModalCommonLoader,
    ModalDemoLoader,
    ModalAutomationType,
    BaseRateLimitPopup,
    BaseSampleBlogPreview,
    TextEditor,
    ModalPreviewImages,
    ModalPreviewVideos,
    ModalContent,
    ModalMusic,
    VueSlider,
    ModalPlans,
  },
  setup(_, { root }: SetupContext) {
    const openAiRes = ref('')
    const finalDraft = ref('')
    const openAiQuery = ref('')
    const isUnsavedChanges = ref(false)
    const blogUrl = ref('')
    const queryClient = useQueryClient()
    const isBlogParseEnabled = computed(() => !!blogUrl.value)
    const parsingMethod = computed(() => root.$route.query.method)
    const draftId = computed(() => root.$route.params.id)
    const isDraftEnabled = computed(() => root.$route.params.id !== 'new')
    const openAiParams = reactive({
      topic: openAiQuery,
      max_tokens: 4000,
      temperature: 0.7,
    })
    const isEnabled = computed(() => !!openAiQuery.value)
    const { data: openAiData, isFetching: isOpenAiFetching } = useGetOpenAiContext(openAiParams, {
      enabled: isEnabled,
      onSuccess() {
        isOpenAiFetching.value = false
      },
      onError() {
        isOpenAiFetching.value = false
      },
    })

    watch(openAiData, (curVal) => {
      openAiRes.value = curVal
    })

    const { data: parsedBlog, isFetching: isParseFetching } = useGetParsedBlog(
      blogUrl,
      { enabled: isBlogParseEnabled },
      (data: any) => {
        root.$store.commit('updateBlogSceneList', get(data, 'result.scenes', []))
        root.$store.commit('setParsedBlog', get(data, 'result', {}))
      }
    )

    const { data: draftData, isLoading: isDraftFetching } = useGetOneDraft(
      draftId,
      { enabled: isDraftEnabled },
      (data: any) => {
        root.$store.commit('clearParsedBlog')
        root.$store.commit('updateBlogSceneList', get(data, 'blogData.scenes', []))
        root.$store.commit('setParsedBlog', get(data, 'blogData', {}))
        localStorage.setItem('blogId', draftId.value)
      }
    )

    const { mutateAsync: saveBlogDraft, isLoading: isSaveDraftLoading } = useSaveDrafts((data: any) => {
      isUnsavedChanges.value = false
      queryClient.invalidateQueries(blogDraftKeys.GET_DRAFT)
      queryClient.invalidateQueries(blogDraftKeys.GET_ONE_DRAFT)
      localStorage.setItem('blogId', data.id)
      root.$router.replace(`/home/blog/${data.id}?method=URL`)
    })

    const { mutate: updateDraft, isLoading: isUpdateDraftLoading } = useUpdateDraft((data: any) => {
      isUnsavedChanges.value = false
      queryClient.invalidateQueries(blogDraftKeys.GET_DRAFT)
      queryClient.invalidateQueries([blogDraftKeys.GET_ONE_DRAFT])
    })

    return {
      openAiRes,
      finalDraft,
      openAiQuery,
      openAiData,
      isOpenAiFetching,
      isUnsavedChanges,
      queryClient,
      blogUrl,
      parsedBlog,
      isParseFetching,
      parsingMethod,
      isDraftFetching,
      draftData,
      saveBlogDraft,
      isSaveDraftLoading,
      updateDraft,
      isUpdateDraftLoading,
    }
  },
})
export default class ViewAiTextToVideo extends Vue {
  @Getter blogSceneList!: any
  @Getter parsedBlogResponse!: any
  @Getter networkId!: any
  @Getter audio!: any
  @Getter youtubeTourShown!: string[]
  @Getter selectedFile!: any
  @Getter blogHistory!: any
  @Getter currentHistoryIndex!: any
  @Getter networkType!: string

  @Action getAudioUids!: any
  @Action setShowPane!: any
  @Action addAudioUid!: any
  @Action generateSubtitles!: Function
  @Action saveAudioTrackSubtitle!: Function
  @Action uploadTourStatus!: any
  @Action closeModal!: any

  @Prop() aiScriptList: any

  voiceTone: string = 'Default'
  selectedAutoPickSetting: string = 'videos'
  selectedOrientation = 'landscape'
  ideaDescription = ''

  showAutomationTypeModal = false

  blogUrlInp = ''
  bgDragColor = false
  dropped = false
  blogURL = ''
  selectedTextFile: any = null

  blogUrl!: string
  showRateLimitPopup = false
  showSampleBlogPreview = false
  rateLimitSecondsLeft = 0

  $shepherd: any
  parsedBlog!: any
  parsingMethod!: any
  isParseFetching!: boolean
  draftData!: any
  isDraftFetching!: boolean
  saveBlogDraft!: any
  isSaveDraftLoading!: boolean
  updateDraft!: any
  isUpdateDraftLoading!: boolean
  queryClient!: QueryClient

  isModalLoader = false
  isModalDemoLoader = false
  isConverting = false
  btnCreateText = 'Create Video'
  selectedContext = 'MAIN_BLOG_IMAGE'
  selectedScene = {}
  isSceneImgFetching = false
  btnClicked = false
  totalTags: any = []
  currentTags: any = []
  tourShown = false

  isUnsavedChanges!: boolean

  interval: any
  openAiRes!: any
  finalDraft!: any
  topic = ''
  openAiQuery!: any
  openAiData!: any
  isOpenAiFetching!: any
  showAIPanel: boolean = false

  showImagePreview = false
  showVideoPreview = false
  image = ''
  video = null

  showContentModal = false
  showMusicModal = false
  isSowVideo = true

  selectedScriptLength = {
    text: '1 minute',
    words: 150,
    isLocked: false,
  }
  selectedScriptLanguage = 'English'
  showPlans = false
  isSummarize = true

  toggleCheckBox() {
    this.isSummarize = !this.isSummarize
  }

  viewImages(image: string) {
    this.showImagePreview = true
    this.image = image
  }
  viewVideos(video: any) {
    this.showVideoPreview = true
    this.video = video
  }

  previewClose() {
    this.showImagePreview = false
    // setTimeout(() => {
    //   this.setShowPane(true)
    // }, 100)
  }

  moveToFinal() {
    this.finalDraft += `${this.openAiRes}\n\n`
    this.openAiRes = ''
    this.isUnsavedChanges = true
  }

  discardFinalScript() {
    this.$store.dispatch('showConfirm', {
      title: 'Discard Draft',
      description: 'Are you sure you want to discard the content?',
      yesButtonText: 'Yes',
      onConfirm: async () => {
        this.finalDraft = ''
        this.closeModal()
      },
    })
  }

  async sendQuery() {
    this.isOpenAiFetching = true
    try {
      const payload = {
        topic: this.topic,
        description: this.ideaDescription,
        length: this.selectedScriptLength.words,
        tone: this.voiceTone,
        language: this.selectedScriptLanguage,
      }
      const res = await AdoriServiceV6.ideaToScript(this.networkId, payload)
      this.$store.commit('updateBlogSceneList', get(res, 'blogData.scenes', []))
      this.$store.commit('setParsedBlog', { ...get(res, 'blogData', {}), keywords: [] })
      this.updateHistory()
      await this.saveDraft()
      this.isOpenAiFetching = false
    } catch (error) {
      this.isOpenAiFetching = false
    }
    if (['ai', 'images', 'videos'].includes(this.selectedAutoPickSetting)) {
      await this.automateAITags({
        type: this.selectedAutoPickSetting,
        aiParams:
          this.selectedOrientation === 'square'
            ? {
                width: '512',
                height: '512',
                aspect_ratio: '1:1',
                orientation: 'squarish',
              }
            : this.selectedOrientation === 'landscape'
            ? {
                width: '896',
                height: '512',
                aspect_ratio: '7:4',
                orientation: 'landscape',
              }
            : {
                width: '512',
                height: '896',
                aspect_ratio: '4:7',
                orientation: 'portrait',
              },
      })
    } else {
      await this.automateSceneImages()
    }
  }
  get btnGenerateText() {
    return this.isOpenAiFetching ? 'Generating' : 'Generate Script'
  }

  get scriptLengthMenu() {
    let menu = [
      {
        name: `<div>
                  <span class="f6">30 seconds</span>
                </div>`,
        onClick: () => {
          this.selectedScriptLength = {
            text: '30 seconds',
            words: 50,
            isLocked: false,
          }
        },
      },
      {
        name: `<div>
                  <span class="f6">1 minute</span>
                </div>`,
        onClick: () => {
          this.selectedScriptLength = {
            text: '1 minute',
            words: 150,
            isLocked: false,
          }
        },
      },
      {
        name: `<div>
                 ${
                   this.networkType == 'VIDEO_FREE'
                     ? `<span class="bn br3 f6 mr2 bg-adori-red" style="border-radius: 7px; padding: 2px 6px">Pro</span>`
                     : ''
                 } 
                  <span class="f6">5 minute</span>
                </div>`,
        onClick: () => {
          this.selectedScriptLength = {
            text: '5 minute',
            words: 800,
            isLocked: this.networkType == 'VIDEO_FREE',
          }
        },
      },
      {
        name: `<div style="width: 250px;">
                 ${
                   this.networkType == 'VIDEO_FREE'
                     ? `<span class="bn br3 f6 mr2 bg-adori-red" style="border-radius: 7px; padding: 2px 6px">Pro</span>`
                     : ''
                 } 
                  <span class="f6">10 minutes</span>
                </div>`,
        onClick: () => {
          this.selectedScriptLength = {
            text: '10 minutes',
            words: 1500,
            isLocked: this.networkType == 'VIDEO_FREE',
          }
        },
      },
      {
        name: `<div style="width: 250px;">
                 ${
                   ['VIDEO_FREE', 'VIDEO_BASIC', 'ADORI_BASIC_MONTHLY', 'ADORI_BASIC_YEARLY', ,].includes(
                     this.networkType
                   )
                     ? `<span class="bn br3 f6 mr2 bg-adori-red" style="border-radius: 7px; padding: 2px 6px">Pro</span>`
                     : ''
                 } 
                  <span class="f6">30 minutes</span>
                </div>`,
        onClick: () => {
          this.selectedScriptLength = {
            text: '30 minutes',
            words: 3000,
            isLocked: ['VIDEO_FREE', 'VIDEO_BASIC', 'ADORI_BASIC_MONTHLY', 'ADORI_BASIC_YEARLY'].includes(
              this.networkType
            ),
          }
        },
      },
    ]

    return menu
  }
  get languagesMenu() {
    let menu = [
      {
        name: 'English',
        onClick: () => {
          this.selectedScriptLanguage = 'English'
        },
      },
      {
        name: 'Spanish',
        onClick: () => {
          this.selectedScriptLanguage = 'Spanish'
        },
      },
      {
        name: 'Portuguese',
        onClick: () => {
          this.selectedScriptLanguage = 'Portuguese'
        },
      },
      {
        name: 'Polish',
        onClick: () => {
          this.selectedScriptLanguage = 'Polish'
        },
      },
      {
        name: 'Italian',
        onClick: () => {
          this.selectedScriptLanguage = 'Italian'
        },
      },
      {
        name: 'French',
        onClick: () => {
          this.selectedScriptLanguage = 'French'
        },
      },
      {
        name: 'German',
        onClick: () => {
          this.selectedScriptLanguage = 'German'
        },
      },
      {
        name: 'Hindi',
        onClick: () => {
          this.selectedScriptLanguage = 'Hindi'
        },
      },
    ]

    return menu
  }

  get isPremium() {
    return [
      'VIDEO_PRO',
      'VIDEO_BASIC',
      'ADORI_BASIC_MONTHLY',
      'ADORI_BASIC_YEARLY',
      'ADORI_PREMIUM_MONTHLY',
      'ADORI_PREMIUM_YEARLY',
      'ADORI_BUSINESS_MONTHLY',
      'ADORI_BUSINESS_YEARLY',
    ].includes(this.networkType)
  }

  get estimatedDuration() {
    let full_text = ''
    for (const scene of this.blogSceneList) {
      full_text += scene.text.toString()
    }
    const characters = full_text.length
    const words = full_text.trim().split(/\s+/).length
    return Math.floor(0.03928 * characters + 0.12958 * words - 0.39964)
  }

  get formattedDuration() {
    const minutes = Math.floor(this.estimatedDuration / 60)
    const seconds = this.estimatedDuration % 60
    let result = ''

    if (minutes > 0) {
      result += `${minutes}m`
    }
    if (seconds > 0) {
      result += (minutes > 0 ? ' ' : '') + `${seconds}s`
    }
    return result || '0secs'
  }

  get exportLimit() {
    if (this.networkType == 'VIDEO_FREE') {
      return 1
    } else if (['ADORI_BASIC_MONTHLY', 'ADORI_BASIC_YEARLY', 'VIDEO_BASIC'].includes(this.networkType)) {
      return 15
    } else {
      return 30
    }
  }

  get exportLimitExceeded() {
    const estimatedMinutes = this.estimatedDuration / 60
    return estimatedMinutes > this.exportLimit ? true : false
  }
  get estimatedIdeaDuration() {
    const characters = this.finalDraft.length
    const words = this.finalDraft.trim().split(/\s+/).length
    return Math.floor(0.03928 * characters + 0.12958 * words - 0.39964)
  }

  get formattedIdeaDuration() {
    const minutes = Math.floor(this.estimatedIdeaDuration / 60)
    const seconds = this.estimatedIdeaDuration % 60
    let result = ''

    if (minutes > 0) {
      result += `${minutes}m`
    }
    if (seconds > 0) {
      result += (minutes > 0 ? ' ' : '') + `${seconds}s`
    }
    return result || '0secs'
  }

  get options() {
    return { ...sliderOPtions, piecewise: true, tooltip: 'hover', tooltipDir: 'bottom' }
  }

  get selectedMusic() {
    return !!this.musicData ? this.musicData.music.title : 'No Music'
  }

  get topicListData() {
    return topicList
  }

  get autoPickData() {
    return autoPickSettingList
  }

  get videoResolutionData() {
    return videoResolutionList
  }

  get voiceToneListData() {
    return voiceToneList
  }

  get selectedFileName() {
    return this.selectedTextFile && this.selectedTextFile.name
  }

  get selectedFileSize() {
    if (this.selectedTextFile) {
      let size = this.selectedTextFile.size
      let fSExt = ['Bytes', 'KB', 'MB', 'GB']
      let i = 0
      while (size > 900) {
        size /= 1024
        i++
      }
      return Math.round(size * 100) / 100 + ' ' + fSExt[i]
    }
  }

  get hasAudioUploadId() {
    return !!this.$store.getters.audioUploadId
  }
  get isUploadingAudio() {
    return this.hasAudioUploadId && this.$store.getters.audioUploadProgress !== 1
  }

  get uploadProgress() {
    return Math.round(this.$store.getters.audioUploadProgress * 100)
  }

  get blogListData() {
    return blogList
  }

  get musicData() {
    return get(this.parsedBlogResponse, 'musicData', null)
  }
  get parsedBlogScenes() {
    return get(this.parsedBlogResponse, 'scenes', [])
  }

  get thumbnailImage() {
    return get(this.parsedBlogResponse, 'topImage', '')
  }
  get title() {
    return get(this.parsedBlogResponse, 'title', '')
  }
  get description() {
    return get(this.parsedBlogResponse, 'description', '')
  }
  get keywords() {
    return get(this.parsedBlogResponse, 'keywords', []).join(',')
  }
  get keywordArray() {
    return this.keywords
      .split(',')
      .map((item: string) => item.trim()) // Trim whitespace from each item
      .filter((item: string) => item !== '') // Remove empty strings
  }
  get cleanText() {
    const text = get(this.parsedBlogResponse, 'clean_text', '')
    return text.length < 1000 || text.length === 0 ? text : text.substring(0, 1000)
  }
  get tagEditStoreData() {
    return this.$store.state.modal.tagEdit
  }

  get aiData() {
    if (this.selectedContext == 'TOPIC_RECOMMENDATION') return this.topicListData

    let data = ['BLOG_TEXT', 'SCENE_BLOG_IMAGE', 'MAIN_BLOG_IMAGE'].includes(this.selectedContext)
      ? this.parsedBlogResponse.blogImages || []
      : []
    ;['AI_TITLE', 'AI_KEYWORDS', 'AI_DESCRIPTION'].includes(this.selectedContext) &&
      (data = {
        title: this.title === 'Untitled' ? this.cleanText : this.title,
        description: this.cleanText,
      })
    return data
  }

  created() {
    if (this.draftData) {
      this.$store.commit('clearParsedBlog')
      this.$store.commit('updateBlogSceneList', get(this.draftData, 'blogData.scenes', []))
      this.$store.commit('setParsedBlog', get(this.draftData, 'blogData', {}))
      localStorage.setItem('blogId', this.draftData.id)
      this.updateHistory()
    }
    // Pause auto save for now
    // this.interval = setInterval(() => {
    //   if (this.isUnsavedChanges && this.draftData) {
    //     this.saveDraft()
    //   }
    // }, 30000)
  }

  selectVoice() {
    if (this.thumbnailImage == 'https://storage.googleapis.com/dev_audio_adorilabs/samples/placeholder.png') {
      alert('Please choose a thumbnail image')
      return
    }

    if (this.title == 'Untitled') {
      alert("Please provide an appropriate title; 'Untitled' is not allowed.")
      return
    }
    if (this.isUnsavedChanges) {
      this.saveDraft()
    }
    this.isModalLoader = true
  }

  async saveDraft() {
    const parsedBlogResponse = { ...this.parsedBlogResponse, scenes: this.blogSceneList }
    const data: any = {
      title: parsedBlogResponse.title,
      topImage: parsedBlogResponse.topImage,
      blogData: parsedBlogResponse,
    }
    if (this.blogUrlInp) {
      data['blogUrl'] = this.blogUrlInp
    }
    if (this.$route.params.id === 'new') {
      await this.saveBlogDraft(data)
    } else {
      data['id'] = localStorage.getItem('blogId')
      this.updateDraft(data)
    }
  }

  validFileType(file: any) {
    const filename = file.name
    const fileType = filename.substring(filename.lastIndexOf('.') + 1, filename.length) || filename

    if (fileType === 'pdf' || fileType === 'txt') return true
    return false
  }

  dropFile(event: any) {
    this.bgDragColor = false
    if (!this.validFileType(event.dataTransfer.files[0])) {
      this.$store.dispatch('pushNotification', {
        text: 'Please upload only pdf or txt file',
        type: 'WARNING',
      })
      return
    }

    this.$store.commit('setSelectedFile', event.dataTransfer.files[0])
  }

  handleUploadFile() {
    this.$store.dispatch('uploadNewEpisodeUsingText')
  }

  openBlog(url: any) {
    window.open(url, '_blank')
  }

  handleVoiceDetails(voiceDetails: any) {
    this.uploadScript(voiceDetails)
  }

  generateReport() {
    let textContent = `User NetworkId : ${this.networkId}\n`
    textContent += `User Name : ${this.$store.getters.name}\n`
    textContent += `User Email : ${this.$store.getters.email}\n\n\n\n\n\n`

    textContent += `parsedBlogResponse : ${JSON.stringify(this.parsedBlogResponse)}\n\n\n\n`
    textContent += `blogSceneList : ${JSON.stringify(this.blogSceneList)}`
    const blob = new Blob([textContent], { type: 'text/plain' })
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.download = 'report.txt'
    link.click()
    window.URL.revokeObjectURL(url)
  }

  async uploadScript(voiceDetails: any) {
    try {
      if (this.isUnsavedChanges) {
        await this.saveDraft()
      }
      this.isConverting = true
      this.btnCreateText = 'Converting to Audio ....'

      const sceneImages: any = []
      let cleanText = ''
      let startWordCount = 1
      let endWordCount = 0

      for (const scene of this.blogSceneList) {
        const text = scene.text.toString().trim().replace(/"/g, '') // Remove leading/trailing spaces and quotation marks
        const words = text.split(/\s+/) // Split text into words

        endWordCount = startWordCount + words.length - 1 // Calculate end word count

        if (scene.image && scene.image.length < 2080) {
          try {
            await new Promise<void>((resolve, reject) => {
              const img = new Image()
              img.src = scene.image

              img.onload = () => {
                sceneImages.push({
                  url: scene.image,
                  startWordCount,
                  endWordCount,
                  type: 'image',
                })
                resolve()
              }

              img.onerror = () => {
                resolve()
              }
            })
          } catch (error) {
            console.log(`Error loading image: ${scene.image}`)
          }
        }
        if (scene.video) {
          sceneImages.push({
            url: scene.video.originalUrl,
            thumbNail: scene.video.thumbNail,
            startWordCount,
            endWordCount,
            type: 'video',
          })
        }

        cleanText += '\n' + text + '.'
        startWordCount = endWordCount + 1 // Update startWordCount for the next scene
      }

      const encoder = new TextEncoder()

      let image: any
      console.log(cleanText)
      const encodedText = encoder.encode(cleanText)
      console.log(encodedText)
      const blob = new Blob([encodedText], { type: 'text/plain;charset=utf-8' })
      console.log(blob)
      const file = new File([blob], `${crypto.randomUUID()}.txt`, { type: 'text/plain;charset=utf-8' })
      console.log(file)

      let thumbnail = this.thumbnailImage
        ? this.thumbnailImage
        : 'https://storage.cloud.google.com/production_image_adorilabs/static/pod-placeholder.jpg'

      try {
        const loadImagePromise = new Promise<void>((resolve) => {
          const img = new Image()
          img.src = thumbnail

          img.onerror = () => {
            thumbnail = 'https://storage.cloud.google.com/production_image_adorilabs/static/pod-placeholder.jpg'
            resolve()
          }

          img.onload = () => {
            resolve()
          }
        })

        await loadImagePromise
      } catch (error) {
        console.log('Error loading image')
      }

      try {
        image = await AdoriService.uploadImage(this.networkId, {
          url: thumbnail,
        })
      } catch (error) {}

      if (!image) {
        image = await AdoriService.uploadImage(this.networkId, {
          url: 'https://storage.cloud.google.com/production_image_adorilabs/static/pod-placeholder.jpg',
        })
      }

      let selectedEpisode: any = {
        name: this.title,
        description: this.description,
        imageId: image.id,
        keywords: this.keywordArray,
        explicitContent: false,
        isRss: false,
        audioUrl: '',
        imageUrl: image.originalUrl,
        uploadId: null,
        trackSource: 'AI_GENERATED',
        language: 'en-US',
      }

      !!this.musicData && (selectedEpisode['musicVolume'] = this.musicData.volume)
      !!this.musicData && (selectedEpisode['musicId'] = this.musicData.music.id)
      !!this.musicData && (selectedEpisode['musicSource'] = this.musicData.music.source)

      selectedEpisode = { ...selectedEpisode, ...voiceDetails }
      const payload = {
        file,
        track_info: selectedEpisode,
      }
      console.log(payload)

      this.$gtag.event('blog-to-audio', { info: selectedEpisode })
      mixpanel.track('Create Video')
      webengage?.track('blog-to-audio', { data: payload })
      const taskId = await this.$store.dispatch('createAudioTrackFromFile', payload)
      const convertToAudio = async () => {
        const res: any = await AdoriService.getTTSUploadStatus(this.networkId, taskId)
        if (res.status === uploadStatus.FINISHED) {
          this.queryClient.invalidateQueries(billingQueryKeys.BILLING_USAGE)
          this.$gtag.event('create-video', {
            event_category: 'User Interaction',
            event_label: 'Create video from scenes',
          })
          await this.getAudioUids({
            clear: true,
          })
          const audioTrack = this.audio(res.audioUid)
          this.$store.dispatch('clearAudioUploader')
          //   this.addAudioUid(res.audioUid)
          await this.$store.dispatch('addAudioUid', res.audioUid)

          // Auto-generate subtitle after TTS
          try {
            await this.generateSubtitles({ trackUid: res.audioUid })
          } catch (error) {}

          const payload = {
            subtitleStyleId: null,
            template: 'BOXED',
            animation: 'NONE',
            isEdited: true,
            params: {
              network_id: this.networkId,
              track_uid: res.audioUid,
            },
          }
          await this.saveAudioTrackSubtitle(payload)
          this.totalTags = sceneImages
          this.generateImages(audioTrack, sceneImages)
          return
        } else if (res.status === uploadStatus.FAILED) {
          this.$store.dispatch('pushNotification', {
            text: res.errorMessage || 'Convert Failed',
            type: 'ERROR',
          })
          this.isConverting = false
          this.btnCreateText = 'Create Video'
          return
        } else {
          setTimeout(convertToAudio, 3000)
        }
      }
      await convertToAudio()
    } catch (error: any) {
      if (error.message.includes('Limit exhausted')) {
        this.$store.commit('setCurrentModal', 'plans')
      }

      Sentry.captureException(error)
      this.$store.dispatch('pushNotification', {
        text: 'Convert Failed',
        type: 'ERROR',
      })
      this.isConverting = false
      this.btnCreateText = 'Create Video'
    }
  }

  openPlansModal() {
    this.$store.commit('setCurrentModal', 'plans')
  }

  openImagePanel(ctx: any) {
    this.selectedContext = ctx
    this.setShowPane(true)
  }

  openTopicRecommend() {
    this.selectedContext = 'TOPIC_RECOMMENDATION'
    this.setShowPane(true)
  }

  updateTitle(value: any) {
    const blogRes = { ...this.parsedBlogResponse, title: value }
    this.$store.commit('setParsedBlog', blogRes)
    this.isUnsavedChanges = true
  }
  updateDescription(value: any) {
    const blogRes = { ...this.parsedBlogResponse, description: value }
    this.$store.commit('setParsedBlog', blogRes)
    this.isUnsavedChanges = true
  }
  updateKeywords(value: any) {
    const blogRes = { ...this.parsedBlogResponse, keywords: value.split(',') }
    this.$store.commit('setParsedBlog', blogRes)
    this.isUnsavedChanges = true
  }

  openChat() {
    //@ts-ignore
    // window.Intercom('show')
    Crisp.chat.open()
  }
  @Watch('isUnsavedChanges')
  handleUnsavedChange() {
    if (this.isUnsavedChanges) {
      this.addOnload()
    } else {
      this.removeOnload()
    }
  }
  addOnload() {
    window.addEventListener('beforeunload', this.reload)
  }
  removeOnload() {
    window.removeEventListener('beforeunload', this.reload)
  }
  reload(event: BeforeUnloadEvent) {
    event.preventDefault()
    return (event.returnValue = 'Changes that you made may not be saved')
  }
  beforeDestroy() {
    this.btnClicked = false
    if (this.isUnsavedChanges && this.draftData) {
      this.saveDraft()
    }
    this.removeOnload()
    this.$store.commit('clearParsedBlog')
    localStorage.removeItem('blogId')
  }

  async handleBlogParse() {
    if (this.selectedScriptLength.isLocked) {
      this.showPlans = true
      return
    }
    this.btnClicked = true
    if (this.blogUrlInp == 'https://www.example.com') {
      this.showSampleBlogPreview = true
    }

    const payload = {
      blogUrl: this.blogUrlInp,
      scriptLength: this.selectedScriptLength.words,
      scriptLanguage: this.selectedScriptLanguage,
      isSummarize: this.isSummarize,
    }
    try {
      this.isParseFetching = true
      const taskId = await this.$store.dispatch('createParseBlogTask', payload)

      const fetchBlog = async () => {
        const res: any = await AdoriServiceV6.getBlogParseStatus(this.networkId, taskId)
        if (res.status === uploadStatus.FINISHED) {
          this.$store.commit('updateBlogSceneList', get(res, 'blogData.scenes', []))
          this.$store.commit('setParsedBlog', get(res, 'blogData', {}))
          this.updateHistory()
          await this.saveDraft()
          //   await this.automateSceneImages()
          //   this.saveDraft()
          //   this.isSceneImgFetching = true

          this.isParseFetching = false
          this.showAutomationTypeModal = true
          return
        } else if (res.status === uploadStatus.FAILED) {
          this.$store.dispatch('pushNotification', {
            text: res.errorMessage || 'Error: Unable to fetch blog',
            type: 'ERROR',
          })
          this.isParseFetching = false
          return
        } else {
          setTimeout(fetchBlog, 2000)
        }
      }
      await fetchBlog()
      this.$gtag.event('create-scenes', {
        event_category: 'User Interaction',
        event_label: 'Create scenes from url',
      })
    } catch (error) {
      Sentry.captureException(error)
      this.$store.dispatch('pushNotification', {
        text: 'Convert Failed',
        type: 'ERROR',
      })
      this.isParseFetching = false
    }
  }

  breakTextIntoParagraphs(inputText: any) {
    // Split the input text into sentences using a regex
    const sentences = inputText.match(/[^.!?]+[.!?]+/g)

    if (!sentences) {
      return `<p>${inputText}</p>`
    }
    const paragraphs = []
    let currentParagraph = sentences[0]
    for (let i = 1; i < sentences.length; i++) {
      if (currentParagraph.length + sentences[i].length + 1 <= 200) {
        currentParagraph += ' ' + sentences[i]
      } else {
        paragraphs.push(`<p>${currentParagraph}</p>`)
        currentParagraph = sentences[i]
      }
    }
    paragraphs.push(`<p>${currentParagraph}</p>`)
    return paragraphs.join('')
  }

  async handleScriptParse() {
    const htmlOutput = this.breakTextIntoParagraphs(this.finalDraft)
    this.handleTextParse(htmlOutput, true)
  }

  async handleTextParse(html: any, isScript?: boolean) {
    if (this.selectedScriptLength.isLocked) {
      this.showPlans = true
      return
    }
    this.blogUrlInp = ''
    const payload = {
      htmlText: html,
      scriptLength: this.selectedScriptLength.words,
      scriptLanguage: this.selectedScriptLanguage,
      isSummarize: this.isSummarize,
    }
    try {
      this.isParseFetching = true
      const res: any = await AdoriServiceV6.postTextParse(this.networkId, payload)
      this.$gtag.event('create-scenes', {
        event_category: 'User Interaction',
        event_label: 'Create scenes from Text/AI',
      })
      this.$store.commit('updateBlogSceneList', get(res, 'blogData.scenes', []))
      this.$store.commit('setParsedBlog', get(res, 'blogData', {}))
      this.updateHistory()
      await this.saveDraft()
      //   if (isScript)
      //   await this.automateAITags({ type: 'images' })
      await this.automateSceneImages()
      //   this.saveDraft()
      this.isParseFetching = false
    } catch (error) {
      Sentry.captureException(error)
      this.$store.dispatch('pushNotification', {
        text: 'Convert Failed',
        type: 'ERROR',
      })
      this.isParseFetching = false
    }
  }

  async handleFileParse() {
    if (this.selectedScriptLength.isLocked) {
      this.showPlans = true
      return
    }
    this.selectedTextFile = this.selectedFile
    this.blogUrlInp = ''

    const payload = {
      file: this.selectedTextFile,
      scriptLength: this.selectedScriptLength.words,
      scriptLanguage: this.selectedScriptLanguage,
      isSummarize: this.isSummarize,
    }

    try {
      this.isParseFetching = true
      const taskId = await this.$store.dispatch('createParseFileTask', payload)

      const fetchBlog = async () => {
        const res: any = await AdoriServiceV6.getBlogParseStatus(this.networkId, taskId)
        if (res.status === uploadStatus.FINISHED) {
          this.$gtag.event('create-scenes', {
            event_category: 'User Interaction',
            event_label: 'Create scenes from File',
          })
          this.$store.commit('updateBlogSceneList', get(res, 'blogData.scenes', []))
          this.$store.commit('setParsedBlog', get(res, 'blogData', {}))
          this.updateHistory()
          await this.saveDraft()

          this.isParseFetching = false
          this.showAutomationTypeModal = true
          return
        } else if (res.status === uploadStatus.FAILED) {
          this.$store.dispatch('pushNotification', {
            text: res.errorMessage || 'Error: Unable to fetch blog',
            type: 'ERROR',
          })
          this.isParseFetching = false
          return
        } else {
          setTimeout(fetchBlog, 2000)
        }
      }
      await fetchBlog()
    } catch (error) {
      Sentry.captureException(error)
      this.$store.dispatch('pushNotification', {
        text: 'Convert Failed',
        type: 'ERROR',
      })
      this.isParseFetching = false
    }
  }

  startRateLimitTimer() {
    let startTime = Date.now()
    const countdown = () => {
      const currentTime = Date.now()
      const elapsedSeconds = Math.floor((currentTime - startTime) / 1000)
      this.rateLimitSecondsLeft = Math.max(0, 60 - elapsedSeconds)
      if (this.rateLimitSecondsLeft <= 0) {
        this.rateLimitSecondsLeft = 0
      } else {
        setTimeout(countdown, 1000)
      }
    }
    countdown()
  }

  goBack() {
    if (this.isUnsavedChanges && this.draftData) {
      this.saveDraft()
    }
    // this.$store.commit('clearParsedBlog')
    // localStorage.removeItem('blogId')
    this.$router.go(-1)
  }

  clearScenes() {
    this.$store.commit('clearParsedBlog')
    localStorage.removeItem('blogId')
    this.$router.replace(`/home/blog/new?method=${this.parsingMethod}`)
  }

  async automateThumbAndTitle() {
    try {
      if (
        !this.thumbnailImage ||
        [
          'https://storage.googleapis.com/production_audio_adorilabs/samples/placeholder.png',
          'https://storage.googleapis.com/dev_audio_adorilabs/samples/placeholder.png',
        ].includes(this.thumbnailImage)
      ) {
        const openAiResThumb: any = await AdoriServiceV6.openAi(this.networkId, {
          topic: `---BEGIN Text---
			  ${
          !!this.parsedBlogResponse.cleanText
            ? this.cleanText.slice(0, 12000)
            : this.parsedBlogResponse.summary.slice(0, 12000)
        }
			  ---END Text---
              Suggest short image search prompt for above text:`,
          context: 'image',
        })
        const unsplashRes: any = await AdoriService.searchImages(this.networkId, 'unsplash', {
          limit: 10,
          offset: 0,
          query: formatOpenAiCaption(openAiResThumb.result),
        })
        if (unsplashRes.data.length) {
          const randomNumber = Math.floor(Math.random() * 6)
          const blogRes = { ...this.parsedBlogResponse }
          blogRes['topImage'] = unsplashRes.data[randomNumber].urls.full

          this.$store.commit('setParsedBlog', blogRes)
        } else {
          const googleRes: any = await AdoriService.searchImages(this.networkId, 'google', {
            limit: 10,
            offset: 0,
            query: formatOpenAiCaption(openAiResThumb.result),
          })
          if (googleRes.data.length) {
            const randomNumber = Math.floor(Math.random() * 6)
            const blogRes = { ...this.parsedBlogResponse }
            blogRes['topImage'] = googleRes.data[randomNumber].urls.full

            this.$store.commit('setParsedBlog', blogRes)
          }
        }
      }

      const openAiResATitle: any =
        !this.title || this.title === 'Untitled'
          ? await AdoriServiceV6.openAi(this.networkId, {
              topic: `---BEGIN Text---
			  ${
          !!this.parsedBlogResponse.cleanText
            ? this.cleanText.slice(0, 12000)
            : this.parsedBlogResponse.summary.slice(0, 12000)
        }
			  ---END Text---
              Suggest short title for above text:`,
              context: 'text',
              template: 'GENERAL_CHATBOT',
            })
          : ''
      const openAiResAiDescription: any = !this.description
        ? await AdoriServiceV6.openAi(this.networkId, {
            topic: `---BEGIN Text---
			  ${
          !!this.parsedBlogResponse.cleanText
            ? this.cleanText.slice(0, 12000)
            : this.parsedBlogResponse.summary.slice(0, 12000)
        }
			  ---END Text---
              Summarize the text in 3 sentences or fewer:`,
            context: 'text',
            template: 'GENERAL_CHATBOT',
          })
        : ''
      const openAiResAiKeywords: any = !this.keywords
        ? await AdoriServiceV6.openAi(this.networkId, {
            topic: `---BEGIN Text---
			  ${
          !!this.parsedBlogResponse.cleanText
            ? this.cleanText.slice(0, 12000)
            : this.parsedBlogResponse.summary.slice(0, 12000)
        }
			  ---END Text---
              Extract comma separated keywords from above text:`,
            context: 'text',
            template: 'GENERAL_CHATBOT',
          })
        : ''

      const blogRes = { ...this.parsedBlogResponse }
      !this.title || (this.title === 'Untitled' && (blogRes['title'] = openAiResATitle.result))
      !this.description && (blogRes['description'] = openAiResAiDescription.result)
      let key = !this.keywords ? formatOpenAiKeywords(openAiResAiKeywords.result) : ''
      const words = key.split(',')
      words.length && !this.keywords && (blogRes['keywords'] = words)
      this.$store.commit('setParsedBlog', blogRes)
      this.isSceneImgFetching = false
      this.isUnsavedChanges = true
    } catch (error) {
      this.isSceneImgFetching = false
      Sentry.captureException(error)
      console.log(error)

      this.$store.dispatch('pushNotification', {
        text: 'Auto image selection failed for thumbs!! try again later',
        type: 'WARNING',
      })
    }
  }

  handleAutomation() {
    this.showAutomationTypeModal = true
  }

  async automateAITags(value: any) {
    this.showAutomationTypeModal = false
    const type = value.type

    this.isSceneImgFetching = true

    if (this.rateLimitSecondsLeft != 0) {
      this.showRateLimitPopup = true
      return
    }

    try {
      let image: any

      const trackArray: any = []
      let count = 0

      this.blogSceneList.forEach(async (scene: any, index: number) => {
        let openAi!: any
        let query

        try {
          if (type === 'images' && !scene.image) {
            this.$gtag.event('auto-pick-media', {
              event_category: 'User Interaction',
              event_label: 'Auto pick stock images',
            })
            count = count + 1
            //check rate limit conditions
            if (count > 50) {
              this.rateLimitSecondsLeft = 60
              this.showRateLimitPopup = true
              this.startRateLimitTimer()
              return
            }

            if (scene.prompt) {
              query = scene.prompt
            } else {
              openAi = await AdoriServiceV6.openAi(this.networkId, {
                topic: `give one or two comma separated keywords that encapsulates the main visual elements and characteristics of the described scene. Scene_description=${scene.text}. Give only keywords as response. Translate non-English keywords to English while maintaining cultural context.`,
                context: 'image',
              })
              query = formatOpenAiCaption(openAi.result)
            }

            const unsplashRes: any = await AdoriService.searchImages(this.networkId, 'unsplash', {
              limit: 10,
              offset: 0,
              query: query,
              orientation: value.aiParams.orientation,
            })
            if (unsplashRes.data.length) {
              const randomNumber = Math.floor(Math.random() * 6)
              const modifiedScene = { ...scene, image: unsplashRes.data[randomNumber].urls.full }
              this.updateScene(modifiedScene)
            } else {
              const googleRes: any = await AdoriService.searchImages(this.networkId, 'google', {
                limit: 10,
                offset: 0,
                query: query,
              })
              if (googleRes.data.length) {
                const randomNumber = Math.floor(Math.random() * 6)
                const modifiedScene = { ...scene, image: googleRes.data[randomNumber].urls.full }
                this.updateScene(modifiedScene)
              }
            }
            trackArray.push(index)
          } else if (type === 'ai' && !scene.image) {
            this.$gtag.event('auto-pick-media', {
              event_category: 'User Interaction',
              event_label: 'Auto pick AI images',
            })
            count = count + 1
            //check rate limit conditions
            if (count > 50) {
              this.rateLimitSecondsLeft = 60
              this.showRateLimitPopup = true
              this.startRateLimitTimer()
              return
            }

            openAi = await AdoriServiceV6.openAi(this.networkId, {
              topic: `"""scene description: ${scene.text} """ \n Without any extra text in response, write a short image prompt in english for scene description. `,
              context: 'image',
            })

            const res: any = await AdoriServiceV6.stabilityResults(this.networkId, {
              prompt: formatOpenAiCaption(openAi.result),
              aspect_ratio: value.aiParams.aspect_ratio,
              model: 'flux-schnell',
              width: value.aiParams.width,
              height: value.aiParams.height,
            })
            image = await AdoriService.uploadImage(this.networkId, {
              url: res.url,
            })
            trackArray.push(index)
            const modifiedScene = { ...scene, image: image.originalUrl }
            this.updateScene(modifiedScene)
          } else if (type === 'videos' && !scene.image && !scene.video) {
            this.$gtag.event('auto-pick-media', {
              event_category: 'User Interaction',
              event_label: 'Auto pick stock videos',
            })
            try {
              count = count + 1
              //check rate limit conditions
              if (count > 50) {
                this.rateLimitSecondsLeft = 60
                this.showRateLimitPopup = true
                this.startRateLimitTimer()
                return
              }

              if (scene.prompt) {
                query = scene.prompt
              } else {
                openAi = await AdoriServiceV6.openAi(this.networkId, {
                  topic: `give one or two comma separated keywords that encapsulates the main visual elements and characteristics of the described scene. Scene_description=${scene.text}. Give only keywords as response. Translate non-English keywords to English while maintaining cultural context.`,
                  context: 'image',
                })
                query = formatOpenAiCaption(openAi.result)
              }

              const randomNumber = Math.floor(Math.random() * 5)
              const pexelsRes = await AdoriServiceV6.searchVideos(this.networkId, 'pexels', {
                limit: 10,
                offset: 0,
                query: query,
                orientation: value.aiParams.orientation === 'squarish' ? 'square' : value.aiParams.orientation,
              })
              const modifiedScene = {
                ...scene,
                video: {
                  originalUrl: get(pexelsRes.data[randomNumber], 'urls.large.url', ''),
                  previewUrl: get(pexelsRes.data[randomNumber], 'urls.tiny.url', ''),
                  thumbNail: get(pexelsRes.data[randomNumber], 'urls.tiny.thumbnail', ''),
                  source: 'adori',
                  id: pexelsRes.data[randomNumber].id,
                },
              }
              this.updateScene(modifiedScene)
            } catch (error) {
              console.log(error)
              this.$store.dispatch('pushNotification', {
                text: 'Auto Video selection failed!! try again later',
                type: 'WARNING',
              })
              this.isSceneImgFetching = false
            }
            trackArray.push(index)
          } else {
            trackArray.push(index)
          }
        } catch (error) {
          trackArray.push(index)
          Sentry.captureException(error)
          this.$store.dispatch('pushNotification', {
            text: `Scene ${index + 1} visual generation failed`,
            type: 'WARNING',
          })
          this.isSceneImgFetching = false
        }
        if (trackArray.length === this.blogSceneList.length) {
          await this.automateThumbAndTitle()
        }
      })
    } catch (error) {
      Sentry.captureException(error)
      this.$store.dispatch('pushNotification', {
        text: 'Auto image selection failed!! try again later',
        type: 'WARNING',
      })

      this.isSceneImgFetching = false
    }
  }

  async automateSceneImages() {
    this.isSceneImgFetching = true

    if (this.rateLimitSecondsLeft != 0) {
      this.showRateLimitPopup = true
      return
    }

    try {
      const trackArray: any = []
      let count = 0
      let pexelsRes: any = null
      let query

      this.blogSceneList.forEach(async (scene: any, index: number) => {
        trackArray.push(index)
        if (scene.image == null) {
          count = count + 1

          //check rate limit conditions
          if (count > 50) {
            this.rateLimitSecondsLeft = 60
            this.showRateLimitPopup = true
            this.startRateLimitTimer()
            return
          }
          if (scene.prompt) {
            query = scene.prompt
          } else {
            const openAi: any = await AdoriServiceV6.openAi(this.networkId, {
              topic: `give one or two comma separated keywords that encapsulates the main visual elements and characteristics of the described scene. Scene_description=${scene.text}.Give only keywords as response. Translate non-English keywords to English while maintaining cultural context`,
              context: 'image',
            })
            query = formatOpenAiCaption(openAi.result)
          }

          if (this.isPremium && index % 2 != 0) {
            pexelsRes = await AdoriServiceV6.searchVideos(this.networkId, 'pexels', {
              limit: 10,
              offset: 0,
              query: query,
            })
          }

          if (pexelsRes && pexelsRes.data.length) {
            const modifiedScene = {
              ...scene,
              video: {
                originalUrl: get(pexelsRes.data[0], 'urls.large.url', ''),
                previewUrl: get(pexelsRes.data[0], 'urls.tiny.url', ''),
                thumbNail: get(pexelsRes.data[0], 'urls.tiny.thumbnail', ''),
                source: 'adori',
                id: pexelsRes.data[0].id,
              },
            }
            this.updateScene(modifiedScene)
          } else {
            const unsplashRes: any = await AdoriService.searchImages(this.networkId, 'unsplash', {
              limit: 10,
              offset: 0,
              query: query,
            })
            if (unsplashRes.data.length) {
              const randomNumber = Math.floor(Math.random() * 6)
              const modifiedScene = { ...scene, image: unsplashRes.data[randomNumber].urls.full }
              this.updateScene(modifiedScene)
            } else {
              const googleRes: any = await AdoriService.searchImages(this.networkId, 'google', {
                limit: 10,
                offset: 0,
                query: query,
              })
              if (googleRes.data.length) {
                const randomNumber = Math.floor(Math.random() * 6)
                const modifiedScene = { ...scene, image: googleRes.data[randomNumber].urls.full }
                this.updateScene(modifiedScene)
              }
            }
          }
        }
        if (trackArray.length === this.blogSceneList.length) {
          await this.automateThumbAndTitle()
        }
      })
    } catch (error) {
      Sentry.captureException(error)
      this.$store.dispatch('pushNotification', {
        text: 'Auto image selection failed!! try again later',
        type: 'WARNING',
      })

      this.isSceneImgFetching = false
    }
  }

  updateScene(value: any) {
    const scenes = [...this.blogSceneList]
    scenes[value.index] = value
    this.$store.commit('updateBlogSceneList', scenes)
    this.updateHistory()
    this.isUnsavedChanges = true
  }

  moveScene({ index, direction }: { index: number; direction: 'up' | 'down' }) {
    let scenes = [...this.blogSceneList]
    if ((direction === 'up' && index === 0) || (direction === 'down' && index === scenes.length - 1)) return

    const newScenes = [...scenes]
    const newIndex = direction === 'up' ? index - 1 : index + 1
    ;[newScenes[index], newScenes[newIndex]] = [newScenes[newIndex], newScenes[index]]
    scenes = newScenes
    this.$store.commit('updateBlogSceneList', scenes)
    this.updateHistory()
    this.isUnsavedChanges = true
  }

  addBwScene(key: any) {
    let scenes = [...this.blogSceneList]
    const index = key + 1
    scenes.splice(index, 0, {
      index: index,
      text: '',
      type: 'para',
      video: '',
      image: '',
    })
    scenes = scenes.map((obj: any, index: number) => ({
      ...obj,
      index,
    }))

    this.$store.commit('updateBlogSceneList', scenes)
    this.updateHistory()
    this.isUnsavedChanges = true
  }

  addScene() {
    let scenes = [...this.blogSceneList]
    const index = scenes.length + 1
    scenes.push({
      index: index,
      text: '',
      type: 'para',
      video: '',
      image: '',
    })
    scenes = scenes.map((obj: any, index: number) => ({
      ...obj,
      index,
    }))

    this.$store.commit('updateBlogSceneList', scenes)
    this.updateHistory()
    this.isUnsavedChanges = true
  }

  removeScene(index: any) {
    let scenes = [...this.blogSceneList]
    scenes.splice(index, 1)
    scenes = scenes.map((obj: any, index: number) => ({
      ...obj,
      index,
    }))
    this.$store.commit('updateBlogSceneList', scenes)
    this.updateHistory()
    this.isUnsavedChanges = true
  }

  updateHistory() {
    let newBlogHistory = [...this.blogHistory]
    newBlogHistory.length === 10 && newBlogHistory.shift()
    newBlogHistory = newBlogHistory.slice(0, this.currentHistoryIndex + 1)
    newBlogHistory.push({
      blogSceneList: this.blogSceneList,
      parsedBlogResponse: this.parsedBlogResponse,
    })
    this.$store.commit('setBlogHistory', newBlogHistory)
    this.$store.commit('setHistoryIndex', newBlogHistory.length - 1)
  }

  undo() {
    if (this.currentHistoryIndex > 0) {
      this.$store.commit('setHistoryIndex', this.currentHistoryIndex - 1)
      this.$store.commit('updateBlogSceneList', this.blogHistory[this.currentHistoryIndex].blogSceneList)
      this.$store.commit('setParsedBlog', this.blogHistory[this.currentHistoryIndex].parsedBlogResponse)
    }
  }
  redo() {
    if (this.currentHistoryIndex < this.blogHistory.length - 1) {
      this.$store.commit('setHistoryIndex', this.currentHistoryIndex + 1)
      this.$store.commit('updateBlogSceneList', this.blogHistory[this.currentHistoryIndex].blogSceneList)
      this.$store.commit('setParsedBlog', this.blogHistory[this.currentHistoryIndex].parsedBlogResponse)
    }
  }

  removeSceneImage(scene: any) {
    const scenes = [...this.blogSceneList]
    scene.image = null
    scene.video = null
    scenes[scene.index] = scene
    this.$store.commit('updateBlogSceneList', scenes)
    this.isUnsavedChanges = true
  }

  handleSelectMusic(data: any) {
    const blogRes = { ...this.parsedBlogResponse }
    blogRes['musicData'] = data
    this.$store.commit('setParsedBlog', blogRes)
    this.isUnsavedChanges = true
  }

  removeMusic() {
    const blogRes = { ...this.parsedBlogResponse }
    delete blogRes.musicData
    this.$store.commit('setParsedBlog', blogRes)
    this.isUnsavedChanges = true
  }

  selectItem(item: any) {
    if (this.selectedContext == 'TOPIC_RECOMMENDATION') {
      this.topic = item
    }
    if (['MAIN_BLOG_IMAGE', 'AI_TITLE', 'AI_DESCRIPTION', 'AI_KEYWORDS'].includes(item.context)) {
      const blogRes = { ...this.parsedBlogResponse }
      !!item.image && (blogRes['topImage'] = item.image)
      !!item.title && (blogRes['title'] = item.title.replace(/["]/g, ''))
      !!item.description && (blogRes['description'] = item.description)

      if (item.context === 'AI_KEYWORDS') {
        let key = formatOpenAiKeywords(item.keywords)
        const words = key.split(',')
        words.length && (blogRes['keywords'] = words)
      }
      this.selectedScene = {
        image: item.image,
        index: 0,
        text: this.title,
      }
      this.$store.commit('setParsedBlog', blogRes)
    } else if (item.context === 'SCENE_BLOG_IMAGE') {
      const scene = { ...item.scene, image: item.image }
      this.updateScene(scene)
      this.selectedScene = scene
    } else if (item.context === 'BLOG_TEXT') {
      const scene = { ...item.scene, text: item.text }
      this.updateScene(scene)
    }
    this.isUnsavedChanges = true
  }

  handleContentLoad(item: any) {
    if (this.selectedContext === 'BLOG_TEXT') {
      const scene = { ...this.selectedScene, image: item.url, video: null }
      this.selectedScene = scene
      this.updateScene(scene)
    }
    if (this.selectedContext === 'MAIN_BLOG_IMAGE') {
      const blogRes = { ...this.parsedBlogResponse }
      blogRes['topImage'] = item.url
      this.selectedScene = {
        image: item.url,
        index: 0,
        text: this.title,
      }
      this.$store.commit('setParsedBlog', blogRes)
      this.isUnsavedChanges = true
    }
    this.showContentModal = false
  }
  handleContentVideoLoad(item: any) {
    let scene = { ...this.selectedScene, video: null, image: null }
    this.updateScene(scene)
    setTimeout(() => {
      scene = { ...this.selectedScene, video: item, image: null }
      this.updateScene(scene)
    }, 100)
    this.showContentModal = false
  }
  toggleAIPanel(scene: any) {
    this.selectedScene = scene
    this.openImagePanel('BLOG_TEXT')
  }
  toggleThumbnailPanel() {
    this.selectedScene = {
      image: this.thumbnailImage,
      index: 0,
      text: this.title,
    }
    this.showContentModal = true
    this.selectedContext = 'MAIN_BLOG_IMAGE'
    this.isSowVideo = false
  }
  toggleBlogImages(scene: any) {
    this.showContentModal = true
    this.selectedScene = scene
    this.selectedContext = 'BLOG_TEXT'
    this.isSowVideo = true
  }
  toggleBackgroundMusic() {
    this.showMusicModal = true
  }

  async generateImages(audioTrack: any, sceneImages: any) {
    this.btnCreateText = 'Generating AI images ....'
    try {
      const transcript: any = await AdoriService.fetchAudioTranscript(this.networkId, audioTrack.uid)
      const sentences = transcript.sentences

      const all_words: any[] = []

      sentences.forEach((sentence: { words: any }) => {
        const { words } = sentence
        words.forEach((word: any) => {
          all_words.push({ ...word })
        })
      })

      const total_words = all_words.length

      const sceneImagesWithTime = sceneImages.map((scene: { startWordCount: any; endWordCount: any }) => {
        let { startWordCount, endWordCount } = scene
        if (startWordCount >= total_words) {
          startWordCount = total_words - 15
        }
        if (endWordCount >= total_words) {
          endWordCount = total_words - 1
        }

        const start_time = all_words[startWordCount].start_time.replace('s', '') * 1000
        const end_time = all_words[endWordCount].end_time.replace('s', '') * 1000

        return {
          ...scene,
          start_time,
          end_time,
        }
      })

      this.createTags(sceneImagesWithTime, audioTrack)
    } catch (error) {
      Sentry.captureException(error)
      this.$store.dispatch('pushNotification', {
        text: 'AI image generation failed!! try again later',
        type: 'WARNING',
      })

      this.goToOnboarding(audioTrack)
      this.isConverting = false
      this.btnCreateText = 'Create Video'
    }
  }

  getImgMeta(src: string) {
    return new Promise((resolve, reject) => {
      let img = new Image()
      img.onload = () => resolve({ originalImageWidth: img.width, originalImageHeight: img.height })
      img.onerror = reject
      img.src = src
    })
  }

  async createTags(imagesArray: any, audioTrack: any) {
    this.btnCreateText = 'Creating visuals ...'
    if (imagesArray.length == 0) {
      this.goToOnboarding(audioTrack)
    }
    try {
      const tempArray: boolean[] = []
      await this.$store.dispatch('getAudioTags', audioTrack.uid)
      imagesArray.map(async (imageObj: any, index: number) => {
        if (imageObj) {
          let image: any
          let video: any
          let tagItem: any
          let tagId: any
          try {
            if (imageObj.type === 'image') {
              const parsed_url = new URL(imageObj.url)
              const parts = parsed_url.hostname.split('.')
              const path = parsed_url.pathname.substring(1)
              const domain = parts.slice(-2).join('.')

              if (domain == 'adorilabs.com') {
                image = await AdoriService.getImageInfo(this.networkId, path)
              } else {
                image = await AdoriService.uploadImage(this.networkId, {
                  url: imageObj.url,
                })
              }

              const imageMeta: any = await this.getImgMeta(image.originalUrl)
              const originalImageWidth = imageMeta.originalImageWidth
              const originalImageHeight = imageMeta.originalImageHeight

              let imageWidth, imageHeight

              if (originalImageWidth >= originalImageHeight) {
                // Landscape or square image
                imageWidth = 1080
                imageHeight = 1080 / (originalImageWidth / originalImageHeight)
              } else {
                // Portrait image
                imageHeight = 1080
                imageWidth = 1080 * (originalImageWidth / originalImageHeight)
              }

              // Calculate scaleX and scaleY based on original image dimensions
              const scaleX = imageWidth / originalImageWidth
              const scaleY = imageHeight / originalImageHeight

              // Draw the image in the center of the canvas
              const x = (1080 - imageWidth) / 2
              const y = (1080 - imageHeight) / 2

              this.tagEditStoreData.data.tagType = 'photo'
              this.tagEditStoreData.data.canvasData = {
                version: '4.6.0',
                objects: [
                  {
                    type: 'rect',
                    version: '4.6.0',
                    originX: 'left',
                    originY: 'top',
                    left: 0,
                    top: 0,
                    width: 1080,
                    height: 1080,
                    fill: {
                      type: 'linear',
                      coords: {
                        x1: 0,
                        y1: 540,
                        x2: 1920,
                        y2: 0,
                      },
                      colorStops: [
                        {
                          offset: 0,
                          color: '#21D4FD',
                        },
                        {
                          offset: 1,
                          color: '#B721FF',
                        },
                      ],
                      offsetX: 0,
                      offsetY: 0,
                      gradientUnits: 'pixels',
                      gradientTransform: null,
                    },
                    stroke: null,
                    strokeWidth: 1,
                    strokeDashArray: null,
                    strokeLineCap: 'butt',
                    strokeDashOffset: 0,
                    strokeLineJoin: 'miter',
                    strokeUniform: false,
                    strokeMiterLimit: 4,
                    scaleX: 1,
                    scaleY: 1,
                    angle: 0,
                    flipX: false,
                    flipY: false,
                    opacity: 1,
                    shadow: null,
                    visible: true,
                    backgroundColor: '',
                    fillRule: 'nonzero',
                    paintFirst: 'fill',
                    globalCompositeOperation: 'source-over',
                    skewX: 0,
                    skewY: 0,
                    rx: 0,
                    ry: 0,
                    id: 'artboard',
                  },
                  {
                    type: 'image',
                    version: '4.6.0',
                    originX: 'left',
                    originY: 'top',
                    left: x,
                    top: y,
                    width: originalImageWidth,
                    height: originalImageHeight,
                    fill: 'rgb(0,0,0)',
                    stroke: null,
                    strokeWidth: 0,
                    strokeDashArray: null,
                    strokeLineCap: 'butt',
                    strokeDashOffset: 0,
                    strokeLineJoin: 'miter',
                    strokeUniform: false,
                    strokeMiterLimit: 4,
                    scaleX: scaleX,
                    scaleY: scaleY,
                    angle: 0,
                    flipX: false,
                    flipY: false,
                    opacity: 1,
                    shadow: null,
                    visible: true,
                    backgroundColor: '',
                    fillRule: 'nonzero',
                    paintFirst: 'fill',
                    globalCompositeOperation: 'source-over',
                    skewX: 0,
                    skewY: 0,
                    cropX: 0,
                    cropY: 0,
                    src: image.originalUrl,
                    crossOrigin: 'anonymous',
                    filters: [],
                  },
                ],
              }
              this.tagEditStoreData.tagTitle = 'AI TAG'
              this.tagEditStoreData.data.orientation = 'SQUARE'
              this.tagEditStoreData.data.imageId = image.id
              this.tagEditStoreData.data.imageData = ''
              this.tagEditStoreData.data.videoId = null

              tagId = await this.$store.dispatch('uploadTag', false)
              tagItem = {
                tagId,
                offsetMillis: imageObj.start_time,
                durationMillis: imageObj.end_time - imageObj.start_time,
              }
            }

            if (imageObj.type === 'video') {
              video = await AdoriServiceV6.uploadVideoUrl(this.networkId, imageObj.url, imageObj?.thumbNail)
              this.tagEditStoreData.tagTitle = 'AI TAG'
              this.tagEditStoreData.data.orientation = 'SQUARE'
              this.tagEditStoreData.data.imageId = null
              this.tagEditStoreData.data.videoSrc = video.originalUrl
              this.tagEditStoreData.data.videoId = video.id
              this.tagEditStoreData.data.canvasData = {}
              this.tagEditStoreData.data.tagType = 'photo'

              tagId = await this.$store.dispatch('uploadTag', false)
              tagItem = {
                tagId,
                offsetMillis: imageObj.start_time,
                durationMillis: imageObj.end_time - imageObj.start_time,
              }
            }
            await this.$store.dispatch('addAudioTag', {
              audioUid: audioTrack.uid,
              tagPosition: tagItem,
            })
            tempArray.push(tagId)
            this.currentTags = tempArray
          } catch (error) {
            tempArray.push(false)
          }
        } else {
          tempArray.push(false)
        }

        try {
          if (tempArray.length === imagesArray.length) {
            await this.$store.dispatch('uploadAudioTags', audioTrack.uid)
            this.goToOnboarding(audioTrack)
          }
        } catch (error) {
          Sentry.captureException(error)
          this.$store.dispatch('pushNotification', {
            text: 'Some visuals failed to generate ! Try again later.',
            type: 'WARNING',
          })
          await this.$store.dispatch('uploadAudioTags', audioTrack.uid)
          this.goToOnboarding(audioTrack)
          this.isConverting = false
          this.btnCreateText = 'Create Video'
        }
      })
    } catch (error) {
      Sentry.captureException(error)
      this.$store.dispatch('pushNotification', {
        text: 'Generating some tags Failed!!! try again later',
        type: 'WARNING',
      })
      await this.$store.dispatch('uploadAudioTags', audioTrack.uid)
      this.goToOnboarding(audioTrack)
      this.isConverting = false
      this.btnCreateText = 'Create Video'
    }
  }

  goToOnboarding(audioTrack: any) {
    this.queryClient.invalidateQueries(audioQueryKeys.ALLTRACKS)
    if (audioTrack) {
      this.$router.push(`/preview/${audioTrack.uid}`)
      return
    }
    const selectedEpisode = {
      audioUrl: audioTrack.audioUrl,
      name: audioTrack.name,
      description: audioTrack.description,
      explicitContent: audioTrack.explicitContent,
      guid: audioTrack.guid,
      imageId: audioTrack.imageId,
      imageUrl: audioTrack.imageUrl,
      isRss: false,
      keywords: audioTrack.keywords,
      durationSeconds: audioTrack.durationMillis / 1000,
      isReUpload: true,
    }
    this.$store.commit('unselectAllEpisodeForUpload')
    this.$store.commit('clearYoutubeState')
    this.$store.commit('resetYoutubeSetting')
    this.$store.commit('selectEpisodeForUpload', selectedEpisode)
    this.$store.commit('setYoutubeStep', 2)
    sessionStorage.setItem('upload', 'YES')
    sessionStorage.setItem('onboardingTrack', JSON.stringify(selectedEpisode))
    this.$router.push('/onboarding')
    this.queryClient.invalidateQueries(audioQueryKeys.ALLTRACKS)
  }

  //   @Watch('parsedBlogScenes')
  //   handleBlogScene() {
  //     if (this.parsedBlogScenes.length && !this.tourShown) {
  //       this.showTour()
  //       this.tourShown = true
  //     }
  //   }
  //   @Watch('showSampleBlogPreview')
  //   handlePreview() {
  //     if (!this.showSampleBlogPreview) {
  //       this.showTour()
  //     }
  //   }

  @Watch('selectedFile')
  handleHasFileSelected(e: any) {
    if (this.selectedFile.type === 'text/plain' || this.selectedFile.type === 'application/pdf') {
      this.selectedTextFile = this.selectedFile
    }
  }

  mounted() {
    //@ts-ignore
    this.$nextTick(() => this.$refs['_input'].focus())
  }

  showTour(show = false) {
    this.$nextTick(() => {
      const tour = this.$shepherd({
        defaultStepOptions: {
          classes: 'shepherd-theme-custom',
        },
        popperOptions: {
          modifiers: [{ name: 'offset', options: { offset: [0, 12] } }],
        },
        useModalOverlay: true,
      })
      tour.addSteps([
        {
          title: 'AI Crafted Captivating Title<span>1/9</span>',
          text: `Press the robot icon to create an attention-grabbing title.<video muted autoplay loop ><source  src="https://storage.googleapis.com/dev_audio_adorilabs/samples/tour/ai_title.mp4" type="video/mp4" ></video>`,
          content: 'ff',
          classes: 'test',
          attachTo: {
            element: '#blog_scenes_tour_step_1',
            on: 'right',
          },
          when: {
            show() {},
          },
          buttons: [
            {
              text: 'Exit tour',
              action: () => {
                tour.cancel()
                this.uploadTourStatus({
                  tourName: 'blog_scenes_tour',
                  isLoggedIn: this.networkId,
                })
              },
              classes: 'shepherd-back',
            },
            {
              text: 'Next',
              action: () => {
                tour.next()
              },
              classes: 'shepherd-next',
            },
          ],
          id: 'one',
        },
        {
          title: 'YouTube SEO Descriptor<span>2/9</span>',
          text: `Click the robot icon to generate an SEO optimised description.<video muted autoplay loop > <source  src="https://storage.googleapis.com/dev_audio_adorilabs/samples/tour/ai_description.mp4" type="video/mp4" ></video>`,
          attachTo: {
            element: '#blog_scenes_tour_step_2',
            on: 'right',
          },
          when: {},
          buttons: [
            {
              text: 'Back',
              action: () => {
                tour.back()
              },
              classes: 'shepherd-back',
            },
            {
              text: 'Next',
              action: () => {
                tour.next()
              },

              classes: 'shepherd-next',
            },
          ],
          id: 'two',
        },
        {
          title: 'Clear Scenes<span>3/9</span>',
          text: `Clear the cached scenes to work on a different blog or text. <video muted autoplay loop > <source  src="https://storage.googleapis.com/dev_audio_adorilabs/samples/tour/clear.mp4" type="video/mp4" ></video>`,
          attachTo: {
            element: '#blog_scenes_tour_step_3',
            on: 'right',
          },
          when: {},
          buttons: [
            {
              text: 'Back',
              action: () => {
                tour.back()
              },
              classes: 'shepherd-back',
            },
            {
              text: 'Next',
              action: () => {
                tour.next()
              },

              classes: 'shepherd-next',
            },
          ],
          id: 'three',
        },
        {
          title: 'Edit your scenes<span>4/9</span>',
          text: `Incorporate the text or modify the text within the scene as needed. <video muted autoplay loop > <source  src="https://storage.googleapis.com/dev_audio_adorilabs/samples/tour/edit_scene_text.mp4" type="video/mp4" ></video>`,
          attachTo: {
            element: '#blog_scenes_0_tour_step_4',
            on: 'bottom',
          },
          when: {},
          buttons: [
            {
              text: 'Back',
              action: () => {
                tour.back()
              },
              classes: 'shepherd-back',
            },
            {
              text: 'Next',
              action: () => {
                tour.next()
              },

              classes: 'shepherd-next',
            },
          ],
          id: 'three',
        },
        {
          title: 'Add new scenes<span>5/9</span>',
          text: `You can add a scene in between. <video muted autoplay loop > <source  src="https://storage.googleapis.com/dev_audio_adorilabs/samples/tour/add_remove_scene.mp4" type="video/mp4" ></video>`,
          attachTo: {
            element: '#blog_scenes_0_tour_step_5',
            on: 'bottom',
          },
          when: {},
          buttons: [
            {
              text: 'Back',
              action: () => {
                tour.back()
              },
              classes: 'shepherd-back',
            },
            {
              text: 'Next',
              action: () => {
                tour.next()
              },

              classes: 'shepherd-next',
            },
          ],
          id: 'three',
        },
        {
          title: 'Remove scenes<span>6/9</span>',
          text: `You can remove any scene <video muted autoplay loop > <source  src="https://storage.googleapis.com/dev_audio_adorilabs/samples/tour/add_remove_scene.mp4" type="video/mp4" ></video>`,
          attachTo: {
            element: '#blog_scenes_0_tour_step_6',
            on: 'left',
          },
          when: {},
          buttons: [
            {
              text: 'Back',
              action: () => {
                tour.back()
              },
              classes: 'shepherd-back',
            },
            {
              text: 'Next',
              action: () => {
                tour.next()
              },

              classes: 'shepherd-next',
            },
          ],
          id: 'three',
        },
        {
          title: 'Image Selection<span>7/9</span>',
          text: `Click to insert a blog image or search from public image libraries. <video muted autoplay loop > <source  src="https://storage.googleapis.com/dev_audio_adorilabs/samples/tour/search_img.mp4" type="video/mp4" ></video>`,
          attachTo: {
            element: '#blog_scenes_0_tour_step_7',
            on: 'left',
          },
          when: {},
          buttons: [
            {
              text: 'Back',
              action: () => {
                tour.back()
              },
              classes: 'shepherd-back',
            },
            {
              text: 'Next',
              action: () => {
                tour.next()
              },

              classes: 'shepherd-next',
            },
          ],
          id: 'three',
        },
        {
          title: 'AI Image and AI Rewriting Scenes <span>8/9</span>',
          text: `Click on the robot icon to generate a Dall-E image for the scene, or utilize AI to make the scene text more engaging.  <video muted autoplay loop > <source  src="https://storage.googleapis.com/dev_audio_adorilabs/samples/tour/ai_scene_img.mp4" type="video/mp4" ></video>`,
          attachTo: {
            element: '#blog_scenes_0_tour_step_8',
            on: 'left',
          },
          when: {},
          buttons: [
            {
              text: 'Back',
              action: () => {
                tour.back()
              },
              classes: 'shepherd-back',
            },
            {
              text: 'Next',
              action: () => {
                tour.next()
              },

              classes: 'shepherd-next',
            },
          ],
          id: 'three',
        },
        {
          title: 'Auto-pick Images<span>9/9</span>',
          text: `Automatically select <strong>missing scene</strong> images. You can choose up to 50 auto-pick images within 1 minute. <video muted autoplay loop> <source  src="https://storage.googleapis.com/dev_audio_adorilabs/samples/tour/auto-pick.mp4" type="video/mp4" ></video>`,
          attachTo: {
            element: '#blog_scenes_tour_step_9',
            on: 'left',
          },
          when: {
            show() {},
            destroy: function () {},
          },

          buttons: [
            {
              text: 'Back',
              action: tour.back,
              classes: 'shepherd-back',
            },
            {
              text: 'Done',
              action: () => {
                tour.next()
                this.uploadTourStatus({
                  tourName: 'blog_scenes_tour',
                  isLoggedIn: this.networkId,
                })
              },
              classes: 'shepherd-next',
            },
          ],
          id: 'four',
        },
      ])

      if (!this.youtubeTourShown.includes('blog_scenes_tour') && !this.showSampleBlogPreview) {
        tour.start()
      }
      if (show) {
        tour.start()
      }
    })
  }
}
