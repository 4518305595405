import Vue from 'vue'
import { StoreOptions, GetterTree, MutationTree, ActionTree } from 'vuex'
import AdoriService from '@/services/adori'
import { createAdorificationPoll, destroyAdorificationPoll } from '@/store/audios'

const state: any = {
  hasAcceptedCookies: true,
  isSupportExist: false,
  selectedTagCollection: null,
  autoprocessedAudioUids: [],
  replaceAudioProcessUids: [],
  replaceAudioProcessPoll: null,
  youtubeTourShown: [],
  openAiPayload: {},
}

const getters: GetterTree<any, any> = {
  hasAcceptedCookies: (state) => state.hasAcceptedCookies,
  selectedTagCollection: (state) => state.selectedTagCollection,
  autoprocessedAudioUids: (state) => state.autoprocessedAudioUids,
  replaceAudioProcessUids: (state) => state.replaceAudioProcessUids,
  replaceAudioProcessPoll: (state) => state.replaceAudioProcessPoll,
  youtubeTourShown: (state) => state.youtubeTourShown,
  openAiPayload: (state) => state.openAiPayload,
  isSupportExist: (state) => state.isSupportExist,
}

const mutations: MutationTree<any> = {
  setIsSupportExist(state, value) {
    Vue.set(state, 'isSupportExist', value)
  },
  setYoutubeTourShown(state, tour) {
    Vue.set(state, 'youtubeTourShown', tour)
  },

  setHasAcceptedCookies(state, hasAcceptedCookies) {
    Vue.set(state, 'hasAcceptedCookies', hasAcceptedCookies)
  },

  setSelectedTagCollection(state, name) {
    Vue.set(state, 'selectedTagCollection', name)
  },

  setOpenAiPayload(state, data) {
    Vue.set(state, 'openAiPayload', data)
  },

  setAutoProcessedAudioUids(state, autoprocessedAudioUids) {
    autoprocessedAudioUids.forEach((audio: any) => {
      audio.pollHandler = createAdorificationPoll(this, audio.audioUid, 8000)
    })
    Vue.set(state, 'autoprocessedAudioUids', autoprocessedAudioUids)
  },

  addAudioToAutoProcessList(state, { audioUid, addTags }) {
    let index = null
    if (state.autoprocessedAudioUids) {
      index = state.autoprocessedAudioUids.findIndex((audio: any) => audio.audioUid === audioUid)
    }
    if (index !== null && index === -1) {
      const pollHandler = createAdorificationPoll(this, audioUid, 8000)
      Vue.set(state, 'autoprocessedAudioUids', [...state.autoprocessedAudioUids, { audioUid, addTags, pollHandler }])
    }
  },

  removeAudioFromAutoProcessList(state, audioUid: string) {
    let index = null
    if (state.autoprocessedAudioUids) {
      index = state.autoprocessedAudioUids.findIndex((audio: any) => audio.audioUid === audioUid)
    }
    if (index !== null && index !== -1) {
      if (state.autoprocessedAudioUids[index].pollHandler) {
        destroyAdorificationPoll(state.autoprocessedAudioUids[index].pollHandler)
      }
      Vue.delete(state.autoprocessedAudioUids, index)
      if (!state.autoprocessedAudioUids) {
        Vue.set(state, 'autoprocessedAudioUids', [])
      }
    }
  },

  addAudioToReplaceAudioProcessList(state, audioUid: string) {
    Vue.set(state, 'replaceAudioProcessUids', [...state.replaceAudioProcessUids, audioUid])
  },

  removeAudioToReplaceAudioProcessList(state, audioUid: string) {
    const index = state.replaceAudioProcessUids.findIndex((uid: any) => uid === audioUid)
    if (index !== -1) {
      const uids = [...state.replaceAudioProcessUids]
      uids.splice(index, 1)
      Vue.set(state, 'replaceAudioProcessUids', uids)
    }
  },

  changeAddTagsOfAudioInAudioProcessList(state, { audioUid, addTags }) {
    let index = null
    if (state.autoprocessedAudioUids) {
      index = state.autoprocessedAudioUids.findIndex((audio: any) => audio.audioUid === audioUid)
    }
    if (index !== null && index !== -1) {
      if (state.autoprocessedAudioUids[index].pollHandler) {
        destroyAdorificationPoll(state.autoprocessedAudioUids[index].pollHandler)
      }
      let newAudios = state.autoprocessedAudioUids
      newAudios[index].addTags = addTags
      Vue.set(state, 'autoprocessedAudioUids', newAudios)
    }
  },

  restartPollOfAudioInAudioProcessList(state, audioUid: string) {
    let index = null
    if (state.autoprocessedAudioUids) {
      index = state.autoprocessedAudioUids.findIndex((audio: any) => audio.audioUid === audioUid)
    }
    if (index !== null && index !== -1) {
      if (state.autoprocessedAudioUids[index].pollHandler) {
        destroyAdorificationPoll(state.autoprocessedAudioUids[index].pollHandler)
      }
      const pollHandler = createAdorificationPoll(this, state.autoprocessedAudioUids[index].audioUid, 8000)
      let newAudios = state.autoprocessedAudioUids
      newAudios[index].pollHandler = pollHandler
      Vue.set(state, 'autoprocessedAudioUids', newAudios)
    }
  },

  clearAudioProcessList(state) {
    Vue.set(state, 'autoprocessedAudioUids', [])
  },

  removeAudioFromReplaceAudioProcessUids(state, audioUid) {
    const index = state.replaceAudioProcessUids.findIndex((uid: string) => uid === audioUid)
    if (index !== -1) {
      let uids = [...state.replaceAudioProcessUids]
      uids.splice(index, 1)
      Vue.set(state, 'replaceAudioProcessUids', uids)
    }
  },

  setReplaceAudioProcessPoll(state, handler) {
    Vue.set(state, 'replaceAudioProcessPoll', handler)
  },
}

const actions: ActionTree<any, any> = {
  async getClientState(context) {
    const clientState: any = await AdoriService.fetchClientState()
    const { hasAcceptedCookies, autoprocessedAudioUids, youtubeTourShown, isSupportExist } = clientState
    if (autoprocessedAudioUids) {
      autoprocessedAudioUids.forEach((audio: any, index: any, autoprocessedAudioUids: any) => {
        if (typeof audio === 'string') {
          autoprocessedAudioUids[index] = {
            audioUid: audio,
            addTags: 'ADD',
            pollHandler: null,
          }
        }
      })
    }
    context.commit('setHasAcceptedCookies', hasAcceptedCookies || false)
    context.commit('setAutoProcessedAudioUids', autoprocessedAudioUids || [])
    context.commit('setYoutubeTourShown', youtubeTourShown || [])
    context.commit('setIsSupportExist', isSupportExist || false)
  },

  async uploadClientState(context) {
    const hasAcceptedCookies = context.getters.hasAcceptedCookies
    const autoprocessedAudioUids = context.getters.autoprocessedAudioUids
    const youtubeTourShown = context.getters.youtubeTourShown
    const isSupportExist = context.getters.isSupportExist

    await AdoriService.updateClientState({
      hasAcceptedCookies,
      autoprocessedAudioUids,
      youtubeTourShown,
      isSupportExist,
    })
  },

  async updateIsSupport(context) {
    await context.commit('setIsSupportExist', true)
    context.dispatch('uploadClientState')
  },

  async uploadTourStatus(context, { tourName, isLoggedIn }) {
    if (isLoggedIn) {
      const youtubeTourShown = context.getters.youtubeTourShown
      if (!youtubeTourShown.includes(tourName)) {
        context.commit('setYoutubeTourShown', [...youtubeTourShown, tourName])
      }
      context.dispatch('uploadClientState')
    } else {
      if (!localStorage.getItem(tourName)) {
        localStorage.setItem(tourName, 'yes')
      }
    }
  },

  async uploadCookieStatus(context, hasAcceptedCookies) {
    context.commit('setHasAcceptedCookies', hasAcceptedCookies)
    context.dispatch('uploadClientState')
  },

  async setSelectedTagCollection(context, name) {
    context.commit('setSelectedTagCollection', name)
  },

  async addAudioToAutoProcessList(context, { audioUid, addTags }) {
    context.commit('addAudioToAutoProcessList', { audioUid, addTags })
    await context.dispatch('uploadClientState')
  },

  async removeAudioFromAutoProcessList(context, audioUid) {
    context.commit('removeAudioFromAutoProcessList', audioUid)
    await context.dispatch('uploadClientState')
  },

  async removeAudioToReplaceAudioProcessList(context, audioUid) {
    context.commit('removeAudioToReplaceAudioProcessList', audioUid)
  },

  async changeAddTagsOfAudioInAudioProcessList(context, { audioUid, addTags }) {
    context.commit('changeAddTagsOfAudioInAudioProcessList', {
      audioUid,
      addTags,
    })
    await context.dispatch('uploadClientState')
  },

  async restartPollOfAudioInAudioProcessList(context, audioUid) {
    context.commit('restartPollOfAudioInAudioProcessList', audioUid)
    await context.dispatch('uploadClientState')
  },

  async clearAudioProcessList(context) {
    context.commit('clearAudioProcessList')
    await context.dispatch('uploadClientState')
  },

  async startReplaceAudioProcessPoll(context) {
    if (!context.getters.replaceAudioProcessPoll) {
      context.commit(
        'setReplaceAudioProcessPoll',
        setInterval(() => {
          context.dispatch('fetchAudiosFromReplaceAudioProcessList')
        }, 8000)
      )
    }
  },

  async fetchAudiosFromReplaceAudioProcessList(context) {
    const audioUids = context.getters.replaceAudioProcessUids
    if (audioUids && audioUids.length) {
      audioUids.forEach((uid: string) => {
        context.dispatch('getAudio', uid)
      })
    } else {
      clearInterval(context.getters.replaceAudioProcessPoll)
      context.commit('setReplaceAudioProcessPoll', null)
    }
  },

  async addAudioToReplaceAudioProcessList(context, audioUid: string) {
    const existingUid: string = context.getters.replaceAudioProcessUids.find((uid: string) => uid === audioUid)

    if (!existingUid) {
      context.commit('addAudioToReplaceAudioProcessList', audioUid)
      if (!context.getters.replaceAudioProcessPoll) {
        context.dispatch('startReplaceAudioProcessPoll')
      }
    }
  },

  async removeAudioFromReplaceAudioProcessUids(context, audioUid: string) {
    context.commit('removeAudioFromReplaceAudioProcessUids', audioUid)
  },
}

const tagCollections: StoreOptions<any> = {
  state,
  getters,
  mutations,
  actions,
}

export default tagCollections
