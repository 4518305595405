import { Component, Vue, Prop } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import get from 'lodash.get'
import { CURRENT_ENV, ENVS } from '@/constants/env'
import { QueryClient, useQueryClient } from 'vue-query'
import { audioQueryKeys } from '@/hooks/audio'
import {
  useGetYoutubeAccount,
  useGetYoutubeCategories,
  useGetYoutubePlaylist,
  useGetYoutubeSummary,
  useYoutubeSignOut,
  youtubeQueryKeys,
} from '@/hooks/youtube'
import { computed, reactive, ref, SetupContext, watch } from '@vue/composition-api'

@Component({
  setup(props: any, context: SetupContext) {
    const queryClient = useQueryClient()
    const podcastId = ref(undefined)
    const showId = computed(() => get(props, 'showId', podcastId.value))
    const youtubeParams: any = reactive({
      showId: showId,
    })

    // Youtube Summary Api
    const { data: summaryData, isFetching: isSummaryLoading } = useGetYoutubeSummary(youtubeParams, false)

    const isYoutubeLoggedIn = computed(() => get(summaryData.value, 'youtube', false))

    // Youtube Account Api which is dependent on summary youtube false || true
    const { data: accountData, isFetching: isAccountLoading } = useGetYoutubeAccount(youtubeParams, {
      enabled: isYoutubeLoggedIn,
    })

    watch(summaryData, (curVal: any, oldVal: any) => {
      queryClient.invalidateQueries([
        youtubeQueryKeys.YOUTUBE_ACCOUNT,
        context.root.$store.getters.networkId,
        youtubeParams,
      ])
      queryClient.invalidateQueries(audioQueryKeys.VIDEOCLIP)
    })
    const isAccountActive = computed(() => get(accountData.value, 'gProfile.active', false))

    // The Api are dependent on account active
    useGetYoutubeCategories(youtubeParams, { enabled: isAccountActive })
    useGetYoutubePlaylist(youtubeParams, { enabled: isAccountActive })

    watch(accountData, async (curVal: any, oldVal: any) => {
      if (!!curVal?.gProfile) {
        queryClient.invalidateQueries([
          youtubeQueryKeys.YOUTUBE_CATEGORIES,
          context.root.$store.getters.networkId,
          youtubeParams,
        ])
        queryClient.invalidateQueries([
          youtubeQueryKeys.YOUTUBE_PLAYLIST,
          context.root.$store.getters.networkId,
          youtubeParams,
        ])
        if (isYoutubeLoggedIn.value && !isAccountActive.value) {
          await youtubeSignOut(youtubeParams)
          context.root.$store.dispatch('pushNotification', {
            text: `Error in connecting to your Youtube account. The reason is  ${curVal?.gProfile?.revokeReason
              .replace(/([A-Z])/g, ' $1')
              .replace(/^./, function (str: string) {
                return str.toUpperCase()
              })}. <a href="https://adoriai.com/documentation/${
              ['channelNotFound', 'channelClosed', 'channelSuspended'].includes(curVal?.gProfile?.revokeReason)
                ? 'how-to-create-a-youtube-channel'
                : //   : curVal?.gProfile?.revokeReason === 'quotaExceeded'
                  //   ? 'how-to-turn-on-2-steps-verification-for-your-youtube-channel'
                  'how-to-turn-on-2-steps-verification-for-your-youtube-channel'
            }" target="_blank">Learn More</a>`,
            type: 'ERROR',
          })
        }
      }
    })

    const { mutateAsync: youtubeSignOut, isLoading: isSignOutLoading } = useYoutubeSignOut(youtubeParams)

    return {
      queryClient,
      summaryData,
      isSummaryLoading,
      accountData,
      isAccountLoading,
      isAccountActive,
      youtubeSignOut,
      isSignOutLoading,
      podcastId,
    }
  },
})
export default class Youtube extends Vue {
  @Getter networkId!: string
  @Getter networkType!: string

  @Action pushNotification!: any

  youtubeAuthHandle: any = null
  timeout: any = null

  queryClient!: QueryClient
  summaryData!: any
  isSummaryLoading!: boolean

  accountData!: any
  isAccountLoading!: boolean

  youtubeSignOut!: any
  isSignOutLoading!: boolean

  showId!: string
  podcastId!: any

  youtubeLoader = false

  async signOut() {
    const youtubePayload = { networkId: this.networkId, showId: this.showId }
    // @ts-ignore
    if (this.signOutPermission) {
      this.pushNotification({
        text: `Sorry! You don't have permission to perform this action`,
        type: 'WARNING',
      })
      return
    }
    this.youtubeSignOut(youtubePayload)
    this.queryClient.invalidateQueries([audioQueryKeys.VIDEOCLIP])
  }

  get isNotALlowedLogin() {
    return ['YOUTUBE_METERED', 'YOUTUBE'].includes(this.networkType) && this.showId && CURRENT_ENV !== ENVS.dev
  }

  get signInPermission() {
    return this.showId ? !this.$permissions.isShowMangeAllowed(this.showId) : !this.$permissions.isYoutubeLoginAllowed()
  }

  get signOutPermission() {
    return this.showId
      ? !this.$permissions.isShowMangeAllowed(this.showId)
      : !this.$permissions.isYoutubeLogoutAllowed()
  }

  async signIn() {
    if (this.isNotALlowedLogin) {
      this.pushNotification({
        text: `Upgrade your account to enterprise for show level login`,
        type: 'WARNING',
      })
      return
    }
    if (this.signInPermission) {
      this.pushNotification({
        text: `Sorry! You don't have permission to perform this action`,
        type: 'WARNING',
      })
      return
    }

    this.youtubeAuthHandle = window.open(
      `${window.origin}/youtubeTerms?youtube=1${this.showId ? `&showId=${this.showId}` : ''}`,
      'youtubeAuthHandle',
      'width=500,height=715'
    )
    this.handleYoutubeLogin()
  }

  async handleYoutubeLogin() {
    if (get(this.youtubeAuthHandle, 'closed', null)) {
      this.queryClient.invalidateQueries([youtubeQueryKeys.YOUTUBE_SUMMARY, this.networkId, { showId: this.showId }])
      const unWatch = this.$watch(
        () => this.accountData,
        () => {
          if (this.accountData?.gProfile == null || this.accountData?.gProfile.active === false) {
            unWatch()
            this.onYtLoginFailed()
          } else if (this.accountData?.gProfile.active === true) {
            unWatch()
            this.onYtLoginSuccess()
          }
        }
      )
      this.youtubeLoader = false
    } else this.timeout = setTimeout(this.handleYoutubeLogin)
  }

  onYtLoginSuccess() {}
  onYtLoginFailed() {}
}
