import Vue from 'vue'
import { StoreOptions, GetterTree, MutationTree, ActionTree } from 'vuex'
import get from 'lodash.get'
import { removeDuplicatesByProp } from '@/utils/misc'
import { correctAudiogramPosition, correctPosition } from '@/utils/time'
import AdoriService from '@/services/adori'
import AdoriServiceV6 from '@/services/adori_v6'
import { suggestionSource } from '@/components/Tags/Suggestions/Suggestions'
import { uploadStatus } from '@/components/Publish/publish'
import store from '@/store'

const state: any = {
  timelineLoader: false,
  audioUids: {},
  audioUidsWithExcludedFeed: null,
  audioUidsCount: -1,
  allAudioSearchValue: '',
  isAllAudioSearching: false,
  setAudioUidsOffset: -1,
  publishedAudioUids: [],
  audiosByUid: {},
  selectedAudioUids: [],
  tagPositionsByAudioUid: {},
  audiogramPositionsByAudioUid: {},
  audioAds: {},
  audioAdsCount: -1,
  audioAdsByAudio: {},
  audioUidsByTag: [],
  timelineScroll: true,
  timeLineZoom: 3,
  isTimeLineEdited: false,
}

const initialState: any = {
  ...JSON.parse(JSON.stringify(state)),
}

const getters: GetterTree<any, any> = {
  timelineLoader: (state) => state.timelineLoader,
  audioUids: (state) => state.audioUids,
  allAudioSearchValue: (state) => state.allAudioSearchValue,
  isAllAudioSearching: (state) => state.isAllAudioSearching,
  selectedAudioWithExcludedFeed: (state) => {
    let audio: any = ''
    if (state.audioUidsWithExcludedFeed && state.audioUidsWithExcludedFeed.data && state.selectedAudioUids[0]) {
      audio = state.audioUidsWithExcludedFeed.data.filter((audio: any) => audio.uid === state.selectedAudioUids[0])[0]
    }
    return audio
  },
  publishedAudioUids: (state) => state.publishedAudioUids,
  audiosByUid: (state) => state.audiosByUid,
  audio: (state) => (audioUid: string) => state.audiosByUid[audioUid],
  tagPositionsByAudioUid: (state) => state.tagPositionsByAudioUid,
  tagPositions: (state) => (audioUid: string) => state.tagPositionsByAudioUid[audioUid],
  audiogramPositionsByAudioUid: (state) => state.audiogramPositionsByAudioUid,
  audiogramPositions: (state) => (audioUid: string) => state.audiogramPositionsByAudioUid[audioUid],
  selectedAudioUids: (state) => state.selectedAudioUids,
  hasSelectedAudioUids: (state) => state.selectedAudioUids !== null,
  selectedAudios: (state) => state.selectedAudioUids.map((audioUid: string) => state.audiosByUid[audioUid]),
  isAudioSelected: (state) => (audioUid: string) => state.selectedAudioUids.indexOf(audioUid) !== -1,
  audioUidsCount: (state) => state.audioUidsCount,
  audioUidsOffset: (state) => state.audioUidsOffset,
  audioUidsWithExcludedFeed: (state) => state.audioUidsWithExcludedFeed,
  audioUidsLazyLoader: (state) => ({
    data: state.audioUids,
    count: state.audioUidsCount,
    offset: state.audioUidsOffset,
  }),
  audioAds: (state) => state.audioAds,
  audioAdsCount: (state) => state.audioAdsCount,
  audioAdsByAudio: (state) => (audioUid: string) => state.audioAdsByAudio && state.audioAdsByAudio[audioUid],
  audioUidsByTag: (state) => state.audioUidsByTag,
  timelineScroll: (state) => state.timelineScroll,
  timeLineZoom: (state) => state.timeLineZoom,
  isTimeLineEdited: (state) => state.isTimeLineEdited,
}

const mutations: MutationTree<any> = {
  setTimelineLoader(state, status) {
    Vue.set(state, 'timelineLoader', status)
  },
  setTimelineEdited(state, value) {
    Vue.set(state, 'isTimeLineEdited', value)
  },
  setAudioUids(state, { audioUids, clear }) {
    if (clear) {
      Vue.set(state, 'audioUids', audioUids)
    } else {
      let data: any = []
      if ((state.audioUids.data && state.audioUids.data.length === 0) || audioUids.offset === 0) {
        data = [...audioUids.data]
      } else data = [...state.audioUids.data, ...audioUids.data]
      Vue.set(state, 'audioUids', {
        data: data,
        offset: audioUids.offset,
        count: audioUids.count,
      })
    }
  },

  resetState(state) {
    Object.keys(state).forEach((key: any) => {
      Vue.set(state, key, initialState[key])
    })
  },

  setAudioUidsCount(state, count) {
    Vue.set(state, 'audioUidsCount', count)
  },

  setAudioUidsOffset(state, offset) {
    Vue.set(state, 'audioUidsOffset', offset)
  },

  addAudioUid(state, audioUid: string) {
    if (state.audioUids.data && state.audioUids.data.indexOf(audioUid) === -1) {
      Vue.set(state.audioUids, 'data', [audioUid, ...state.audioUids.data])
      Vue.set(state, 'audioUidsCount', state.audioUidsCount + 1)
    }
  },

  deleteAudioUid(state, audioUid: string) {
    Vue.delete(state.audioUids.data, state.audioUids.data.indexOf(audioUid))
    Vue.set(state, 'audioUidsCount', state.audioUidsCount - 1)
  },

  setPublishedAudioUids(state, audioUids) {
    Vue.set(state, 'publishedAudioUids', audioUids)
  },

  addAudio(state, audio) {
    Vue.set(state.audiosByUid, audio.uid, audio)
  },

  deleteAudio(state, audioUid) {
    Vue.delete(state.audiosByUid, audioUid)
  },

  setAudioAudiogram(state, { audioUid, audiogramPositions }) {
    Vue.set(state.audiogramPositionsByAudioUid, audioUid, audiogramPositions)
  },

  addAudioAudiogram(state, { audioUid, audiogramPosition }) {
    // const audiograms = [...state.audiogramPositionsByAudioUid[audioUid], audiogramPosition]
    const duration = state.audiosByUid[audioUid].durationMillis
    const offsetMillis = 0

    const audiogramPositions = [{ ...audiogramPosition, offsetMillis, durationMillis: duration }]

    Vue.set(state.audiogramPositionsByAudioUid, audioUid, audiogramPositions)
  },

  removeAudioAudiogram(state, { audioUid, audiogramPosition }) {
    Vue.set(
      state.audiogramPositionsByAudioUid,
      audioUid,
      state.audiogramPositionsByAudioUid[audioUid].filter(
        (obj: any) => obj.audiogramId !== audiogramPosition.audiogramId
      )
    )
  },

  setAudioTags(state, { audioUid, tagPositions }) {
    Vue.set(state.tagPositionsByAudioUid, audioUid, tagPositions)
  },

  addAudioTag(state, { audioUid, tagPosition }) {
    const tags = [...state.tagPositionsByAudioUid[audioUid], tagPosition]
    const duration = state.audiosByUid[audioUid].durationMillis
    const offsetMillis = correctPosition(tags.length - 1, tags, duration, state.timeLineZoom, store.getters.networkType)

    const tagPositions = [
      ...state.tagPositionsByAudioUid[audioUid],
      { ...tagPosition, offsetMillis, durationMillis: 240000, tag: store.getters.tag(tagPosition.tagId) },
    ]

    Vue.set(state.tagPositionsByAudioUid, audioUid, sortByOffsetMillis(tagPositions))
  },

  moveAudioTag(state, { audioUid, index, toPosition, action }) {
    const tags = state.tagPositionsByAudioUid[audioUid]
    tags[index] = { ...tags[index], offsetMillis: toPosition }
    const duration = state.audiosByUid[audioUid].durationMillis
    let offsetMillis = toPosition
    if (action && action === 'audio') {
      offsetMillis = toPosition
    } else {
      offsetMillis = correctPosition(index, tags, duration, state.timeLineZoom, store.getters.networkType)
    }
    tags[index] = { ...tags[index], offsetMillis }

    Vue.set(state.tagPositionsByAudioUid, audioUid, sortByOffsetMillis(tags))
  },

  removeAudioTag(state, { audioUid, tagPosition }) {
    Vue.set(
      state.tagPositionsByAudioUid,
      audioUid,
      state.tagPositionsByAudioUid[audioUid].filter((t: any) => t.offsetMillis !== tagPosition.offsetMillis)
    )
  },

  selectAudio(state, audioUid) {
    const selectedAudioUids = state.selectedAudioUids
    Vue.set(state, 'selectedAudioUids', [...selectedAudioUids, audioUid])
  },

  unselectAudio(state, audioUid) {
    Vue.delete(state.selectedAudioUids, state.selectedAudioUids.indexOf(audioUid))
  },

  unselectAllAudios(state) {
    Vue.set(state, 'selectedAudioUids', [])
  },

  clearAudioUids(state) {
    Vue.set(state, 'setAudioUidsCount', -1)
    Vue.set(state, 'setAudioUidsOffset', -1)
    Vue.set(state, 'setAudioUids', [])
  },

  setAudioUidsWithExcludedFeed(state, audios) {
    // Vue.set(state, 'audioUidsWithExcludedFeed', audioUids)
    // state.audioUidsWithExcludedFeed.data = audios.data
    // state.audioUidsWithExcludedFeed.count = audios.count
    // state.audioUidsWithExcludedFeed.limit = audios.limit
    // state.audioUidsWithExcludedFeed.offset = audios.offset
    let data: any = []
    if (audios.offset !== 0) {
      data = state.audioUidsWithExcludedFeed.data
        ? [...state.audioUidsWithExcludedFeed.data, ...audios.data]
        : audios.data
    } else {
      data = audios.data
    }

    Vue.set(state, 'audioUidsWithExcludedFeed', {
      count: audios.count,
      limit: audios.limit,
      offset: audios.offset,
      data: data,
    })
  },

  clearAudioUidsWithExcludedFeed(state) {
    Vue.set(state, 'audioUidsWithExcludedFeed', {})
  },

  setAudioUidsByTag(state, audios) {
    let data: any = []
    if (audios.offset !== 0) {
      data = state.audioUidsByTag.data ? [...state.audioUidsByTag.data, ...audios.data] : audios.data
    } else {
      data = audios.data
    }

    Vue.set(state, 'audioUidsByTag', data)
  },

  setAudiosByUid(state, audiosByUid) {
    Vue.set(state, 'audiosByUid', { ...state.audiosByUid, ...audiosByUid })
  },

  setAllAudioSearchValue(state, searchValue) {
    Vue.set(state, 'allAudioSearchValue', searchValue)
  },

  setIsAllAudioSearching(state, isSearching) {
    Vue.set(state, 'isAllAudioSearching', isSearching)
  },

  setAudioAds(state, audioAds) {
    let data = []
    if ((state.audioAds.data && state.audioAds.data.length === 0) || audioAds.offset === 0) {
      data = [...audioAds.data]
    } else data = [...state.audioAds.data, ...audioAds.data]

    Vue.set(state, 'audioAds', {
      data,
      offset: audioAds.offset,
      count: audioAds.count,
    })
    // Vue.set(state, 'audioAds', audioAds.filter((obj: any) => obj.adorificationStatus === 'FINISHED'))
  },

  setAudioAdsCount(state, audioAdsCount) {
    Vue.set(state, 'audioAdsCount', audioAdsCount)
  },

  setAudioAdsByAudio(state, { audioUid, audioAdsByAudio }) {
    Vue.set(state.audioAdsByAudio, audioUid, audioAdsByAudio)
  },

  setTimelineScroll(state, value) {
    Vue.set(state, 'timelineScroll', value)
  },
  setTimeLineZoom(state, value) {
    Vue.set(state, 'timeLineZoom', value)
  },
}

const actions: ActionTree<any, any> = {
  resetAudiosState(context) {
    context.commit('resetState')
  },

  setAllAudioSearchValue(context, searchValue) {
    context.commit('setAllAudioSearchValue', searchValue)
  },

  setIsAllAudioSearching(context, isSearching) {
    context.commit('setIsAllAudioSearching', isSearching)
  },

  async getAudioUids(
    context,
    { paginate = false, limit = 10, offset = 0, category = 'TRACK', query = null, clear = false, fromYoutube = false }
  ) {
    const networkId = context.getters.networkId
    let audioUids: any = [],
      data: any = []
    if (clear) {
      context.commit('clearAudioUids')
      context.commit('unselectAllAudios')
    }

    if (query === '') return
    const searchQuery = query || context.state.allAudioSearchValue || null

    if (searchQuery) {
      audioUids = await AdoriService.fetchAudioUidsSearch(networkId, {
        params: {
          paginate,
          limit,
          offset,
          category,
          query: searchQuery,
        },
      })
    } else if (fromYoutube) {
      let params = new URLSearchParams()
      params.append('paginate', paginate)
      params.append('limit', limit)
      params.append('offset', offset)
      params.append('track_source', 'UPLOADED')
      params.append('track_source', 'UPLOADED_FILE')
      params.append('track_source', 'AI_GENERATED')
      audioUids = await AdoriService.fetchAudioUids(networkId, {
        params: params,
      })
    } else {
      audioUids = await AdoriService.fetchAudioUids(networkId, {
        params: {
          paginate,
          limit,
          offset,
        },
      })
    }

    let payload = {
      network_id: networkId,
      trackUid: audioUids.data,
      offset: 0,
      limit: 50,
    }

    // recursive function call until all video clips are fetched
    let fetchVideoClips = async (value: any) => {
      const res: any = await AdoriService.youtube_upload_task_by_uid(value)
      data.push(...res.data)
      if (res.count > data.length) {
        payload = {
          network_id: networkId,
          trackUid: audioUids.data,
          offset: data.length,
          limit: 50,
        }
        await fetchVideoClips(payload)
      }
    }

    await fetchVideoClips(payload)

    // group video clips by trackUid
    let result = data.reduce((map: any, obj: any) => {
      ;(map[obj.trackUid] = map[obj.trackUid] || []).push(obj)
      return map
    }, {})

    // assign video clips to episodes
    for (let i = 0; i < audioUids.data.length; i++) {
      const uid = audioUids.data[i].uid
      audioUids.data[i].videoClips = result[uid] || []
    }

    let audiosByUid: any = {}
    audioUids.data.forEach((audio: any) => {
      audiosByUid[audio.uid] = audio
    })

    if (category === 'AD') {
      context.commit('setAudioAds', audioUids)
    }
    context.commit('setAudiosByUid', audiosByUid)
    // if (paginate) {
    const audioUidsData = audioUids.data.map((audio: any) => audio.uid)
    context.commit('setAudioUidsCount', audioUids.count)
    context.commit('setAudioUidsOffset', audioUids.offset)
    // context.commit()
    context.commit('setAudioUids', {
      audioUids: {
        data: audioUidsData,
        count: audioUids.count,
        offset: audioUids.offset,
      },
      clear,
    })
    context.dispatch('filterReplaceAudioProcessUids', audioUids.data)
  },

  async getAudioUidsWithExcludedFeed(context, { excludeFeedUid, limit = 10, offset = 0, query = null, clear = false }) {
    const networkId = context.getters.networkId
    let params: any = {
      exclude_feed: excludeFeedUid,
      limit,
      offset,
    }
    let audioUids: any = {}
    if (query) {
      params['query'] = query
      audioUids = await AdoriService.fetchAudioUidsSearch(networkId, {
        params,
      })
    } else {
      audioUids = await AdoriService.fetchAudioUids(networkId, {
        params,
      })
    }
    context.commit('setAudioUidsWithExcludedFeed', audioUids)
  },

  async getAudioUidsByTag(context, { tagId, limit = 10, offset = 0, query = null, clear = false }) {
    const networkId = context.getters.networkId
    let params: any = {
      tag_id: tagId,
      limit,
      offset,
    }
    let audioUids: any = {}
    if (query) {
      params['query'] = query
      audioUids = await AdoriService.fetchAudioUidsSearch(networkId, {
        params,
      })
    } else {
      audioUids = await AdoriService.fetchAudioUids(networkId, {
        params,
      })
    }
    context.commit('setAudioUidsByTag', audioUids)
  },

  async getAudioAds(context, params) {
    const networkId = context.getters.networkId
    const audioAdsData: any = await AdoriService.fetchAudioUids(networkId, {
      params,
    })
    if (audioAdsData) {
      let audioAds: any = {
        data: audioAdsData.data,
        count: audioAdsData.count,
        offset: audioAdsData.offset,
      }

      context.commit('setAudioAds', audioAds)
      context.commit('setAudioAdsCount', audioAdsData.count)
    }
  },

  async updateAudioAd(context, { audioId, payload }) {
    const networkId = context.getters.networkId
    await AdoriService.updateAudio(networkId, audioId, payload)
  },

  async getAudioAdsByAudio(context, audioUid: string) {
    const networkId = context.getters.networkId
    const audioAdsByAudio: any = await AdoriService.fetchAudio(networkId, audioUid)

    if (audioAdsByAudio) {
      context.commit('setAudioAdsByAudio', {
        audioUid,
        audioAdsByAudio: audioAdsByAudio,
      })
    }
  },

  async addAudioUid(context, audioUid: string) {
    context.commit('addAudioUid', audioUid)
  },

  async deleteAudioUid(context, audioUid: string) {
    context.commit('deleteAudioUid', audioUid)
    context.dispatch('removeAudioFromAutoProcessList', audioUid)
  },

  async getPublishedAudioUids(context) {
    const networkId = context.getters.networkId
    const publishedAudioUids: any = await AdoriService.fetchAudioUids(networkId, {}, true)
    context.commit('setPublishedAudioUids', publishedAudioUids)
    context.commit('setAudioUidsCount', publishedAudioUids.count)
  },

  async getAudio(context, audioUid: string) {
    if (audioUid) {
      const networkId = context.getters.networkId
      const audio: any = await AdoriService.fetchAudio(networkId, audioUid).then((response: any) => {
        if (response === 404) {
          return null
        } else {
          return response
        }
      })
      if (audio) {
        const state: any = store.state
        const clientState: any = state.clientState
        context.commit('addAudio', audio)
        const index = context.getters.autoprocessedAudioUids.findIndex((audio: any) => audio.audioUid === audioUid)
        if (index !== -1 && audio.adorificationStatus === 'FAILED') {
          const index = clientState.autoprocessedAudioUids.findIndex((audio: any) => audio.audioUid === audioUid)
          if (index !== -1) {
            context.dispatch('removeAudioFromAutoProcessList', audioUid)
          }
        } else if (index !== -1 && audio.adorificationStatus === uploadStatus.FINISHED) {
          context.dispatch('removeAudioFromAutoProcessList', audioUid)
        } else if (
          index !== -1 &&
          audio.transcriptStatus === uploadStatus.FINISHED &&
          audio.trackInfoSuggTaskStatus === uploadStatus.FINISHED &&
          audio.transSuggTaskStatus === uploadStatus.FINISHED
        ) {
          if (context.getters.autoprocessedAudioUids[index].addTags === 'ADD') {
            await context.dispatch('changeAddTagsOfAudioInAudioProcessList', {
              audioUid,
              addTags: 'ADDING',
            })
            await context.dispatch('fetchTranscript', audioUid)
            await context.dispatch('fetchSuggestions', {
              audioUid: audioUid,
              source: suggestionSource.EP_INFO,
            })
            await context.dispatch('fetchSuggestions', {
              audioUid: audioUid,
              source: suggestionSource.TRANSCRIPT,
            })
            await context.dispatch('addTagSuggestionsToTimeline', audioUid)
            await context.dispatch('changeAddTagsOfAudioInAudioProcessList', {
              audioUid,
              addTags: 'DONE',
            })
          }
          context.dispatch('removeAudioFromAutoProcessList', audioUid)
        }
      } else {
        const index = context.getters.autoprocessedAudioUids.findIndex((audio: any) => audio.audioUid === audioUid)
        if (index !== -1) {
          context.dispatch('removeAudioFromAutoProcessList', audioUid)
        }
      }
      context.dispatch('handleReplaceAudio', audio)
    }
  },

  async handleReplaceAudio(context, audio: any) {
    if (audio) {
      const replaceAudioUid: string = context.getters.replaceAudioProcessUids.find((uid: string) => uid === audio.uid)
      if (
        audio.replaceTask &&
        replaceAudioUid &&
        (audio.replaceTask.status === uploadStatus.FINISHED || audio.replaceTask.status === uploadStatus.FAILED)
      ) {
        context.dispatch('removeAudioFromReplaceAudioProcessUids', audio.uid)
      }
    }
  },

  async addAudio(context, audio) {
    context.commit('addAudio', audio)
  },

  async deleteAudio(context, audioUid: string) {
    const networkId = context.getters.networkId
    await AdoriService.deleteAudio(networkId, audioUid)
  },

  async deleteYoutubeAudio(context, payload: any) {
    const networkId = context.getters.networkId
    await AdoriService.deleteAudio(networkId, payload.audioUid, payload.ytFeedUid)
  },

  async getAudioAudiogram(context, audioUid: string) {
    const networkId = context.getters.networkId

    const audiogramPositions = await AdoriServiceV6.fetchAudioAudiograms(networkId, audioUid)
    context.commit('setAudioAudiogram', { audioUid, audiogramPositions })
    try {
      context.commit('setTimelineLoader', true)
      // @ts-ignore
      audiogramPositions.map(async (obj) => {
        if (!context.getters.audiogramByIdOnTimeline[obj.audiogramId]) {
          await context.dispatch('getOneAudiogram', obj.audiogramId)
        }
      })
      context.commit('setTimelineLoader', false)
    } catch (e) {
      context.commit('setTimelineLoader', false)
    }
  },

  async addAudioAudiogram(context, { audioUid, audiogramPosition }) {
    context.commit('addAudioAudiogram', { audioUid, audiogramPosition })
    await context.dispatch('uploadAudioAudiograms', audioUid)
  },

  async uploadAudioAudiograms(context, audioUid) {
    const networkId = context.getters.networkId
    const audiogramPositions = context.getters.audiogramPositions(audioUid)

    const cleanedUpAudiogramPositions = audiogramPositions.map((position: any) => ({
      audioTrackUid: audioUid,
      durationMillis: position.durationMillis,
      offsetMillis: position.offsetMillis,
      audiogramId: position.audiogramId,
    }))

    await AdoriServiceV6.updateAudioAudiogram(networkId, audioUid, cleanedUpAudiogramPositions)
  },

  async updateAudioAudiogram(context, { audioUid, payload }) {
    const networkId = context.getters.networkId
    const oldTagPositions = context.getters.audiogramPositions(audioUid)
    const cleanedUpAudiogramPositions = oldTagPositions.map((position: any) => {
      if (position.id === payload.id) {
        return {
          audioTrackUid: audioUid,
          durationMillis: payload.durationMillis,
          offsetMillis: payload.offsetMillis,
          audiogramId: payload.audiogramId,
        }
      }
    })
    await AdoriServiceV6.updateAudioAudiogram(networkId, audioUid, cleanedUpAudiogramPositions)
  },

  async removeAudioAudiogram(context, { audioUid, audiogramPosition }) {
    context.commit('removeAudioAudiogram', { audioUid, audiogramPosition })
    await context.dispatch('uploadAudioAudiograms', audioUid)
  },

  async getAudioTags(context, audioUid: string) {
    const networkId = context.getters.networkId
    const tagPositions = await AdoriService.fetchAudioTags(networkId, audioUid)
    context.commit('setAudioTags', { audioUid, tagPositions })
  },

  async addAudioTag(context, { audioUid, tagPosition }) {
    context.commit('addAudioTag', { audioUid, tagPosition })
  },

  async addAudioTags(context, { audioUid, tagPositions }) {
    tagPositions.forEach((tagPosition: any) => {
      context.commit('addAudioTag', { audioUid, tagPosition })
    })
  },

  async moveAudioTag(context, { audioUid, index, toPosition, action }) {
    context.commit('moveAudioTag', { audioUid, index, toPosition, action })
  },

  async removeAudioTag(context, { audioUid, tagPosition }) {
    context.commit('removeAudioTag', { audioUid, tagPosition })
  },

  async uploadAudioTags(context, audioUid) {
    const networkId = context.getters.networkId
    const tagPositions = context.getters.tagPositions(audioUid)

    const cleanedUpTagPositions = tagPositions.map((position: any) => {
      const tagPayload: any = {
        audioTrackUid: audioUid,
        durationMillis: position.durationMillis ? position.durationMillis : 30000,
        fitToScreen: position.fitToScreen,
        offsetMillis: position.offsetMillis,
        tagId: position.tagId,
      }
      position.transition && (tagPayload['transition'] = position.transition)
      position.transitionDurationMillis && (tagPayload['transitionDurationMillis'] = position.transitionDurationMillis)
      position.effectId && (tagPayload['effectId'] = position.effectId)
      return tagPayload
    })
    context.commit('setTimelineEdited', false)
    await AdoriService.updateAudioTags(networkId, audioUid, cleanedUpTagPositions)
  },
  async customUploadAudioTags(context, { audioUid, transition, fitToScreen }: any) {
    const networkId = context.getters.networkId
    const tagPositions = context.getters.tagPositions(audioUid)

    const cleanedUpTagPositions = tagPositions.map((position: any) => {
      const tagPayload: any = {
        audioTrackUid: audioUid,
        durationMillis: position.durationMillis ? position.durationMillis : 30000,
        fitToScreen,
        offsetMillis: position.offsetMillis,
        tagId: position.tagId,
        transition,
      }
      position.transition && (tagPayload['transition'] = position.transition)
      position.transitionDurationMillis && (tagPayload['transitionDurationMillis'] = position.transitionDurationMillis)
      position.effectId && (tagPayload['effectId'] = position.effectId)
      return tagPayload
    })
    context.commit('setTimelineEdited', false)
    await AdoriService.updateAudioTags(networkId, audioUid, cleanedUpTagPositions)
  },

  async updateAudioTag(context, { audioUid, payload }) {
    const networkId = context.getters.networkId
    const oldTagPositions = context.getters.tagPositions(audioUid)
    let allTagsTransition: any, allTagsEffectId: any, allTagsFitToScreen: any
    if (payload.applyEffectToAllTags) {
      allTagsTransition = payload.transition || null
      allTagsEffectId = payload.effectId || null
      allTagsFitToScreen = payload.fitToScreen || null
    }

    const cleanedUpTagPositions = oldTagPositions.map((position: any) => {
      if (position.id === payload.id) {
        const tagPayload: any = {
          audioTrackUid: audioUid,
          durationMillis: payload.durationMillis,
          offsetMillis: payload.offsetMillis,
          tagId: payload.tagId,
        }
        payload.transition && (tagPayload['transition'] = payload.transition)
        payload.transitionDurationMillis && (tagPayload['transitionDurationMillis'] = payload.transitionDurationMillis)
        payload.fitToScreen && (tagPayload['fitToScreen'] = payload.fitToScreen)
        payload.effectId && (tagPayload['effectId'] = payload.effectId)
        return tagPayload
      } else {
        const tagPayload: any = {
          audioTrackUid: audioUid,
          durationMillis: position.durationMillis ? position.durationMillis : 30000,
          offsetMillis: position.offsetMillis,
          tagId: position.tagId,
        }
        if (payload.applyEffectToAllTags) {
          tagPayload['transition'] = allTagsTransition
          position.transitionDurationMillis &&
            (tagPayload['transitionDurationMillis'] = position.transitionDurationMillis)
          tagPayload['fitToScreen'] = allTagsFitToScreen
          tagPayload['effectId'] = allTagsEffectId
        } else {
          position.transition && (tagPayload['transition'] = position.transition)
          position.transitionDurationMillis &&
            (tagPayload['transitionDurationMillis'] = position.transitionDurationMillis)
          position.fitToScreen && (tagPayload['fitToScreen'] = position.fitToScreen)
          position.effectId && (tagPayload['effectId'] = position.effectId)
        }

        return tagPayload
      }
    })

    await AdoriService.updateAudioTags(networkId, audioUid, cleanedUpTagPositions)
  },

  async toggleSelectAudio(context, audioUid) {
    const selectedAudioUids = context.getters.selectedAudioUids
    if (selectedAudioUids.indexOf(audioUid) === -1) {
      context.commit('selectAudio', audioUid)
    } else {
      context.commit('unselectAudio', audioUid)
    }
  },

  async unselectAllAudios(context) {
    context.commit('unselectAllAudios')
  },

  async clearAudioUidsWithExcludedFeed(context) {
    context.commit('clearAudioUidsWithExcludedFeed')
  },

  async addTagSuggestionsToTimeline(context, audioUid) {
    const transcriptSuggestions = await context.getters.getSuggestions(audioUid, 'transcript')
    const transcript = await context.getters.getTranscript(audioUid)
    const networkId = context.getters.networkId
    const tagSuggestions = removeDuplicatesByProp(
      transcriptSuggestions.suggestions.filter((s: any) => s.url),
      'url'
    )
      .filter((s: any) => s.images.length !== 0)
      .map((s: any) => {
        const { sentIdx, wordIdx } = s.contexts[0].beginWord
        const startTimeInSeconds = parseFloat(transcript.sentences[sentIdx].words[wordIdx].start_time)

        return {
          url: s.url,
          imageUrl: s.images[0].url,
          caption: s.caption,
          offsetMillis: startTimeInSeconds * 1000,
          suggestionId: s.id,
        }
      })

    const createAllTags = tagSuggestions.map(async (s: any) => {
      const image: any = await AdoriService.uploadImage(networkId, {
        url: s.imageUrl,
      })
      const tag = await AdoriService.createTag(networkId, {
        actions: 'click',
        url: s.url,
        caption: s.caption,
        imageId: image.id,
        shareable: true,
        saveable: true,
        style: {
          fontStyle: 1,
          topMarginPercentage: 1,
          imageOpacity: 0,
        },
        suggestionId: s.suggestionId,
      })
      return tag
    })
    const allTags = await Promise.all(createAllTags)
    await context.dispatch('addTags', allTags)

    const allTagPositions = allTags.map((tag: any, index: any) => {
      const tagId = tag.id
      const offsetMillis = tagSuggestions[index].offsetMillis
      return {
        tagId,
        offsetMillis,
      }
    })
    await context.dispatch('addAudioTags', {
      audioUid: audioUid,
      tagPositions: allTagPositions,
    })
  },
}

const audios: StoreOptions<any> = {
  state,
  getters,
  mutations,
  actions,
}

export default audios

// Public helpers

export function createAdorificationPoll(store: any, audioUid: string, interval: number): number {
  return window.setInterval(() => {
    store.dispatch('getAudio', audioUid)
    // store.dispatch('fetchTranscript', audioUid)
  }, interval)
}

export function destroyAdorificationPoll(handler: number) {
  window.clearInterval(handler)
}

// Private helpers

function sortByOffsetMillis(positions: any[]) {
  return positions.sort((a: any, b: any) => a.offsetMillis - b.offsetMillis)
}
