




























































import { Component, Mixins, Prop, Vue, Watch } from 'vue-property-decorator'
import ProgressBar from 'vuejs-progress-bar'
import { computed, ref } from '@vue/composition-api'
import { QueryClient, useQueryClient } from 'vue-query'
import { useGetAudioTrack, useGetVideoClipsById } from '@/hooks/audio'
import { formatTimeAgo } from '@/utils/time'
import { uploadStatus } from '../Publish/publish'
import { Action, Getter } from 'vuex-class'
import AdoriService from '@/services/adori'
import ModalYoutubeSettingsV2 from '../Youtube/Modal/ModalYoutubeSettingsV2.vue'
import get from 'lodash.get'
import Youtube from '@/mixins/Youtube'

@Component({
  components: {
    ProgressBar,
    ModalYoutubeSettingsV2,
  },
  setup({ exportId, youtubeSignInStatus }: any, { root }) {
    const audioTrackId: any = computed(() => root.$route.params.id)
    const interval: any = ref(false)
    const queryClient = useQueryClient()
    const { data: exportData, isLoading: exportLoading } = useGetVideoClipsById(exportId, true, interval)
    const { data: audioData } = useGetAudioTrack(audioTrackId.value)
    return {
      exportData,
      exportLoading,
      interval,
      queryClient,
      audioData,
    }
  },
})
export default class ExportedItem extends Mixins(Youtube) {
  @Prop() exportId!: any

  @Getter networkId!: string
  @Getter signinStatus!: any

  @Action pushNotification!: any

  exportData!: any
  exportLoading!: any
  interval!: any
  queryClient!: QueryClient
  downloadLoader = false
  audioData!: any
  showYoutubeSettings = false
  youtubeBtnText = 'Publish'
  youtubeProcessing = false

  options = {
    text: {
      color: '#FFFFFF',
      shadowEnable: true,
      shadowColor: '#000000',
      fontSize: 8,
      fontFamily: 'Helvetica',
      dynamicPosition: false,
      hideText: true,
    },
    progress: {
      color: '#de1a23',
      backgroundColor: 'grey',
      inverted: false,
    },
    layout: {
      height: 10,
      width: 200,
      verticalTextAlign: 61,
      horizontalTextAlign: 43,
      zeroOffset: 0,
      strokeWidth: 30,
      progressPadding: 0,
      type: 'line',
    },
  }

  get exportImage() {
    return this.exportData && this.exportData.posterImage ? this.exportData.posterImage.thumbnailURL : null
  }

  get exportDate() {
    return formatTimeAgo(this.exportData.createdOn)
  }

  get isRenderingFailed() {
    if (this.exportData.status === uploadStatus.FAILED) return true
    return false
  }

  get showProgressBar() {
    if (this.exportData.status === uploadStatus.FINISHED) return false
    else if (this.exportData.status == uploadStatus.QUEUED || this.exportData.status == uploadStatus.STARTED)
      return true
    return false
  }

  get youtubeId() {
    return !!this.exportData.videoId
  }

  get progressBarValue() {
    return Math.ceil(this.exportData.progress)
  }

  get youtubeSignInStatus() {
    return this.signinStatus[this.networkId]?.youtube ? this.signinStatus[this.networkId]?.youtube : false
  }

  @Watch('exportData', { deep: true })
  watchClip() {
    this.exportProcess()
    this.publishProcess()
  }

  async downloadVideo() {
    this.downloadLoader = true
    const params = {
      network_id: this.networkId,
      task_id: this.exportData.id,
    }
    let data!: any
    try {
      data = await AdoriService.youtube_download(params)
      if (!data.url) {
        this.pushNotification({ text: 'No URL available', type: 'ERROR' })
        return
      }
    } catch (error) {
      this.downloadLoader = false
    }

    if (this.$refs['videoDownloadRef']) {
      //@ts-ignore
      this.$refs['videoDownloadRef'].href = data.url
      const audioDownloadLink = this.$refs.videoDownloadRef as HTMLElement
      audioDownloadLink.click()
    }
    this.downloadLoader = false
  }

  exportProcess() {
    if (this.exportData.status === uploadStatus.FINISHED) {
      if (this.interval !== false && this.youtubeProcessing == false) this.downloadVideo()
      this.interval = false
    } else if (this.exportData.status == uploadStatus.QUEUED || this.exportData.status == uploadStatus.STARTED) {
      this.interval = 7000
    }
  }

  publishProcess() {
    if (this.exportData.status === uploadStatus.FINISHED) {
      if (this.exportData?.youtubeProcessingDetails?.processingStatus === 'processing') {
        this.youtubeBtnText = 'Publishing'
        this.youtubeProcessing = true
        this.interval = 5000
      } else if (this.exportData?.youtubeProcessingDetails?.processingStatus === 'succeeded') {
        this.youtubeBtnText = 'View'
        this.youtubeProcessing = false
        this.interval = false
      } else if (this.exportData?.youtubeProcessingDetails === null && this.exportData.videoId) {
        this.youtubeBtnText = 'View'
        this.youtubeProcessing = false
        this.interval = false
      }
    }
  }

  youtubeLink() {
    window.open('//youtu.be/' + this.exportData?.videoId, '_blank')
  }

  publishVideo() {
    if (!this.youtubeSignInStatus) {
      this.youtubeLoader = true
      this.signIn()
    } else {
      this.onYtLoginSuccess()
    }
  }
  onYtLoginSuccess() {
    this.showYoutubeSettings = true
  }

  async handlePublish(data: any) {
    let commonPayload: any = {
      categoryId: data.category,
      privacy: data.privacy,
      title: data.title,
      description: data.description,
      keywords: data.keywords,
      posterImageId: data.posterImageId,
      selfDeclareMadeForKids: data.selfDeclareMadeForKids,
    }

    if (!!data.scheduledFor) {
      commonPayload = {
        ...commonPayload,
        scheduledFor: data.scheduledFor,
      }
    }
    try {
      this.youtubeBtnText = 'Publishing'
      this.youtubeProcessing = true
      await AdoriService.youtube_publish(this.networkId, this.exportId, commonPayload)
      this.queryClient.invalidateQueries(['VIDEOCLIP', this.exportId])
      this.showYoutubeSettings = false
      this.$gtag.event('publish-youtube', {
        event_category: 'User Interaction',
        event_label: 'Published to youtube',
        value: this.exportId,
      })
    } catch (error) {
      this.youtubeBtnText = 'Publish'
      this.youtubeProcessing = false
    }
  }

  mounted() {
    this.exportProcess()
    this.publishProcess()
  }

  get tagPositions() {
    const tagPositions = this.$store.getters.tagPositions(get(this.audioData, 'uid', '')) || []
    return tagPositions
  }
}
