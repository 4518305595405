var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-70 flex flex-column justify-around items-center" },
    [
      _c(
        "div",
        {
          staticClass:
            "flex justify-center silver mt3 items-center w-80 flex-wrap overflow-scroll overflow-x-hidden scrollbar ba b--gray pa2 br3",
          staticStyle: { height: "130px" },
        },
        [
          _vm.isVoiceFetching
            ? _c("div", { staticClass: "loader" })
            : _vm.voicesList.length === 0
            ? _c("div", [
                _vm._v("No Voices found!! select different voice type"),
              ])
            : _c(
                "div",
                { staticClass: "flex justify-center flex-wrap" },
                _vm._l(_vm.voicesList, function (voice) {
                  return _c(
                    "div",
                    {
                      staticClass: "mr2 mb2 pa2 ba br3 flex items-center",
                      class:
                        _vm.selectedVoice.name === voice.name
                          ? "b--adori-red bg-adori-red white	"
                          : "b--grey",
                      on: {
                        click: function ($event) {
                          return _vm.selectVoice(voice)
                        },
                      },
                    },
                    [
                      _c("span", [_vm._v(_vm._s(voice.name))]),
                      _vm.selectedVoice.name === voice.name &&
                      _vm.isAudioPlaying
                        ? _c(
                            "i",
                            {
                              staticClass:
                                "material-icons light-gray f4 v-mid pa1 ml1",
                              on: { click: _vm.pauseAudio },
                            },
                            [_vm._v("pause_circle_outline")]
                          )
                        : _c(
                            "i",
                            {
                              staticClass:
                                "material-icons light-gray f4 v-mid pa1 ml1",
                              on: {
                                click: function ($event) {
                                  return _vm.playAudio(voice)
                                },
                              },
                            },
                            [_vm._v("volume_up")]
                          ),
                    ]
                  )
                }),
                0
              ),
        ]
      ),
      _c(
        "div",
        [
          _c("BaseButtonRed", {
            staticClass: "mt3",
            attrs: {
              text: _vm.buttonTitle,
              onClick: _vm.submitVoiceDetails,
              disabled: _vm.disabled,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }