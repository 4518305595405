import { queryStaleTime, queryDefaults } from '@/utils/query.config'
import AdoriService from '@/services/adori'
import AdoriServiceV6 from '@/services/adori_v6'
import store from '@/store'
import { useQuery, useMutation, useInfiniteQuery, useQueryClient } from 'vue-query'
import { formatOpenAiCaption, formatOpenAiPoll } from '@/utils/misc'
import { billingQueryKeys } from './billing'

interface allScriptsParam {
  paginate?: boolean
  limit?: number
  offset?: number
}

// Query Keys

export enum openAiQueryKeys {
  CONTEXT = 'OPEN_AI_CONTEXT',
  DALLE = 'DALLE',
  STABILITY = 'STABILITY',
  AI_SCRIPTS = 'AI_SCRIPTS',
  AI_SCRIPT = 'AI_SCRIPT',
  AI_TEMPLATES = 'AI_TEMPLATES',
  BLOG_PARSE = 'BLOG_PARSE',
}

// Queries
const getOpenAiContext = (networkId: string, params: any) => {
  return AdoriServiceV6.openAi(networkId, params)
}

const getDalleResults = (networkId: any, params: any) => {
  return AdoriServiceV6.dalleResults(networkId, params)
}

const getStabilityResults = async (networkId: any, params: any) => {
  let image: any = await AdoriServiceV6.stabilityResults(networkId, params)
  //   If search from Tag editor upload to My library
  if (params.searchLibrary == 'AI') {
    let uploadImg: any = await AdoriService.uploadImage(networkId, { url: image.url })
    image.url = uploadImg.originalUrl
  }
  return image
}
const getAiScripts = (networkId: any, { paginate = true, limit = 10, offset = 0 }: allScriptsParam) => {
  return AdoriServiceV6.getAiScripts(networkId, {
    paginate,
    limit,
    offset,
  })
}
const getAiScript = (networkId: any, scriptId: string) => {
  return AdoriServiceV6.getAiScript(networkId, scriptId)
}
const postScript = (networkId: string, data: any) => {
  return AdoriServiceV6.postAiScript(networkId, data)
}
const updateScript = (networkId: string, data: any) => {
  return AdoriServiceV6.patchAiScript(networkId, data.id, data)
}
const deleteScript = (networkId: string, scriptId: string) => {
  return AdoriServiceV6.deleteAiScript(networkId, scriptId)
}

const getAiTemplates = (networkId: any) => {
  return AdoriServiceV6.getAiTemplates(networkId)
}

const getParsedBlog = (networkId: string, data: any) => {
  return AdoriServiceV6.postBlogParse(networkId, {
    blogUrl: data,
    // scriptLength: words,
  })
}

// Hooks
export const useGetOpenAiContext = (params: any, { enabled }: any, onSuccess?: any) => {
  const networkId = store.getters.networkId || localStorage.getItem('networkId')
  const openAiPayload = store.getters.openAiPayload
  return useQuery([openAiQueryKeys.CONTEXT, networkId, params], () => getOpenAiContext(networkId, { ...params }), {
    ...queryDefaults,
    onSuccess,
    enabled,
    select: (data: any) => {
      return data.context
        ? ['image', 'text', 'custom'].includes(data.context)
          ? formatOpenAiCaption(data.result)
          : formatOpenAiPoll(data.result)
        : data.result
    },
  })
}
export const useGetDalleResults = (params: any, { enabled }: any) => {
  const networkId = store.getters.networkId || localStorage.getItem('networkId')
  const queryClient = useQueryClient()
  return useQuery([openAiQueryKeys.DALLE, networkId, params], () => getDalleResults(networkId, params), {
    ...queryDefaults,
    enabled,
    refetchOnWindowFocus: false,
    onSuccess() {
      queryClient.invalidateQueries(billingQueryKeys.BILLING_USAGE)
    },
  })
}
export const useGetStabilityResults = (params: any, { enabled }: any, interval: any = false, onSuccess?: any) => {
  const networkId = store.getters.networkId || localStorage.getItem('networkId')
  return useQuery([openAiQueryKeys.STABILITY, networkId, params], () => getStabilityResults(networkId, params), {
    ...queryDefaults,
    enabled,
    refetchOnWindowFocus: false,
    refetchInterval: interval,
    onSuccess,
  })
}

export const useGetAIScripts = (params: allScriptsParam) => {
  const networkId = store.getters.networkId || localStorage.getItem('networkId')
  return useQuery([openAiQueryKeys.AI_SCRIPTS, networkId, params], () => getAiScripts(networkId, params), {
    keepPreviousData: true,
    cacheTime: queryStaleTime,
    staleTime: queryStaleTime,
  })
}

export const useGetAITemplates = () => {
  const networkId = store.getters.networkId || localStorage.getItem('networkId')
  return useQuery([openAiQueryKeys.AI_TEMPLATES, networkId], () => getAiTemplates(networkId), {
    ...queryDefaults,
    cacheTime: Infinity,
    staleTime: Infinity,
  })
}

export const useGetAIScript = (scriptId: any, { enabled }: any, onSuccess?: any) => {
  const networkId = store.getters.networkId || localStorage.getItem('networkId')
  return useQuery([openAiQueryKeys.AI_SCRIPT, scriptId], () => getAiScript(networkId, scriptId.value), {
    ...queryDefaults,
    cacheTime: Infinity,
    staleTime: Infinity,
    enabled,
    onSettled: onSuccess,
  })
}

export const useGetParsedBlog = (blogUrl: any, { enabled }: any, onSuccess?: any) => {
  const networkId = store.getters.networkId || localStorage.getItem('networkId')
  return useQuery([openAiQueryKeys.BLOG_PARSE, blogUrl], () => getParsedBlog(networkId, blogUrl.value), {
    ...queryDefaults,
    cacheTime: Infinity,
    staleTime: Infinity,
    enabled,
    onSettled: onSuccess,
  })
}

// mutations
export const useSaveScripts = (onSuccess?: any) => {
  const networkId = store.getters.networkId || localStorage.getItem('networkId')
  return useMutation((data: any) => postScript(networkId, data), {
    onSuccess,
  })
}
export const useUpdateScript = (onSuccess?: any) => {
  const networkId = store.getters.networkId || localStorage.getItem('networkId')
  return useMutation((data: any) => updateScript(networkId, data), {
    onSuccess,
  })
}
export const useDeleteScripts = () => {
  const queryClient = useQueryClient()
  const networkId = store.getters.networkId || localStorage.getItem('networkId')
  return useMutation((scriptId: any) => deleteScript(networkId, scriptId), {
    onSuccess() {
      queryClient.invalidateQueries(openAiQueryKeys.AI_SCRIPTS)
    },
  })
}
