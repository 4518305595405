


































































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import VoiceSelection from '@/components/Common/VoiceSelection.vue'
import VoiceCloning from '@/components/Common/VoiceCloning.vue'
import randomText from '@/utils/loadingTexts'
import { useGetVoiceCloneDetails } from '@/hooks/transcript'
import get from 'lodash.get'

@Component({
  components: {
    VoiceSelection,
    VoiceCloning,
  },
  setup() {
    const { data: voiceDetailData, isFetching: isVoiceFetching } = useGetVoiceCloneDetails()
    return {
      voiceDetailData,
      isVoiceFetching,
    }
  },
})
export default class ModalLoader extends Vue {
  @Prop(Boolean) isLoaderActive!: boolean
  @Prop(String) loaderText!: string
  @Prop() totalTagsCount!: any
  @Prop() tagCount!: any

  randomLoadingText = 'Do not run! We are your friends!'
  selectedTab = 'PREMIUM'
  handleVoiceDetails(voiceDetails: any) {
    this.$emit('voice-details', voiceDetails)
  }

  created() {
    this.randomText()
  }

  get voicesList() {
    return get(this, 'voiceDetailData', [])
  }

  loadIframe() {
    //@ts-ignore
    Tally.loadEmbeds()
  }

  randomText() {
    setTimeout(() => {
      this.randomLoadingText = randomText()
      this.randomText()
    }, 7000)
  }
}
