import { CURRENT_ENV, ENVS } from '@/constants/env'
import Vue from 'vue'
import Vuex from 'vuex'
import get from 'lodash.get'
import router from '@/router'
import queryString from 'query-string'
import { SUBSCRIPTION_STATUS } from '@/constants/subscription'

import user from './user'
import images from './images'
import videos from './videos'
import audios from './audios'
import audioCollections from './audioCollections'
import tags from './tags'
import clientState from './clientState'
import networkState from './networkState'
import modal from './modal'
import selection from './selection'
import fileSelector from './fileSelector'
import audioUploader from './audioUploader'
import transcripts from './transcripts'
import tagSuggestions from './tagSuggestions'
import rssFeeds from './rssFeeds'
import notifications from './notifications'
import session from './session'
import networks from './networks'
import permissions from './permissions'
import videoToAdori from './videoToAdori'
import panes from './panes'
import campaign from './campaign'
import AdoriService from '@/services/adori'
import publish from './publish'
import youtube from './youtube'
import autopublish from './autopublish'
import loginAndSignup from './loginAndSignup'
import pagination from './pagination'
import audiogram from './audiogram'
import subtitle from './subtitle'
import ai from './ai'
import * as Sentry from '@sentry/vue'
import { EXPIRY_STORAGE_KEY, INTERCOM_ID, TOKEN_STORAGE_KEY } from '@/constants'
import smartlookClient from 'smartlook-client'
import mixpanel from 'mixpanel-browser'
import { Crisp } from 'crisp-sdk-web'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    hasLoadedUser: false,
    isFirstTimeUser: false,
    showLoader: false,
    initialLoader: false,
    showLoaderText: '',
  },
  getters: {
    hasLoadedUser: (state) => state.hasLoadedUser,
    isFirstTimeUser: (state) => state.isFirstTimeUser,
    showLoader: (state) => state.showLoader,
    initialLoader: (state) => state.initialLoader,
    showLoaderText: (state) => state.showLoaderText,
  },
  mutations: {
    setLoadedUser(state, hasLoadedUser) {
      Vue.set(state, 'hasLoadedUser', hasLoadedUser)
    },

    setFirstTimeUser(state, isFirstTimeUser) {
      Vue.set(state, 'isFirstTimeUser', isFirstTimeUser)
    },

    setShowLoader(state, showLoader) {
      Vue.set(state, 'showLoader', showLoader)
    },

    setShowLoaderText(state, text) {
      Vue.set(state, 'showLoaderText', text)
    },

    setInitialLoader(state, value) {
      Vue.set(state, 'initialLoader', value)
    },
  },
  actions: {
    async loadUser(context, networkChange: boolean) {
      context.commit('setLoadedUser', false)

      await context.dispatch('getPermissions')
      await context.dispatch('getProfile')
      await context.dispatch('getMembersNetworks', {})

      await Promise.all([context.dispatch('getClientState'), context.dispatch('getRoles')])

      const isYoutube = context.getters.isYoutube

      ;(async () => {
        const configUrl = 'https://adori.github.io/openai.json'
        const openAi = await fetch(configUrl)
        const res = await openAi.json()
        CURRENT_ENV === ENVS.prod && delete res.custom
        isYoutube && delete res.poll
        context.commit('setOpenAiPayload', res)
      })()

      localStorage.setItem(TOKEN_STORAGE_KEY, context.getters.token)
      localStorage.setItem(EXPIRY_STORAGE_KEY, context.getters.expiry)
      localStorage.removeItem('blogId')

      context.dispatch('getAllAutomations', { networkId: context.getters.networkId })
      const hostname = window.location.hostname
      if (
        (hostname === 'video.adorilabs.com' || hostname === 'video.dev.adorilabs.com') &&
        window.location.origin.includes('adorilabs')
      ) {
        localStorage.clear()
        window.location.href = `${window.location.origin.replace('adorilabs', 'adoriai')}/#id_token=${
          context.getters.token
        }&expires_at=${context.getters.expiry}`
      }
      if (isYoutube && window.location.origin.includes('studio') && !networkChange) {
        localStorage.clear()
        window.location.href = `${window.location.origin.replace('studio', 'video')}/#id_token=${
          context.getters.token
        }&expires_at=${context.getters.expiry}`
      } else if (!isYoutube && window.location.origin.includes('video') && !networkChange) {
        localStorage.clear()
        window.location.href = `${window.location.origin.replace('video', 'studio')}/#id_token=${
          context.getters.token
        }&expires_at=${context.getters.expiry}`
      }

      context.dispatch('getIntegrationsSummary', { networkId: context.getters.networkId })
      sessionStorage.getItem('feedUid') &&
        context.dispatch('getIntegrationsSummary', {
          networkId: context.getters.networkId,
          showId: sessionStorage.getItem('feedUid'),
        })

      if (!isYoutube) {
        await context.dispatch('handleStripeQuery')
        const hasProfileDetails = await context.dispatch('hasBasicProfileAndNetworkDetails')

        if (!hasProfileDetails) router.push('/signup')
        else {
          if (router.currentRoute.name === 'plans') router.push('/overview')

          const email = get(context, 'getters.email', '')
          if (router.currentRoute.name === 'overview' && email && email === 'demo+apple@adorilabs.com') {
            router.push('/audioall')
          }
        }
        await context.dispatch('handlePaymentCards')
      } else {
        if (router.currentRoute.name === 'overview') router.push('/home')

        const hasProfileDetails = await context.dispatch('hasBasicProfileAndNetworkDetails')
        if (window.location.pathname === '/onboarding' && sessionStorage.getItem('onboardingTrack')) {
          // @ts-ignore
          const selectedEpisode = JSON.parse(sessionStorage.getItem('onboardingTrack'))
          context.commit('selectEpisodeForUpload', selectedEpisode)
          context.commit('setYoutubeStep', 2)
          sessionStorage.setItem('upload', 'YES')
          if (sessionStorage.getItem('ytModalSetting'))
            // @ts-ignore
            context.commit('setSelectedEpisodeSettings', JSON.parse(sessionStorage.getItem('ytModalSetting')))
        }

        if (!hasProfileDetails) router.push('/home/all')
        context.commit('setTimeLineZoom', 1)
      }

      context.commit('setLoadedUser', true)
      /// state information
      const state: any = context.state
      if (state.networks.validNetworkOrShowUser) {
        if (state.networks.validNetworkUser) {
          context.dispatch('getNetworkState')
        }
      } else {
        context.dispatch('showNoMemberNetworks')
      }
      const email = get(context, 'getters.email', '')
      const name = get(context, 'getters.name', '')
      const networkType = get(context, 'getters.networkType', '')

      if (localStorage.getItem('selectedProduct')) {
        context.commit('setSelectedProduct', localStorage.getItem('selectedProduct'))
      } else if (
        ['YOUTUBE', 'YOUTUBE_METERED', 'YOUTUBE_ENTERPRISE', 'YOUTUBE_PRO'].includes(networkType) &&
        !localStorage.getItem('selectedProduct')
      ) {
        context.commit('setSelectedProduct', 'VIDEO')
      } else if (
        [
          'VIDEO_FREE',
          'VIDEO_BASIC',
          'VIDEO_PRO',
          'ADORI_BASIC_MONTHLY',
          'ADORI_BASIC_YEARLY',
          'ADORI_PREMIUM_MONTHLY',
          'ADORI_PREMIUM_YEARLY',
          'ADORI_BUSINESS_MONTHLY',
          'ADORI_BUSINESS_YEARLY',
        ].includes(networkType) &&
        !localStorage.getItem('selectedProduct')
      ) {
        context.commit('setSelectedProduct', 'BLOG')
      }

      mixpanel.identify(email)
      //   ;(function () {
      //     let w: any = window
      //     let ic = w.Intercom
      //     if (typeof ic === 'function') {
      //       ic('reattach_activator')
      //       ic('update', w.intercomSettings)
      //     } else {
      //       let d = document
      //       let i: any = function () {
      //         i.c(arguments)
      //       }
      //       i.q = []
      //       i.c = function (args: any) {
      //         i.q.push(args)
      //       }
      //       w.Intercom = i
      //       let l = function () {
      //         let s = d.createElement('script')
      //         s.type = 'text/javascript'
      //         s.async = true
      //         s.src = 'https://widget.intercom.io/widget/' + INTERCOM_ID
      //         let x: any = d.getElementsByTagName('script')[0]
      //         x.parentNode.insertBefore(s, x)
      //         // @ts-ignore
      //         window.Intercom('boot', {
      //           app_id: INTERCOM_ID,
      //           email: email,
      //           name: name,
      //           networkType: networkType,
      //           networkID: context.getters.networkId,
      //           alignment: 'left',
      //           //Website visitor so may not have any user related info
      //         })
      //       }
      //       if (document.readyState === 'complete') {
      //         l()
      //       } else if (w.attachEvent) {
      //         w.attachEvent('onload', l)
      //       } else {
      //         w.addEventListener('load', l, false)
      //       }
      //     }
      //   })()
      ;(function () {
        // Load the Calendly script
        let script = document.createElement('script')
        script.type = 'text/javascript'
        script.async = true
        script.src = 'https://assets.calendly.com/assets/external/widget.js'
        script.onload = function () {
          console.log('Calendly script loaded')
        }
        let firstScript = document.getElementsByTagName('script')[0]
        // @ts-ignore
        firstScript.parentNode.insertBefore(script, firstScript)

        // Load the Calendly CSS
        let link = document.createElement('link')
        link.rel = 'stylesheet'
        link.href = 'https://assets.calendly.com/assets/external/widget.css'
        link.type = 'text/css'
        link.onload = function () {
          console.log('Calendly CSS loaded')
        }
        document.head.appendChild(link)
      })()

      Crisp.configure('77c4b10d-1fa8-422b-a5cf-372c2a3b29cb')
      Crisp.user.setEmail(email)
      Crisp.user.setNickname(name)

      Crisp.session.setData({
        network_id: context.getters.networkId,
        plan: networkType,
      })

      // @ts-ignore
      const webengage = window['webengage']

      webengage?.user.login(context.getters.networkId)
      webengage?.user.setAttribute('name', context.getters.name)
      webengage?.user.setAttribute('email', context.getters.email)

      smartlookClient.identify(context.getters.networkId, {
        name: context.getters.name,
        email: context.getters.email,
      })

      sessionStorage.removeItem('networkType')
      context.commit('setLoadedUser', true)
    },

    async setFirstTimeUser(context, isFirstTimeUser: boolean) {
      context.commit('setFirstTimeUser', isFirstTimeUser)
    },

    async resetAllStates(context) {
      context.dispatch('setShowLoader', true)
      await Promise.all([
        context.dispatch('resetAnalyticsState'),
        context.dispatch('resetAudioCollectionsState'),
        context.dispatch('resetAudiosState'),
        context.dispatch('resetAudioUploaderState'),
        context.dispatch('resetFileSelectorState'),
        context.dispatch('resetImagesState'),
        context.dispatch('resetModalState'),
        context.dispatch('resetNetworksState'),
        context.dispatch('resetNetworkStateState'),
        context.dispatch('resetNotificationsState'),
        context.dispatch('resetPermissionsState'),
        context.dispatch('resetRssFeedsState'),
        context.dispatch('resetSelectionState'),
        context.dispatch('resetTagsState'),
        context.dispatch('resetTagSuggestionsState'),
        context.dispatch('resetTranscriptsState'),
        context.dispatch('resetVideoToAdoriState'),
      ])
      context.dispatch('setShowLoader', false)
    },

    async setShowLoader(context, showLoader: boolean) {
      context.commit('setShowLoader', showLoader)
    },

    hasBasicProfileAndNetworkDetails(context) {
      // @ts-ignore
      const permissions: any = this._vm.$permissions
      const currentNetwork = context.getters.currentNetwork
      const profile = context.getters.profile

      const userDetails = profile.name

      if (!userDetails) return false

      if (!permissions.isManageNetworkMetaAllowed()) return true

      const networkDetails = currentNetwork && currentNetwork.network.name

      if (!networkDetails) return false

      return true
    },

    async hasSubscriptionDetails(context) {
      // @ts-ignore
      const permissions: any = this._vm.$permissions
      if (!permissions.isManageNetworkBillingAllowed()) return true

      try {
        await context.dispatch('getNetworkSubscriptions')
        const subs: any = context.getters.currentNetworkSubscriptions
        if (subs && !subs.hasUpgraded) return true
        if (
          subs &&
          subs.subscription &&
          (subs.subscription.status === SUBSCRIPTION_STATUS.trial ||
            subs.subscription.status === SUBSCRIPTION_STATUS.active)
        ) {
          return true
        }
      } catch (error) {
        Sentry.captureException(error)
      }
      return false
    },

    async handleStripeQuery(context) {
      const route = router.currentRoute
      let sessionId = route.query.session_id
      let transactionId = route.query.transactionId

      const parsed = queryString.parse(location.search)

      if (!sessionId && !transactionId) {
        sessionId = parsed.session_id
        transactionId = parsed.transactionId
      }

      const networkId: string = context.getters.networkId
      if (sessionId && transactionId) {
        try {
          await AdoriService.fetchPaymentTransaction(networkId, transactionId)
          router.replace({ query: {} })
        } catch (error) {
          Sentry.captureException(error)
        }
      }
    },

    async handlePaymentCards(context) {
      // @ts-ignore
      const permissions: any = this._vm.$permissions
      const subs: any = context.getters.currentNetworkSubscriptions
      if (subs && subs.subscription && permissions.isManageNetworkBillingAllowed()) {
        await context.dispatch('getCurrentNetworkPaymentCards')
      }
    },
  },

  modules: {
    user,
    images,
    videos,
    audios,
    audioCollections,
    tags,
    clientState,
    networkState,
    modal,
    selection,
    fileSelector,
    audioUploader,
    transcripts,
    tagSuggestions,
    rssFeeds,
    notifications,
    session,
    networks,
    permissions,
    videoToAdori,
    panes,
    campaign,
    publish,
    youtube,
    autopublish,
    loginAndSignup,
    pagination,
    audiogram,
    subtitle,
    ai,
  },
})
