
































import { useGetVoiceCloneDetails } from '@/hooks/transcript'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import get from 'lodash.get'
import { ref } from '@vue/composition-api'
import { Getter, Action } from 'vuex-class'

@Component({
  setup(props: any) {
    const voiceType = ref('standard')
    const voiceGender = ref('FEMALE')
    const voiceLanguage = ref('en-US')
    const { data: voiceDetailData, isFetching: isVoiceFetching } = useGetVoiceCloneDetails()
    return {
      voiceType,
      voiceGender,
      voiceLanguage,
      voiceDetailData,
      isVoiceFetching,
    }
  },
})
export default class VoiceSelection extends Vue {
  @Prop(String) buttonTitle!: string
  @Prop(Boolean) disabled!: boolean
  @Prop(String) quality!: string

  @Getter networkType!: any

  isVoiceFetching!: boolean

  voiceType!: string
  voiceGender!: string
  voiceLanguage!: string

  voiceDetailData!: any

  selectedVoice: any = {}

  mounted() {
    this.handleVoiceDetailData()
  }

  @Watch('voiceDetailData')
  handleVoiceDetailData() {
    if (this.voiceDetailData.length) {
      this.selectedVoice = this.voiceDetailData[0]
    }
  }

  get voicesList() {
    return get(this, 'voiceDetailData', [])
  }

  audio!: HTMLAudioElement
  isAudioPlaying = false

  playAudio(voice: any) {
    if (this.audio) {
      !this.audio.paused && this.audio.pause()
      this.audio = new Audio()
      this.isAudioPlaying = false
    }
    this.audio = new Audio()
    const url = voice.preview_url

    this.audio = new Audio(url)
    this.audio.play()
    this.audio.addEventListener('ended', () => {
      this.isAudioPlaying = false
      this.audio.removeEventListener('ended', () => {})
    })
    this.isAudioPlaying = true
  }

  pauseAudio() {
    !this.audio.paused && this.audio.pause()
    this.audio = new Audio()
    this.isAudioPlaying = false
  }

  async submitVoiceDetails() {
    // if (['VIDEO_FREE', 'VIDEO_BASIC'].includes(this.networkType) && this.quality === 'PREMIUM') {
    //   this.$store.commit('setCurrentModal', 'plans')
    //   return
    // }
    this.$emit('voice-details', {
      voiceId: this.selectedVoice.voice_id,
      language: this.voiceLanguage,
      //   voiceType: this.voiceType,
      service: 'elevenlabs',
    })
  }

  destroyed() {
    this.audio.pause()
  }
  selectVoice(voice: any) {
    this.selectedVoice = voice
  }
}
