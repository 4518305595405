var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    [
      _c("template", { slot: "header" }, [
        _c("div", { staticClass: "light-gray f3 fw5" }, [
          _vm._v("Edit profile details"),
        ]),
      ]),
      _c("template", { slot: "body" }, [
        _c("div", { staticClass: "flex pb3" }, [
          _c("div", { staticClass: "pointer" }, [
            _c(
              "div",
              {
                staticClass:
                  "flex justify-center items-center br2 link pointer mr5 mt4 w1500 h12 relative",
                attrs: { title: "Change profile image" },
              },
              [
                _c("croppa", {
                  ref: "croppaInstance",
                  staticClass: "br2",
                  class: _vm.isProfileImageSelected
                    ? "ba bw1 b--adori-light-gray b--dashed"
                    : "ba bw1 b--adori-red b--dashed",
                  attrs: {
                    accept: "image/*",
                    "initial-size": "contain",
                    "show-remove-button": false,
                    "zoom-speed": 20,
                    "placeholder-font-size": 1,
                    width: 200,
                    "initial-image": _vm.profileImage,
                    height: 200,
                    "loading-color": "red",
                    "disable-drag-and-drop": true,
                    "canvas-color": "#212121",
                  },
                  on: {
                    "file-choose": _vm.profileFileChoosen,
                    "image-remove": function ($event) {
                      _vm.isProfileImageSelected = false
                    },
                    "new-image": _vm.profileFileChoosen,
                    move: _vm.profileFileChoosen,
                  },
                  model: {
                    value: _vm.profileCroppa,
                    callback: function ($$v) {
                      _vm.profileCroppa = $$v
                    },
                    expression: "profileCroppa",
                  },
                }),
                !_vm.isProfileImageSelected
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "absolute flex flex-column justify-center items-center mt3 z--1",
                        on: { click: _vm.handleProfileChoose },
                      },
                      [
                        _c(
                          "i",
                          { staticClass: "material-icons light-gray f1" },
                          [_vm._v("panorama")]
                        ),
                        _c("p", { staticClass: "f4 light-gray" }, [
                          _vm._v("Add Profile image"),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "flex flex-column items-center mr5" },
              [
                !_vm.isProfileImageSelected
                  ? _c("div", { staticClass: "f6 adori-red mt3" }, [
                      _vm._v("Please add profile image."),
                    ])
                  : _vm._e(),
                _c("BaseButtonBorder", {
                  staticClass: "mt3",
                  attrs: {
                    text: "Change profile image",
                    onClick: _vm.handleProfileChoose,
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "w-100 mr3" }, [
            _c("p", { staticClass: "gray f6" }, [_vm._v("User name")]),
            _c("div", { staticClass: "relative mb4" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.name,
                    expression: "name",
                  },
                ],
                staticClass:
                  "db bg-adori-very-light-gray br2 ph3 pv3 gray f6 mb1 white",
                class: _vm.name
                  ? "ba bw1 b--adori-transparent"
                  : "ba bw1 b--adori-red",
                staticStyle: { width: "370px" },
                attrs: { placeholder: "Enter user details" },
                domProps: { value: _vm.name },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.name = $event.target.value
                  },
                },
              }),
              !_vm.name
                ? _c("div", { staticClass: "f6 adori-red measure absolute" }, [
                    _vm._v("Name cannot be empty."),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]),
      ]),
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          { staticClass: "flex justify-end items-center mt4" },
          [
            _vm.isSubmitting
              ? _c("div", { staticClass: "loader loader-inline mh3" })
              : _vm._e(),
            _c("BaseButtonRed", {
              staticClass: "mr3",
              attrs: {
                text: "Save changes",
                disabled:
                  !_vm.hasFilledAllDetails ||
                  _vm.isSubmitting ||
                  !_vm.isProfileImageSelected,
                onClick: _vm.uploadProfileDetails,
              },
            }),
            _c("BaseButtonBorder", {
              attrs: {
                text: "Cancel",
                disabled: _vm.isFirstTimeUser,
                onClick: _vm.onCancel,
              },
            }),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }