var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "flex mh500 flex-column mt2 pb4 justify-center items-center silver pa3 tc bg-adori-light-gray ba b--adori-gray br2 br-bottom",
    },
    [
      (_vm.isDraftLoading && !_vm.isNextDraftFetching) || _vm.isDelDraftLoading
        ? _c(
            "div",
            { staticClass: "flex flex-wrap ml3 mt3" },
            _vm._l(new Array(12), function (_, index) {
              return _c("div", {
                key: index,
                staticClass:
                  "w-100 bn bg-black-20 flex justify-center items-center mr3 mb3 br2 skeleton cuz-size",
              })
            }),
            0
          )
        : _c("div", [
            _vm.drafts && _vm.drafts.length === 0
              ? _c("div", { staticClass: "mh4 mt5 mb5 light-gray tc f4" }, [
                  _c("img", {
                    attrs: {
                      width: "150px",
                      alt: "placeholder",
                      src: require("@/assets/audiogram/no-data.svg"),
                    },
                  }),
                  _c("div", { staticClass: "text white mt2" }, [
                    _vm._v("No draft found!"),
                  ]),
                ])
              : _c(
                  "div",
                  { staticClass: "temp" },
                  _vm._l(_vm.drafts, function (draft, i) {
                    return _c("div", { key: draft.id + "-" + i }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "relative flex flex-column items-center ba ma2 b--adori-gray br3 pa3 white mh-150 bg-adori-medium-gray noselect",
                        },
                        [
                          _c("div", { staticClass: "custom-h" }, [
                            _c("img", {
                              staticClass: "br2 nodrag w-100 top-img",
                              attrs: { src: draft.topImage },
                            }),
                          ]),
                          _c("div", { staticClass: "f6 gray pt3 mb2" }, [
                            _c("div", { staticClass: "truncate white w-190" }, [
                              _vm._v(_vm._s(draft.title)),
                            ]),
                          ]),
                          _c("BaseButtonRed", {
                            attrs: {
                              text: "Edit Scenes",
                              onClick: function () {
                                return _vm.editDraft(draft)
                              },
                            },
                          }),
                          _c(
                            "div",
                            {
                              staticClass:
                                "absolute right-0 top-1 o-70 glow flex flex-column pointer",
                            },
                            [
                              _c(
                                "i",
                                {
                                  staticClass:
                                    "material-icons light-gray f4 v-mid pa1 dark-red",
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteBlogDraft(draft.id)
                                    },
                                  },
                                },
                                [_vm._v(" delete ")]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ])
                  }),
                  0
                ),
            _vm.hasNextDraft && _vm.drafts && _vm.drafts.length !== 0
              ? _c(
                  "div",
                  { staticClass: "tc pb3 ph4 mt3" },
                  [
                    _c("BaseButtonLoadMore", {
                      attrs: {
                        text: "Load more",
                        onClick: _vm.nextDraftPage,
                        rootBottomMargin: "800",
                        onVisible: function () {},
                        loading: _vm.isNextDraftFetching,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }