import { queryStaleTime, queryDefaults } from '@/utils/query.config'

import AdoriServiceV6 from '@/services/adori_v6'
import store from '@/store'
import { useQuery, useMutation, useInfiniteQuery, useQueryClient } from 'vue-query'
import { formatOpenAiCaption, formatOpenAiPoll } from '@/utils/misc'
import get from 'lodash.get'
import { Ref } from 'vue-demi'

// Query Keys
export enum blogDraftKeys {
  GET_DRAFT = 'GET_DRAFT',
  GET_ONE_DRAFT = 'GET_ONE_DRAFT',
}

// Queries
const getDrafts = (networkId: string, { offset = 10 }) => {
  return AdoriServiceV6.fetchDrafts(networkId, {
    limit: 12,
    offset,
  })
}
const getOneDraft = (networkId: string, draftId: string) => {
  return AdoriServiceV6.fetchOneDrafts(networkId, draftId)
}
const delDraft = (networkId: string, draftId: string) => {
  return AdoriServiceV6.deleteDraft(networkId, draftId)
}

const saveDraft = (networkId: string, data: any) => {
  return AdoriServiceV6.postDraft(networkId, data)
}
const updateDraft = (networkId: string, data: any) => {
  return AdoriServiceV6.patchDraft(networkId, data.id, data)
}

// Hooks
export const useGetDrafts = () => {
  const networkId = store.getters.networkId || localStorage.getItem('networkId')
  return useInfiniteQuery(
    [blogDraftKeys.GET_DRAFT, networkId],
    ({ pageParam = 0 }) => getDrafts(networkId, { offset: pageParam }),
    {
      getNextPageParam: (_, pages) => {
        const totalPages = get(pages, '[0].count', 0) > 12 ? Math.ceil(get(pages, '[0].count', 0) / 12) : 0
        if (pages.length - 1 === totalPages) return false
        else return pages.length * 12
      },
      ...queryDefaults,
      // @ts-ignore
      select: (data: any) => {
        const draftArray = data.pages.flatMap((obj: any) => obj.data)
        return {
          count: data.pages[0].count,
          data: draftArray,
        }
      },
    }
  )
}

export const useGetOneDraft = (draftId: Ref<string>, { enabled }: any, onSuccess?: any) => {
  const networkId = store.getters.networkId || localStorage.getItem('networkId')
  return useQuery([blogDraftKeys.GET_ONE_DRAFT, draftId, networkId], () => getOneDraft(networkId, draftId.value), {
    enabled,
    cacheTime: Infinity,
    staleTime: Infinity,
    onSuccess,
  })
}

export const useSaveDrafts = (onSuccess?: any) => {
  const networkId = store.getters.networkId || localStorage.getItem('networkId')
  return useMutation((data: any) => saveDraft(networkId, data), {
    onSuccess,
  })
}

export const useUpdateDraft = (onSuccess?: any) => {
  const networkId = store.getters.networkId || localStorage.getItem('networkId')
  return useMutation((data: any) => updateDraft(networkId, data), {
    onSuccess,
  })
}

export const useDeleteDraft = () => {
  const networkId = store.getters.networkId || localStorage.getItem('networkId')
  return useMutation((draftId: string) => delDraft(networkId, draftId), {})
}
